import { When } from 'react-if';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { zepAnalytics } from '@zep/analytics';
import { useAuth } from '@zep/auth';

import { isDevelopment, isLocal } from '../../../utils';

import S from './Footer.module.scss';
import { ZepStringMenus } from './ZepStringMenus';

export const FooterMenus = () => {
  const { locale } = useRouter();
  const { user } = useAuth();
  const { t } = useTranslation(['layout'], { useSuspense: true });

  const isStringUpdatable =
    isLocal || (isDevelopment && user && user.snsId.includes('@zep.us'));

  return (
    <div className={S.menu_container}>
      <Link
        href={`https://docs.channel.io/zep-support/${locale}/articles/b40ac18f`}
        onClick={() => zepAnalytics.track('footer_term')}
        prefetch={false}>
        {t('layout:footer_terms')}
      </Link>
      <span className={S.vertical_bar}>|</span>
      <Link
        href={`https://docs.channel.io/zep-support/${locale}/articles/8232ed3d`}
        onClick={() => zepAnalytics.track('footer_privacy')}
        prefetch={false}>
        {t('layout:footer_privacy')}
      </Link>
      <span className={S.vertical_bar}>|</span>
      <When condition={locale === 'ja'}>
        <a
          href={
            t('layout:footer.links.specificTransactionsAct') ??
            'https://teamzep.notion.site/ebb58b3378d448febdbdcaf59d833c2a?pvs=4'
          }
          target={'_blank'}
          onClick={() => zepAnalytics.track('footer_specificTransactionsAct')}
          rel="noreferrer">
          {t('layout:footer.menus.specificTransactionsAct')}
        </a>
        <span className={S.vertical_bar}>|</span>
      </When>
      <a
        href={t('layout:footer_update_note_link')}
        target="_blank"
        rel="noreferrer"
        onClick={() => zepAnalytics.track('footer_update')}>
        {t('layout:footer_patchnote')}
      </a>
      <span className={S.vertical_bar}>|</span>
      <a
        href={t('layout:footer_guide_link')}
        target="_blank"
        rel="noreferrer"
        onClick={() => zepAnalytics.track('footer_guide')}>
        {t('layout:footer_guide')}
      </a>
      {locale === 'ko' && (
        <>
          <span className={S.vertical_bar}>|</span>
          <a
            href="https://careers.zep.us/"
            target="_blank"
            rel="noreferrer"
            onClick={() => zepAnalytics.track('footer_recruit')}>
            {t('layout:footer_career')}
          </a>
          <span className={S.vertical_bar}>|</span>
          <a
            href="https://zep-official-partners.super.site/"
            target="_blank"
            rel="noreferrer"
            onClick={() => zepAnalytics.track('footer_partners')}>
            {'ZEP 공식 파트너스'}
          </a>
        </>
      )}
      {isStringUpdatable && <ZepStringMenus />}
    </div>
  );
};
