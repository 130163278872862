import axios from 'axios';

import { assert } from '@zep/utils';

export const createZepetofulClient = (
  token: string,
  locale = 'en',
  dev = false,
) => {
  const client = axios.create({
    baseURL: 'https://cdn-cms.zep.us',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      content_type: 'zepPropertyList',
      select: dev ? 'fields.jsonDev,fields.json' : 'fields.json',
      locale,
    },
  });
  client.interceptors.response.use(response => {
    const fields = response.data?.items?.[0]?.fields;
    if (typeof fields !== 'object') {
      return response;
    }
    assert(typeof fields === 'object', 'Invalid response from Zepetoful');
    response.data = fields.jsonDev ?? fields.json;
    return response;
  });
  return client;
};
