import { PropsWithChildren, useEffect, useMemo } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/nextjs';
import { useQueryClient } from '@tanstack/react-query';
import { usePreservedCallback, usePrevious } from '@toss/react';
import { isEqual } from 'lodash-es';
import { match, P } from 'ts-pattern';
import { createContext } from 'use-context-selector';

import { zepAnalytics } from '@zep/analytics';
import { QUERY_KEY_ME, useMe } from '@zep/apis';
import { SESSION_STORAGE_KEY, User } from '@zep/types';

import { removeUserSession, setUserSession } from '../services';
import {
  ASP_SESSION_QUERY_KEY,
  useAspSessionQuery,
} from '../services/useAspSessionQuery';

type AuthContextType = {
  user: User | null;
  isInitialized: boolean;
  updateUser: (user: User) => Promise<void>;
  clearUser: () => void;
  refetch: ReturnType<typeof useMe>['refetch'];
};

export const AuthContext = createContext<AuthContextType | null>(null);

type AuthProviderProps = PropsWithChildren<{
  initialUser: User | undefined;
}>;

const NOT_EXISTS = P.nullish;
const EXISTS = P.not(P.nullish);

export const AuthProvider = ({ children, initialUser }: AuthProviderProps) => {
  const {
    data: aspSession,
    isInitialLoading: isAspSessionInitialLoading,
    isError: isAspSessionError,
  } = useAspSessionQuery();
  const {
    data: user,
    isError,
    isInitialLoading: isUserInitialLoading,
    refetch,
  } = useMe({
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    enabled: !!aspSession,
    initialData: initialUser,
    retry: false,
    suspense: false,
    useErrorBoundary: false,
  });
  const queryClient = useQueryClient();

  const prevUser = usePrevious(user);

  //****************************************************************************************************
  const updateUser = usePreservedCallback(async (newUser: User) => {
    try {
      await setUserSession(newUser);
      queryClient.setQueryData(QUERY_KEY_ME, newUser);
    } catch (e) {
      console.error('Failed to set user session:', e);
    }
  });

  const clearUser = usePreservedCallback(() => {
    sessionStorage.removeItem(SESSION_STORAGE_KEY.PLAY_SESSION);
    queryClient.setQueryData(ASP_SESSION_QUERY_KEY, null);
    queryClient.setQueryData(QUERY_KEY_ME, null);
    removeUserSession().catch(error => {
      console.error('Failed to remove user session:', error);
    });
  });

  //****************************************************************************************************

  // 앱 구동시 초기 로그인 여부 판단, 유저 정보 가져오기 끝났는지 여부
  const isInitialized = useMemo(
    () =>
      match([isAspSessionInitialLoading, aspSession, isUserInitialLoading])
        .with([false, null, P.any], () => true) // AspSession 초기 로딩 끝, AspSession이 없음 => 로그인 안된 유저
        .with([false, EXISTS, false], () => true) // AspSession이 있고, User 초기 로딩이 끝난 경우 => 로그인 한 유저
        .otherwise(() => false),
    [isAspSessionInitialLoading, aspSession, isUserInitialLoading],
  );

  // 유저 정보가 변경 되었을 때
  // prevUser => 기존 유저 데이터
  // user => 새로운 유저 데이터
  useEffect(() => {
    match([prevUser, user])
      .with([NOT_EXISTS, NOT_EXISTS], () => {
        // do nothing
      })
      .with([NOT_EXISTS, EXISTS], ([_, cur]) => {
        updateUser(cur);
        zepAnalytics.setAmplitudeUserId(cur.hashId);
        Sentry.setUser({ id: cur.hashId });
      })
      .with([EXISTS, NOT_EXISTS], () => {
        clearUser();
        zepAnalytics.resetAmplitudeUserId();
        Sentry.setUser(null);
      })
      .with([EXISTS, EXISTS], ([prev, cur]) => {
        if (isEqual(prev, cur)) return;
        updateUser(cur);
      });
  }, [clearUser, prevUser, updateUser, user]);

  useEffect(() => {
    if (isError || isAspSessionError) {
      clearUser();
      // if (Router.basePath === '/play') {
      //   Router.reload();
      // }
    }
  }, [clearUser, isError, isAspSessionError]);

  // initialize가 완료된 후
  useEffect(() => {
    if (isInitialized) {
      amplitude.setGroup(
        'userType',
        window.location.hostname === 'zep.us' ? 'service' : 'beta',
      );
    }
  }, [isInitialized]);

  return (
    <AuthContext.Provider
      value={{
        user: user ?? null,
        isInitialized,
        updateUser,
        clearUser,
        refetch,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
