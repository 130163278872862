import { SSRSafeSuspense } from '@zep/common/react-boundary';

import S from './Footer.module.scss';
import { FooterInformation } from './FooterInformation';
import { FooterMenus } from './FooterMenus';

export const Footer = () => {
  return (
    <footer className={S.footer}>
      <div className={S.inner}>
        <div className={S.footer_width}>
          <div className={S.footer_logo}>
            <img
              width={83}
              height={41}
              src="/images/light/layout/logo_zep.svg"
              alt="zep"
            />
          </div>
          <SSRSafeSuspense>
            <FooterMenus />
          </SSRSafeSuspense>
        </div>
        <hr />
        <SSRSafeSuspense>
          <FooterInformation />
        </SSRSafeSuspense>
      </div>
    </footer>
  );
};
