import { isServer } from '../device';

import { Continent } from './geoUtil';

export const getGeoInfoFromTimeZone = (): {
  continent: Continent;
  city: string;
} => {
  if (isServer()) {
    return { continent: 'unknown' as Continent, city: 'unknown' };
  }
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [continent, city] = timeZone.split('/');

    return { continent: continent as Continent, city };
  } catch (error) {
    console.error('extractGeoInfoFromTimeZone', error);
    return { continent: 'unknown' as Continent, city: 'unknown' };
  }
};
