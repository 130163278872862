import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { When } from 'react-if';
import { useModal } from '@ebay/nice-modal-react';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogOverlay,
  DialogPanel,
  DialogTitle,
} from '@zep/ui';

import { MapTemplateFooterBanner } from './footer-banner/MapTemplateFooterBanner';
import S from './MapTemplateModal.module.scss';
import { MapTemplateModalHeader } from './MapTemplateModalHeader';
import {
  MapTemplatesProps,
  OfficialMapTemplates,
  PurchasedMapTemplates,
} from './MapTemplates';

export const MapTemplateModal = (props: MapTemplateModalProps) => {
  const { t } = useTranslation(['common']);

  const { visible, hide, remove } = useModal();
  const [type, setType] = useState<'official' | 'purchased'>('official');
  const [categoryId, setCategoryId] = useState(-1);

  const setNoTemplate = () => {
    props.onSelectTemplate(null);
  };

  return (
    <Dialog
      maxWidth="968px"
      open={visible}
      onClose={hide}
      afterClose={remove}
      drawer>
      <DialogOverlay />
      <DialogPanel className={S.wrapper}>
        <DialogHeader onClose={hide}>
          <DialogTitle>{t('common:mapTemplateModal.title')}</DialogTitle>
        </DialogHeader>
        <MapTemplateModalHeader
          tab={type}
          onTabChange={setType}
          categoryId={categoryId}
          onCategoryChange={setCategoryId}
          onClickNoTemplate={setNoTemplate}
        />
        <DialogContent id="template_modal_content">
          <When condition={type === 'official'}>
            <OfficialMapTemplates
              categoryId={categoryId}
              onSelectTemplate={props.onSelectTemplate}
            />
          </When>
          <When condition={type === 'purchased'}>
            <PurchasedMapTemplates onSelectTemplate={props.onSelectTemplate} />
          </When>
        </DialogContent>
        <MapTemplateFooterBanner />
      </DialogPanel>
    </Dialog>
  );
};

export type MapTemplateModalProps = Pick<MapTemplatesProps, 'onSelectTemplate'>;
