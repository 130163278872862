import { createContext } from 'react';

export type ToggleGroupValue = {
  type?: 'single' | 'multiple';
  value?: string;
};

export const ToggleGroupContext = createContext<ToggleGroupValue>(
  {} as ToggleGroupValue,
);
