import { QUERY_KEY_SPACES } from '@zep/apis';

import { PlanTemplateType } from '../components';

export const EmptyHashMapId = '7zKngR';
export type SpaceRole =
  | 'OWNER'
  | 'ADMIN'
  | 'STAFF'
  | 'INSTANT_MASTER'
  | 'CAN_EDIT'
  | 'NORMAL'
  | 'GUEST';

export const SpaceUserRole: Record<string, number> = {
  OWNER: 3001,
  ADMIN: 3000,
  STAFF: 2000,
  INSTANT_MASTER: 1999,
  CAN_EDIT: 1000,
  NORMAL: 0,
  GUEST: -1,
};

export const SpaceUserRoleName: Record<number, string> = Object.keys(
  SpaceUserRole,
).reduce((obj, key) => {
  obj[SpaceUserRole[key]] = key;
  return obj;
}, {} as Record<number, string>);

export const SpaceQueryKeys = {
  /**
   * 내 스페이스 리스트를 가져오는 API(맵 에셋 업로드시 사용)
   */
  MY_SPACE_LIST_GET: 'my-spaces',
  /**
   *  맵 탬플릿을 가져오는 API(내 스페이스 목록 > 템플릿 고르기
   * */
  MAP_TEMPLATE_LIST_GET: 'map-templates',
  PURCHASED_MAP_TEMPLATE_LIST_GET: 'purchased-map-templates',
  DELETE_VISIT_HISTORY: 'visit-history-delete',
  COPY_SPACE: 'my-spaces-copy',
  /**
   * 배너 리스트를 위한 키
   */
  MAIN_BANNERS: 'main-banners',
  SPACE_CATEGORIES_GET: 'space-categories',
  CHANGE_OWNER: 'change-space-owner',
};

export const SpaceSearchQueryKeys = {
  all: ['spaceSearchQuery'] as const,
  queryKey: (query: string, spaceFilter: 'related' | 'visited') => [
    ...SpaceSearchQueryKeys.all,
    query,
    spaceFilter,
  ],
};

/**
 * 방문한 모든 스페이스 목록 API query key
 */
export const VisitedSpaceKeys = {
  all: [QUERY_KEY_SPACES, 'visited'] as const,
  lists: () => [...VisitedSpaceKeys.all, 'list'] as const,
  list: (filters: string) =>
    [...VisitedSpaceKeys.lists(), { filters }] as const,
  details: () => [...VisitedSpaceKeys.all, 'detail'] as const,
  detail: (id: number) => [...VisitedSpaceKeys.details(), id] as const,
};

/**
 * 내 역할이 부여된 스페이스 목록 API query key
 */
export const RelatedMeSpaceKeys = {
  all: [QUERY_KEY_SPACES, 'related'] as const,
  lists: () => [...RelatedMeSpaceKeys.all, 'list'] as const,
  list: (filters: string) =>
    [...RelatedMeSpaceKeys.lists(), { filters }] as const,
  details: () => [...RelatedMeSpaceKeys.all, 'detail'] as const,
  detail: (id: number) => [...RelatedMeSpaceKeys.details(), id] as const,
};

export const PlanCheckoutKeys = {
  Space: (id: string) => ['plan-checkout-space', id],
  RegisterCard: 'register-card',
  RemoveCard: 'remove-card',
  SetCardName: 'set-card-name',
  Checkout: 'plan-checkout',
  Products: 'plan-products',
  SellingSpace: (spaceHashId: string, templateType: PlanTemplateType) => [
    'selling-space',
    spaceHashId,
    templateType,
  ],
  EduProducts: 'plan-edu-products',
  SpaceProducts: 'plan-product',
  BillingInvoice: 'billing-invoice',
  PaypleAuthToken: 'payple-auth-token',
  ExpectedPrice: (spaceHashId: string, spacePlanTemplateId: number) => [
    'expect-price',
    spaceHashId,
    spacePlanTemplateId,
  ],
  ExpectedOnetimePrice: (
    spacePlanHashId: string,
    spacePlanTemplateId: number,
  ) => ['expect-onetime-price', spacePlanHashId, spacePlanTemplateId],
  BillingPayment: (spaceHashId: string) => ['billing-payments', spaceHashId],
  SetZipCode: (place: string) => ['set-zip-code', place],
} as const;

export const PremiumSpaceKeys = {
  SPACE_PLAN: 'premium-space-plan',
  subscribed: 'subscribed',
  channelTalkSubscribed: 'channel-talk-subscribed',
  expired: 'expired',
  detail: 'applied-plan-detail',
};

export const PlanKeys = {
  spaceDetail: (id: string) => ['plan-space-detail', id],
  planDetail: (id: string) => ['plan-detail', id],
  unsubscribe: 'unsubscribe',
  cancelUnsubscribe: 'cancel-unsubscribe',
  cancelDowngrade: 'cancel-downgrade',
};

export const IsoCode = 'iso-code';
