import { ReactNode } from 'react';
import cns from 'classnames';

import S from './Chip.module.scss';

export const Chip = ({
  children,
  leftSlot,
  rightSlot,
  variant = 'default',
  className,
}: ChipProps) => {
  return (
    <div className={cns([S.wrapper, S[`chip_${variant}`], className])}>
      {leftSlot && <span className={S.slot}>{leftSlot}</span>}
      <span className={S.text}>{children}</span>
      {rightSlot && <span className={S.slot}>{rightSlot}</span>}
    </div>
  );
};

type ChipProps = {
  children: string;
  leftSlot?: ReactNode;
  rightSlot?: ReactNode;
  variant?: 'default' | 'primary' | 'alert';
  className?: string;
};
