import React, { ComponentPropsWithoutRef, ReactNode } from 'react';

import { cn } from '@zep/utils';

const BottomLogo = () => {
  return (
    <img
      width={57}
      height={20}
      className="absolute bottom-[40px]"
      src={'/images/light/layout/logo_zep.svg'}
      alt="zep"
    />
  );
};
export const ErrorPageWrapper = (
  props: ComponentPropsWithoutRef<'div'> & { children: ReactNode },
) => {
  const { className, children } = props;
  return (
    <div
      className={cn(
        'fixed bg-gray-50 flex h-dvh w-full items-center justify-center p-[16px] z-10 pointer-events-auto',
        className,
      )}
      {...props}>
      {children}
      <BottomLogo />
    </div>
  );
};
