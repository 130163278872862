import { AriaRole, FormEvent, ReactNode, useState } from 'react';

import S from './ToggleGroup.module.scss';
import { ToggleGroupContext, ToggleGroupValue } from './ToggleGroupContext';

/**
 * @description
 * 이 컴포넌트는 순수하게 HTMLInputElement 를 사용하고 있으며, 기본적으로 감춰져 있습니다.
 * 이 컴포넌트의 예제 코드는 ToggleGroup.stories.tsx 파일에서 자세히 확인해주세요.
 * 스토리북에서 안내하는 예제 코드는 정확하지 않습니다.
 *
 * @param type `single` | `multiple` 타입은 기본적으로 단일 선택, 다중 선택으로 나뉘며, 단일 선택은 radio 타입을 가지고 있고 다중 선택은 checkbox 타입으로 작동합니다.
 *
 * @example
 * <form /> 을 감싸서 사용하는 방법은 다음과 같습니다. checked 상태를 받아서 true, false 로 반환합니다. 이걸 이용해 선택, 미 선택 스타일을 표현합니다.
 * 단일 선택은 그룹핑을 하기 위해 name 은 같아야 하며, 다중 선택은 개별 선택요소이기 때문에 name 은 달라야 합니다.
 *
 * ```tsx
 * <form onSubmit={...}>
 *   <ToggleGroup type="single | multiple">
 *     <ToggleGroupOption name="radio-group">
 *       {({ checked }) => {`${String(checked)}`}}
 *     </ToggleGroupOption>
 *   </ToggleGroup>
 * </form>
 * ```
 */
export const ToggleGroup = (props: ToggleGroupProps) => {
  const [value, setValue] = useState<string>();
  const { type = 'single', children } = props;
  const role: AriaRole = type === 'single' ? 'radiogroup' : 'none';

  const handleChange = (e: FormEvent) => {
    setValue((e.target as HTMLInputElement).value);
  };

  return (
    <ToggleGroupContext.Provider value={{ type, value }}>
      <div role={role} className={S.group} onChange={handleChange}>
        {children}
      </div>
    </ToggleGroupContext.Provider>
  );
};

type ToggleGroupProps = ToggleGroupValue & {
  children: ReactNode;
};
