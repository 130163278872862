import { MouseEventHandler, PropsWithChildren, ReactNode } from 'react';
import cns from 'classnames';

import S from './Dock.module.scss';

export const DockItem = (props: DockItemProps & PropsWithChildren) => {
  const {
    icon,
    color,
    isDisabled = false,
    isActive = false,
    onClick,
    children,
  } = props;

  return (
    <button
      type="button"
      disabled={isDisabled}
      aria-disabled={isDisabled}
      className={cns(S.button, {
        [S.active]: isActive,
        [S.red]: color === 'red',
      })}
      onClick={event => {
        (document.activeElement as HTMLElement).blur();
        if (onClick) {
          onClick(event);
        }
      }}>
      {icon}
      <span className={S.title}>{children}</span>
    </button>
  );
};

type DockItemProps = {
  icon: ReactNode;
  color?: 'red';
  isDisabled?: boolean;
  isActive?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};
