import { PropsWithChildren, SyntheticEvent } from 'react';

import { XIcon } from '@zep/icons';

import { cn } from '../../utils/tailwind';
import { IconContainer } from '../IconContainer';

import S from './Dialog.module.scss';

export const DialogHeader = (props: PropsWithChildren<DialogHeaderProps>) => {
  const { className, topSlot, leftSlot, rightSlot, onClose, children } = props;

  const handleClose = (e: SyntheticEvent) => {
    onClose && onClose(e);
  };

  return (
    <header className={cn(S.header, className)}>
      {topSlot && <span className={cn(S.col, S.topSlot)}>{topSlot}</span>}
      <div className={S.col}>
        {leftSlot && <div className={cn(S.slot, S.leftSlot)}>{leftSlot}</div>}
        <div className={cn(S.slot, S.title)}>{children}</div>
        {(rightSlot || onClose) && (
          <div className={cn(S.slot, S.rightSlot)}>
            {rightSlot && rightSlot}
            {onClose && (
              <IconContainer
                type="button"
                onClick={handleClose}
                className={S.close}>
                <XIcon width={20} height={20} aria-label="Close" />
              </IconContainer>
            )}
          </div>
        )}
      </div>
    </header>
  );
};

type DialogHeaderProps = {
  topSlot?: React.ReactNode;
  leftSlot?: React.ReactNode;
  rightSlot?: React.ReactNode;
  className?: string;
  onClose?: (e: SyntheticEvent) => void;
};
