import cn from 'classnames';

import S from './Divider.module.scss';

export const Divider = (props: DividerProps) => {
  const { style } = props;

  return <hr className={cn(S.divider, props.className)} style={{ ...style }} />;
};

type DividerProps = {
  style?: React.CSSProperties;
  className?: string;
};
