import { forwardRef, HTMLProps, ReactNode, useId } from 'react';
import cns from 'classnames';

import S from './Toggle.module.scss';

export const Toggle = forwardRef<HTMLInputElement, ToggleProps>(function Toggle(
  props,
  ref,
) {
  const toggleId = useId();
  const {
    label,
    text,
    labelPlacement = 'left',
    isFullWidth,
    className,
    ...rest
  } = props;

  const ToggleComponent = (
    <span className={S.toggle}>
      <input
        ref={ref}
        id={toggleId}
        type="checkbox"
        data-checkbox-control
        {...rest}
      />
      <span aria-hidden="true" data-checkbox-icon></span>
    </span>
  );

  if (label || text) {
    return (
      <div
        className={cns(
          S.row,
          {
            [S.checked]: rest.checked,
            [S.disabled]: rest.disabled,
            [S.fullWidth]: isFullWidth,
          },
          className,
        )}>
        {labelPlacement === 'left' && ToggleComponent}
        <div className={S.col}>
          {label && (
            <label htmlFor={toggleId} className={S.label}>
              {label}
            </label>
          )}
          {text && <p className={S.text}>{text}</p>}
        </div>
        {labelPlacement === 'right' && ToggleComponent}
      </div>
    );
  }

  return ToggleComponent;
});

type ToggleProps = {
  label?: ReactNode;
  labelPlacement?: 'left' | 'right';
  text?: ReactNode;
  isFullWidth?: boolean;
  className?: string;
} & Omit<HTMLProps<HTMLInputElement>, 'label'>;
