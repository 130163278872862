import Head from 'next/head';
import { useRouter } from 'next/router';

import { SeoProperties } from '../../../types/GlobalTypes';
import { isDevelopment, isStage } from '../../../utils';

const defaultDescriptions = {
  en: 'Welcome to ZEP, the Metaverse for Everyone',
  ja: 'みんなのメタバース',
  ko: '모두를 위한 메타버스 ZEP으로 초대합니다!',
};

const Meta = (props: MetaProps) => {
  const { partialSeoContent } = props;
  const { locale, locales, asPath } = useRouter();
  const defaultDescription =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    defaultDescriptions[locale] ?? defaultDescriptions['ko'];

  const seoContent = {
    title: partialSeoContent?.title ?? 'ZEP',
    subject: partialSeoContent?.subject ?? '',
    description:
      partialSeoContent?.description ??
      defaultDescription ??
      '모두를 위한 메타버스 ZEP으로 초대합니다!',
    image:
      partialSeoContent?.image ??
      'https://zep.us/images/thumbnail.png?ver=22.10.3',
    keywords:
      partialSeoContent?.keywords ??
      'ZEP, metaverse, zepeto, meta, meta commerce, avatar, map, space, virtual world, game, zep script, creator',
    copyright: partialSeoContent?.copyright ?? 'ZEP Inc',
    author: partialSeoContent?.author ?? '',
    schema: partialSeoContent?.schema ?? {},
  };

  return (
    <Head>
      <title>{seoContent.title}</title>
      <meta charSet="UTF-8" />
      <meta
        name="google-signin-client_id"
        content="193885560050-841lokv1235nmutetrie7p2mdof8k1kp.apps.googleusercontent.com"
      />
      <meta
        name="naver-site-verification"
        content="4dcf5ebf7b8c40900070239aa6b08ffca05509f8"
      />
      <meta name="viewport" content="width=device-width" />
      <meta name="description" content={seoContent.description} />
      <meta name="subject" content={seoContent.subject} />
      <meta name="keywords" content={seoContent.keywords} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={seoContent.title} />
      <meta property="og:url" content={process.env.NEXT_PUBLIC_BASE_URL} />
      <meta
        property="og:title"
        content={seoContent.subject || seoContent.title}
      />
      <meta property="og:description" content={seoContent.description} />
      <meta property="og:image" content={seoContent.image} />
      <meta property="og:locale" content={locale} />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:title" content={seoContent.title} />
      <meta property="twitter:description" content={seoContent.description} />
      <meta property="twitter:image" content={seoContent.image} />
      <meta name="application-name" content="ZEP" />
      <meta name="copyright" content={seoContent.copyright} />
      <meta name="author" content={seoContent.author} />
      <meta name="reply-to" content="hello@zep.us" />
      <link rel="icon" href="/favicon.ico" />
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        locales
          .filter(lang => locale !== lang)
          .map(lang => (
            <link
              key={lang}
              rel="alternate"
              hrefLang={lang}
              href={`${process.env.NEXT_PUBLIC_BASE_URL}/${lang}${asPath}`}
            />
          ))
      }
      {isDevelopment || isStage ? (
        <>
          <meta name="robots" content="noindex" />
          <meta name="googlebot" content="noindex" />
        </>
      ) : (
        <meta name="robots" content="index, follow" />
      )}
      {seoContent.schema['@context'] && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(seoContent.schema),
          }}
        />
      )}
    </Head>
  );
};

export default Meta;

export interface MetaProps {
  partialSeoContent?: Partial<SeoProperties>;
}
