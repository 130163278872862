import { ReactNode } from 'react';
import cns from 'classnames';

import S from './TextField.module.scss';

export const TextField = (props: TextFieldProps) => {
  const {
    id,
    size = 'md',
    label,
    labelClassName,
    rightSlotOfLabel,
    rightSlot,
    description,
    helperText,
    isRequired,
    error,
    children,
    className,
    contentClassName,
  } = props;

  return (
    <div
      role="group"
      className={cns(S.text_field, S[`size_${size}`], className)}>
      {(label || description || rightSlot || rightSlotOfLabel) && (
        <div className={S.header}>
          <div className={S.left}>
            {label && (
              <label htmlFor={id} className={cns(S.label, labelClassName)}>
                {label}
                {isRequired && <span className={S.required}> *</span>}
                {rightSlotOfLabel && (
                  <span className={S.right_slot}>{rightSlotOfLabel}</span>
                )}
              </label>
            )}
            {description && <div className={S.description}>{description}</div>}
          </div>
          {rightSlot && <span className={S.right_slot}>{rightSlot}</span>}
        </div>
      )}
      <div className={cns(S.content, contentClassName)}>{children}</div>
      {helperText && (
        <div
          className={cns(S.helper_text, {
            [S.error]: error,
          })}>
          {helperText}
        </div>
      )}
    </div>
  );
};

type TextFieldProps = {
  id?: string;
  size?: 'sm' | 'md';
  name?: string;
  label?: string | ReactNode;
  labelClassName?: string;
  rightSlotOfLabel?: string | ReactNode;
  rightSlot?: string | ReactNode;
  description?: string | ReactNode;
  helperText?: string | ReactNode;
  error?: boolean;
  children: ReactNode;
  isRequired?: boolean;
  tooltipText?: string;
  className?: string;
  contentClassName?: string;
};
