import { forwardRef, HTMLProps, ReactNode, useId } from 'react';
import cns from 'classnames';

import S from './Radio.module.scss';

export const Radio = forwardRef<HTMLInputElement, RadioProps>(function Radio(
  props,
  ref,
) {
  const radioId = useId();
  const { label, text, labelPlacement = 'left', isFullWidth, ...rest } = props;

  const RadioComponent = (
    <span className={cns(S.radio)}>
      <input ref={ref} id={radioId} type="radio" data-radio-control {...rest} />
      <span aria-hidden="true" data-radio-icon></span>
    </span>
  );

  if (label || text) {
    return (
      <div
        className={cns(S.row, {
          [S.checked]: rest.checked,
          [S.disabled]: rest.disabled,
          [S.fullWidth]: isFullWidth,
        })}>
        {labelPlacement === 'left' && RadioComponent}
        <div className={S.col}>
          {label && (
            <label htmlFor={radioId} className={S.label}>
              {label}
            </label>
          )}
          {text && <p className={S.text}>{text}</p>}
        </div>
        {labelPlacement === 'right' && RadioComponent}
      </div>
    );
  }

  return RadioComponent;
});

type RadioProps = {
  label: ReactNode;
  labelPlacement: 'left' | 'right';
  text?: ReactNode;
  isFullWidth?: boolean;
} & Omit<HTMLProps<HTMLInputElement>, 'label'>;
