import React, { PropsWithChildren, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { When } from 'react-if';

import { QuestionmarkSmallCircleColorIcon } from '@zep/icons';
import { SpaceUsageType } from '@zep/types';
import {
  Input,
  InputTextLengthLabel,
  Stack,
  TextField,
  Toggle,
  Tooltip,
} from '@zep/ui';
import { cn, isMobileDevice } from '@zep/utils';

import { CreateSpaceSchemaType, SpaceSchemaSpec } from '../schema';

import S from './SpaceCreateSettingModal.module.scss';

export const SpaceCreateSettingContent = ({
  isLoadingSubmit,
}: {
  isLoadingSubmit: boolean;
}) => {
  const { t } = useTranslation(['common']);

  const { register, watch, setValue, getValues } =
    useFormContext<CreateSpaceSchemaType>();

  const isSetPassword = watch('enablePassword');
  const spaceUsageType = watch('spaceUsageType');
  const name = watch('name');

  useEffect(() => {
    if (!isSetPassword) {
      setValue('password', '');
    }
  }, [getValues, setValue, isSetPassword]);

  const toggleSetPassword = () => {
    setValue('enablePassword', !getValues('enablePassword'));
  };

  const toggleSpaceUsageType = (event: React.FormEvent<HTMLInputElement>) => {
    setValue(
      'spaceUsageType',
      event.currentTarget.checked
        ? SpaceUsageType.PUBLIC
        : SpaceUsageType.PRIVATE,
    );
  };

  return (
    <Stack gap={16} className={S.content}>
      <TextField
        label={
          <TextFiledLabel>
            {t('common:createSpaceModal.nameLabel')}
          </TextFiledLabel>
        }
        rightSlot={
          <InputTextLengthLabel
            inputLength={name.length}
            maxInputLength={SpaceSchemaSpec.name.maxLength}
          />
        }>
        <Input
          size={'sm'}
          isFullWidth
          {...register('name')}
          placeholder={t('common:createSpaceModal.namePlaceHolder')}
          maxLength={SpaceSchemaSpec.name.maxLength}
          disabled={isLoadingSubmit}
        />
      </TextField>
      <Toggle
        isFullWidth
        checked={spaceUsageType === SpaceUsageType.PUBLIC}
        onChange={toggleSpaceUsageType}
        label={
          <Stack direction={'row'} gap={4} style={{ alignItems: 'center' }}>
            <span>{t('common:spaces.create.spaceUsageType')}</span>
            <div
              className={cn('rounded-full px-[8px] ml-auto py-[2px]', {
                'bg-primaryBg text-primary':
                  spaceUsageType === SpaceUsageType.PUBLIC,
                'bg-gray-200 text-gray-600':
                  spaceUsageType === SpaceUsageType.PRIVATE,
              })}>
              <span className="text-caption-1 font-[600]">
                {spaceUsageType === SpaceUsageType.PUBLIC
                  ? t('common:spaces.create.public')
                  : t('common:spaces.create.private')}
              </span>
            </div>
            <When condition={!isMobileDevice()}>
              <Tooltip
                placement="top"
                tooltipClassName="max-w-[300px]"
                title={t('common:spaces.create.tooltip')}>
                <QuestionmarkSmallCircleColorIcon width={24} height={24} />
              </Tooltip>
            </When>
          </Stack>
        }
        labelPlacement={'right'}
        disabled={isLoadingSubmit}
      />
      <Stack gap={5}>
        <Toggle
          label={t('common:createSpaceModal.passwordLabel')}
          labelPlacement={'right'}
          isFullWidth
          checked={isSetPassword}
          onChange={toggleSetPassword}
          disabled={isLoadingSubmit}
        />
        <When condition={isSetPassword}>
          <Input
            size={'sm'}
            {...register('password')}
            placeholder={t('common:createSpaceModal.passwordPlaceHolder')}
            maxLength={SpaceSchemaSpec.password.maxLength}
            disabled={isLoadingSubmit}
          />
        </When>
      </Stack>
    </Stack>
  );
};

const TextFiledLabel = (prop: PropsWithChildren) => (
  <span className={S.text_filed_label}>{prop.children}</span>
);
