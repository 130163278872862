import { useTranslation } from 'react-i18next';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { zepAnalytics } from '@zep/analytics';
import { CreateSpaceV2Request, useCreateSpaceV2 } from '@zep/apis';
import { useDialog } from '@zep/dialog';
import { useFunnel } from '@zep/react';
import { SpaceUsageType } from '@zep/types';

import { MapTemplateModal, MapTemplateModalProps } from './MapTemplateModal';
import { CreateSpaceSchemaType } from './schema';
import { SpaceCreateSettingModal } from './SpaceCreateSettingModal';

interface CreateSpaceFunnelProps {
  from?: 'home' | 'play';
}

const create_space_complete_map = {
  home: 'home_create_space_complete',
  play: 'play_create_space_complete',
};

const _CreateSpaceFunnel = ({ from = 'home' }: CreateSpaceFunnelProps) => {
  const [Funnel, { mapTemplate }, setFunnelState] = useFunnel([
    'map-template',
    'space-setting',
  ]).withState<CreateSpaceFunnelState>({});
  const modal = useModal();
  const { t } = useTranslation(['common']);
  const dialog = useDialog();
  const createSpaceMutation = useCreateSpaceV2();

  const setTemplate: MapTemplateModalProps['onSelectTemplate'] = template => {
    setFunnelState({
      step: 'space-setting',
      mapTemplate: template,
    });
  };

  const createSpace = async (data: CreateSpaceSchemaType) => {
    const templateHashId = mapTemplate?.hashId ?? emptyTemplateHashId;
    const isPurchased = mapTemplate?.isPurchased ?? false;
    try {
      const request: CreateSpaceV2Request = {
        name: data.name,
        spaceUsageType: data.spaceUsageType,
        enablePassword: false,
      };
      if (data.enablePassword && data.password?.trim().length) {
        request.enablePassword = true;
        request.password = data.password;
      }
      if (isPurchased) {
        request.purchasedTemplateHashId = templateHashId;
      } else {
        request.templateHashId = templateHashId;
      }
      const space = await createSpaceMutation.mutateAsync(request);

      const url = !mapTemplate
        ? `/edit/${space.hashId}/${space.entryMapHashId}`
        : `/play/${space.entryMapHashId}`;
      window.open(url, '_blank', 'noopener,noreferrer');

      modal.hide();
      const eventProps: Record<string, unknown> = {
        map_template_id: templateHashId,
        template_name: mapTemplate?.name ?? '빈맵',
        place: 'play',
        space_name: space.name,
        spaceUsageType:
          space.options.spaceUsageType === SpaceUsageType.PRIVATE
            ? 'private'
            : 'public',
        password: space.options.enablePassword ? 'true' : 'false',
        category: space.categoryIds.map(String),
      };

      const completeEventKey = create_space_complete_map[from];
      zepAnalytics.track(completeEventKey, eventProps);
    } catch (e) {
      console.error(e);
      dialog.alert({
        title: t('common:error.api.unknown'),
      });
    }
  };

  return (
    <Funnel>
      <Funnel.Step name="map-template">
        <MapTemplateModal onSelectTemplate={setTemplate} />
      </Funnel.Step>
      <Funnel.Step name="space-setting">
        <SpaceCreateSettingModal
          isLoading={createSpaceMutation.isLoading}
          onSubmit={createSpace}
        />
      </Funnel.Step>
    </Funnel>
  );
};

const emptyTemplateHashId = '7zKngR';

type CreateSpaceFunnelState = {
  mapTemplate?: Parameters<MapTemplateModalProps['onSelectTemplate']>[0];
};

export const CreateSpaceFunnel =
  NiceModal.create<CreateSpaceFunnelProps>(_CreateSpaceFunnel);
