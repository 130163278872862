import React, { createContext, useContext, useEffect, useState } from 'react';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { ZepApiResponse } from './types';
import { ZepApiError } from './ZepApiError';

export const createApiClient = (axiosConfig?: AxiosRequestConfig) => {
  const client = axios.create(axiosConfig);
  client.interceptors.response.use(
    (response: AxiosResponse<ZepApiResponse>) => {
      if (response.data.status !== undefined && response.data.status < 0) {
        throw ZepApiError.createErrorFromResponse(response.data);
      }
      return response;
    },
  );
  return client;
};

export const defaultConfig = {
  baseURL: '/api',
  withCredentials: true,
  headers: {
    'ngrok-skip-browser-warning': 'true',
  },
};

const ZepApiClientContext = createContext<AxiosInstance>(createApiClient());

export const ZepApiProvider = (props: ZEPApiProviderProps) => {
  const { axiosConfig = defaultConfig, children } = props;
  const [apiClient, setApiClient] = useState(() =>
    createApiClient(axiosConfig),
  );

  useEffect(() => {
    setApiClient(() => createApiClient(axiosConfig));
  }, [axiosConfig]);

  return (
    <ZepApiClientContext.Provider value={apiClient}>
      {children}
    </ZepApiClientContext.Provider>
  );
};

export const useZepApiClient = () => {
  return useContext(ZepApiClientContext);
};

export interface ZEPApiProviderProps {
  axiosConfig?: AxiosRequestConfig;
  children: React.ReactNode;
}
