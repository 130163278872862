import { DependencyList, useRef } from 'react';
import { useIsomorphicLayoutEffect } from '@toss/react';

export function useLatestValue<T>(
  value: T,
  dependencies: DependencyList = [value],
) {
  const valueRef = useRef<T>(value);

  useIsomorphicLayoutEffect(() => {
    if (valueRef.current !== value) {
      valueRef.current = value;
    }
  }, dependencies);

  return valueRef;
}
