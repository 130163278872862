import { ReactNode, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import cns from 'classnames';

import { Button } from '../Button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPanel,
  DialogTitle,
} from '../Dialog';

import S from './AlertDialog.module.scss';

export const AlertDialog = (props: AlertDialogProps) => {
  const {
    maxWidth = '320px',
    topSlot,
    title,
    content,
    dimmed = true,
    keyboard = true,
    useOutSideClick = false,
    confirmText,
    focus,
    onClose,
    onConfirm,
    ...rest
  } = props;
  const { t } = useTranslation(['common']);

  const handleClose = () => {
    if (!useOutSideClick) {
      return;
    }
    onClose && onClose();
  };

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    // props 로 제어하고 싶을때만 준다. (복수개 인케이스)
    if (focus && buttonRef.current) {
      buttonRef?.current.focus();
    }
  }, [focus]);

  return (
    <Dialog
      maxWidth={maxWidth}
      open
      onClose={handleClose}
      initialFocus={buttonRef}
      onKeyDown={e => !keyboard && e.stopPropagation()}
      {...rest}>
      {dimmed && <DialogOverlay />}
      <DialogPanel>
        <DialogHeader topSlot={topSlot} className={cns(!content && S.header)}>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        {content && <DialogContent preLine>{content}</DialogContent>}
        <DialogFooter>
          <Button ref={buttonRef} size="md" onClick={onConfirm}>
            {confirmText ?? t('common:confirm')}
          </Button>
        </DialogFooter>
      </DialogPanel>
    </Dialog>
  );
};

export type AlertDialogProps = {
  maxWidth?: string;
  topSlot?: ReactNode;
  title: ReactNode;
  content?: ReactNode;
  dimmed?: boolean;
  keyboard?: boolean;
  useOutSideClick?: boolean;

  confirmText?: ReactNode;
  onClose?: () => void;
  onConfirm?: () => void;
  focus?: boolean;
};
