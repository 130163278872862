import { useQuery } from '@tanstack/react-query';

import { getServerSession } from './index';

export const ASP_SESSION_QUERY_KEY = ['ASP_SESSION'];

type UseAspSessionQuery = typeof useQuery<string, unknown, string | null>;
type AspSessionQueryOptions = Parameters<UseAspSessionQuery>[2];

const getAspSessionQueryFunction: Parameters<UseAspSessionQuery>[1] =
  async () => {
    const data = await getServerSession();
    return data.serverSession;
  };

export const useAspSessionQuery = (options?: AspSessionQueryOptions) => {
  return useQuery<string, unknown, string | null>(
    ASP_SESSION_QUERY_KEY,
    getAspSessionQueryFunction,
    {
      retry: false,
      suspense: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      useErrorBoundary: false,
      ...options,
    },
  );
};
