import { z } from 'zod';

/**
 *  파일을 검사할수잇는 zod schema,
 *  항상 onChange Event 안에서 parse 하자(수동으로 데이터, 에러 심어줘야함)
 *
 *  @example
 *  try {
 *      thumbnailSchema.parse(e.target.files as FileList)
 *
 *      setValue(imageType, files[0], {
 *         shouldDirty: true,
 *         shouldTouch: true,
 *         shouldValidate: false,
 *      });
 *
 *     } catch (error: unknown) {
 *       if (error instanceof ZodError) {
 *         setError(imageType, {
 *           message: error.issues[0].message,
 *         });
 *         return;
 *       }
 * */
export const zFile = ({
  fileSize,
  allowedImageExt,
}: {
  fileSize: {
    value: number;
    message: string;
  };
  allowedImageExt: {
    value: string[];
    message: string;
  };
}) => {
  return z
    .any()
    .refine(
      (files: File[]) => {
        return files[0].size <= fileSize.value;
      },
      {
        message: fileSize.message,
      },
    )
    .refine(
      (files: File[]) => {
        // 확장자 검사 안하는 케이스
        if (allowedImageExt.value.length === 0) return true;

        const fileType = files[0].name.split('.').pop()?.toLowerCase() ?? '';
        return allowedImageExt.value.includes(fileType);
      },
      {
        message: allowedImageExt.message,
      },
    );
};
