import { PropsWithChildren } from 'react';
import cns from 'classnames';

import S from './Dock.module.scss';

export const Dock = (props: PropsWithChildren<DockProps>) => {
  const { className, children } = props;
  return <div className={cns(S.dock, className)}>{children}</div>;
};

type DockProps = {
  className?: string;
};
