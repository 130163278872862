import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useModal } from '@ebay/nice-modal-react';
import { zodResolver } from '@hookform/resolvers/zod';

import { useViewportDevice } from '@zep/react';
import { SpaceUsageType } from '@zep/types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPanel,
} from '@zep/ui';
import { cn } from '@zep/utils';

import { CreateSpaceSchema, CreateSpaceSchemaType } from '../schema';

import { SpaceCreateSettingContent } from './SpaceCreateSettingContent';
import S from './SpaceCreateSettingModal.module.scss';

export const SpaceCreateSettingModal = (
  props: SpaceCreateSettingModalProps,
) => {
  const { visible, hide, remove } = useModal();
  const { isMobile } = useViewportDevice();
  const { t } = useTranslation(['common']);

  const form = useForm<CreateSpaceSchemaType>({
    resolver: zodResolver(CreateSpaceSchema),
    defaultValues: {
      name: '',
      password: '',
      enablePassword: false,
      spaceUsageType: SpaceUsageType.PUBLIC,
    },
  });

  return (
    <Dialog
      maxWidth="400px"
      drawer={isMobile}
      open={visible}
      onClose={hide}
      afterClose={remove}>
      <DialogOverlay />
      <FormProvider {...form}>
        <DialogPanel>
          <DialogHeader onClose={hide}>
            {t('common:createSpaceModal.spaceSetting')}
          </DialogHeader>
          <DialogContent>
            <SpaceCreateSettingContent isLoadingSubmit={props.isLoading} />
          </DialogContent>
          <DialogFooter>
            <Footer {...props} />
          </DialogFooter>
        </DialogPanel>
      </FormProvider>
    </Dialog>
  );
};

const Footer = (props: SpaceCreateSettingModalProps) => {
  const { isLoading, onSubmit } = props;
  const {
    watch,
    handleSubmit,
    formState: { isDirty, errors },
  } = useFormContext<CreateSpaceSchemaType>();
  const { t } = useTranslation(['common']);

  const name = watch('name');
  const isValid = Object.values(errors).length === 0 && name.trim().length > 0;

  return (
    <div
      className={cn(
        'flex items-center flex-col w-full gap-[1.625rem]',
        S.footer_content,
      )}>
      <span className={S.loading_text}>
        {t('common:createSpaceModal.loadingText')}
      </span>

      <Button
        className="min-w-[120px]"
        size={'sm'}
        isFullWidth
        isLoading={isLoading}
        disabled={!isValid || !isDirty || isLoading}
        role={'button'}
        onClick={handleSubmit(onSubmit, console.error)}>
        {t('common:createSpaceModal.confirmText')}
      </Button>
    </div>
  );
};

type SpaceCreateSettingModalProps = {
  isLoading: boolean;
  onSubmit: (data: CreateSpaceSchemaType) => void;
};
