import { useEffect, useRef, useState } from 'react';
import { useThrottle } from '@toss/react';
import cns from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { zepAnalytics } from '@zep/analytics';
import { useAuth } from '@zep/auth';

import { GNBMenu, GNBSubMenu } from '../../../types';
import { getElementComputedWidth } from '../../../utils';

import S from './Header.module.scss';

const baseURL = process.env.NEXT_PUBLIC_BASE_URL;

/**
 * @returns 헤더 메뉴 컴포넌트
 * @param props
 */
const GlobalNavigationBarMenu = (props: GNBMenuProps) => {
  const { headerContent } = props;
  const { pathname } = useRouter();
  const { user } = useAuth();
  const [printCount, setPrintCount] = useState(0);
  const navWrapper = useRef<HTMLElement>(null);
  const buttonAreaWrapper = useRef<HTMLDivElement>(null);

  const changeButtonCount = useThrottle((menuButtonWidths: number[]) => {
    const navWidth = getElementComputedWidth(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      navWrapper.current,
    );
    const buttonCount = menuButtonWidths.length;

    let calculatedButtonAreaWidth = 0;
    for (let index = 0; index < buttonCount; index++) {
      const leftMargin = index > 0 ? 32 : 0;
      calculatedButtonAreaWidth += menuButtonWidths[index] + leftMargin;
      if (Math.ceil(calculatedButtonAreaWidth) >= navWidth) {
        setPrintCount(index);
        break;
      } else if (index === buttonCount - 1) {
        setPrintCount(buttonCount);
        break;
      }
    }
  }, 100);

  useEffect(() => {
    let menuButtonWidths: number[] = [];

    const getMenuButtonWidths = () => {
      const buttonWidths: number[] = [];
      const buttonCount = headerContent.length;
      for (let menuIndex = 0; menuIndex < buttonCount; menuIndex++) {
        const button = document.querySelector(`#GNB_menu_${menuIndex}`);
        if (button) {
          const width = getElementComputedWidth(button);
          if (width === 0) {
            return [];
          }
          buttonWidths.push(width);
        }
      }

      return buttonWidths;
    };

    const buttonAreaResizeObserver = new ResizeObserver(() => {
      if (headerContent.length > 0) {
        menuButtonWidths = getMenuButtonWidths();
        changeButtonCount(menuButtonWidths);
      }
    });
    const navResizeObserver = new ResizeObserver(() => {
      if (menuButtonWidths.length > 0) {
        changeButtonCount(menuButtonWidths);
      }
    });

    buttonAreaResizeObserver.observe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      buttonAreaWrapper.current,
    );
    navResizeObserver.observe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      navWrapper.current,
    );

    return () => {
      navResizeObserver.disconnect();
      buttonAreaResizeObserver.disconnect();
    };
  }, [changeButtonCount, headerContent.length]);

  return (
    <nav
      className={cns(S.desktop_nav, { [S.desktop_nav_edu]: user?.isEdu })}
      ref={navWrapper}>
      <div className={S.nav_button_wrapper} ref={buttonAreaWrapper}>
        {headerContent.map((menu: GNBMenu, menuIndex) => (
          <div
            className={cns(S.header_menu, {
              [S.hidden]: menuIndex > printCount - 1,
            })}
            id={`GNB_menu_${menuIndex}`}
            key={menu.title}>
            <div className={S.gnb_menu}>
              <div className={cns(S.menu_button)}>
                {menu.path ? (
                  <Link
                    href={((menu.useBaseURL && baseURL) || '') + menu.path}
                    className={cns(
                      pathname.includes(menu.path) && S.active_item,
                    )}
                    target={menu.target}
                    onClick={() =>
                      zepAnalytics.track(
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        menu.eventLog,
                      )
                    }
                    prefetch={false}>
                    {menu.title}
                    {menu.beta && (
                      <img
                        width={29}
                        height={18}
                        src="/images/light/layout/beta_badge-primary.svg"
                        alt="beta badge"
                      />
                    )}
                  </Link>
                ) : (
                  <button type="button">{menu.title}</button>
                )}
                {menu.menus && (
                  <img
                    className={S.menu_icon}
                    width={12}
                    height={12}
                    src={`/images/light/common/chevron-down.svg`}
                    alt="dropdown menu"
                  />
                )}
              </div>
              {menu.menus && (
                <div className={S.menu_items}>
                  {menu.menus
                    .filter((menus: GNBSubMenu) => !menus.hidden)
                    .map((menus: GNBSubMenu) => (
                      <div key={menus.name}>
                        <Link
                          href={
                            ((menus.useBaseURL && baseURL) || '') + menus.url
                          }
                          target={menus.target}
                          onClick={() => zepAnalytics.track(menus.eventLog)}
                          prefetch={false}>
                          {menus.name}
                          {menus.beta && (
                            <img
                              width={29}
                              height={18}
                              src="/images/light/layout/beta_badge-primary.svg"
                              alt="beta badge"
                            />
                          )}
                        </Link>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </nav>
  );
};

export default GlobalNavigationBarMenu;

type GNBMenuProps = {
  headerContent: GNBMenu[];
};
