import {
  ButtonHTMLAttributes,
  ComponentPropsWithoutRef,
  ElementType,
  forwardRef,
  ReactNode,
  SVGProps,
} from 'react';

import { cn } from '../../utils/tailwind';

import S from './Button.module.scss';

const IndicatorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={18} className={S.loading} {...props}>
    <circle
      className={S.circle}
      cx="50%"
      cy="50%"
      r={5}
      stroke="currentColor"
    />
  </svg>
);

export const Button = forwardRef(
  <T extends ElementType = 'button'>(
    props: ButtonProps<T> & ComponentPropsWithoutRef<T>,
    ref: React.Ref<any>,
  ) => {
    const {
      as = 'button',
      variant = 'primary',
      size = 'md',
      leftSlot,
      rightSlot,
      rounded = false,
      transparent = false,
      isLoading,
      isFullWidth,
      className,
      children,
      bordered = false,
      ...rest
    } = props;

    const Comp = as;

    return (
      <Comp
        ref={ref}
        className={cn(
          S.btn,
          S[`variant_${variant}`],
          S[`size_${size}`],
          {
            [S.fullWidth]: isFullWidth,
            [S.rounded]: rounded,
            [S.transparent]: transparent,
            [S.bordered]: bordered,
          },
          className,
        )}
        aria-disabled={rest.disabled}
        {...rest}>
        {isLoading ? (
          <IndicatorIcon />
        ) : (
          leftSlot && <span className={S.slot}>{leftSlot}</span>
        )}
        <span>{children}</span>
        {rightSlot && <span className={S.slot}>{rightSlot}</span>}
      </Comp>
    );
  },
);

type ButtonProps<T extends ElementType> =
  ButtonHTMLAttributes<HTMLButtonElement> & {
    size?: 'lg' | 'md' | 'sm' | 'xs' | 'xxs';
    disabled?: boolean;
    leftSlot?: ReactNode;
    rightSlot?: ReactNode;
    isLoading?: boolean;
    isFullWidth?: boolean;
    children: ReactNode;
    as?: T;

    /**
     * 버튼의 꾸밈요소
     */
    variant?: 'primary' | 'secondary' | 'sub' | 'alert' | 'white';
    rounded?: boolean;
    bordered?: boolean;
    transparent?: boolean;
    className?: string;
  };
