import * as React from 'react';
import { SVGProps } from 'react';
export const HammerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 2.987a2 2 0 0 1 .699 3.71l-2.574 1.485 5.5 9.525a2 2 0 0 1-.733 2.732l-1.732 1a2 2 0 0 1-2.732-.732l-5.499-9.525-.457.264a2 2 0 0 1-2.732-.732l-.046-.08-.409.236.045.079a2 2 0 0 1-.732 2.732l-.866.5A2 2 0 0 1 3 13.449l-1-1.732a2 2 0 0 1 .732-2.733l.866-.5a2 2 0 0 1 2.685.655l.412-.238a2 2 0 0 1 .777-2.65l6.062-3.5a2 2 0 0 1 1.301-.246L18 2.987Zm-4.533 4.42-.075.042-1.732 1a2.058 2.058 0 0 0-.072.044L9.472 9.714l-1-1.732 6.062-3.5 3.165.482-4.232 2.444Zm-.806 2.775 5.5 9.525 1.731-1-5.499-9.525-1.732 1Zm-8.063.034 1 1.733-.866.5-1-1.732.866-.5Z"
      fill={props.fill || `currentColor`}
    />
  </svg>
);
