import { CSSProperties, ReactNode, SVGProps } from 'react';
import {
  autoUpdate,
  flip,
  FloatingOverlay,
  shift,
  useFloating,
} from '@floating-ui/react';
import { Listbox, Portal } from '@headlessui/react';
import cns from 'classnames';

import S from './Select.module.scss';

const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M16.664 8.753a1 1 0 0 1 .083 1.411l-5.333 6a1 1 0 0 1-1.495 0l-2.666-3a1 1 0 0 1 1.494-1.328l1.92 2.159 4.586-5.16a1 1 0 0 1 1.411-.082Z"
      fill="currentColor"
    />
  </svg>
);

const CarotDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="m14.167 11.667-4.167-5-4.167 5h8.334Z" fill="currentColor" />
  </svg>
);

/**
 * @param value 시스템 Option 과 동일하게 name, value 를 사용하고 있으며, name 은 ReactNode 로 커스텀 가능하도록 설계되었습니다. 만약 multiple 을 선택한 경우 value 는 배열로 노출 됩니다.
 * @param placeholder placeholder 가 없는 경우 options 의 제일 첫번째가 선택됩니다.
 */
export const Select = <T,>(props: SelectProps<T>) => {
  const {
    placeholder,
    size = 'md',
    width,
    name,
    options,
    optionsHeight,
    disabled,
    multiple = false,
    isFullWidth,
    value,
    defaultValue,
    error,
    onChange,
  } = props;

  const { x, y, elements, strategy, refs } = useFloating({
    placement: 'bottom-start',
    middleware: [flip({ altBoundary: true }), shift()],
    whileElementsMounted: autoUpdate,
  });

  const styles = { '--select-width': width } as CSSProperties;

  const isEmpty = (_value: SelectItemType<T> | SelectItemType<T>[]) =>
    Array.isArray(_value) ? !_value.length : !_value;

  const handleChange = (
    selectedValue: SelectItemType<T> & SelectItemType<T>[],
  ) => {
    onChange?.(selectedValue);
  };

  const renderValueText = (_value: SelectItemType<T> | SelectItemType<T>[]) => {
    if (isEmpty(_value)) return placeholder;
    return multiple
      ? (_value as SelectItemType<T>[]).map(option => option.name).join(', ')
      : (_value as SelectItemType<T>)?.name;
  };

  const renderOptionContent = (
    option: SelectItemType<T>,
    selected: boolean,
  ) => (
    <>
      <span className={S.text}>{option.name}</span>
      {selected && <CheckIcon className={S.icon} />}
    </>
  );

  return (
    <div
      className={cns(S.select, { [S.fullWidth]: isFullWidth })}
      style={styles}>
      <Listbox
        value={value}
        defaultValue={
          defaultValue
            ? defaultValue
            : placeholder
            ? undefined
            : multiple
            ? [options[0]]
            : options[0]
        }
        name={name}
        disabled={disabled}
        multiple={multiple}
        onChange={handleChange}>
        {({ open }) => (
          <>
            <Listbox.Button
              ref={refs.setReference}
              className={cns(S.button, S[`size_${size}`], {
                [S.open]: open,
                [S.error]: error,
                [S.disabled]: disabled,
              })}>
              {({ value: _value }) => (
                <>
                  <span className={cns(S.text, isEmpty(_value) && S.empty)}>
                    {renderValueText(_value)}
                  </span>
                  <CarotDownIcon className={S.icon} aria-hidden="true" />
                </>
              )}
            </Listbox.Button>

            {open && (
              <Portal>
                <FloatingOverlay className={S.overlay} />
                <Listbox.Options
                  ref={refs.setFloating}
                  className={S.list}
                  style={{
                    position: strategy,
                    top: y ?? 0,
                    left: x ?? 0,
                    maxWidth:
                      elements.reference?.getBoundingClientRect().width ?? 0,
                    maxHeight: optionsHeight,
                  }}>
                  {options.map((option, index) => (
                    <Listbox.Option
                      className={cns(S.item, option.danger && S.danger)}
                      value={option}
                      disabled={option.disabled}
                      key={index}>
                      {({ selected }) => renderOptionContent(option, selected)}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Portal>
            )}
          </>
        )}
      </Listbox>
    </div>
  );
};

type DefaultType = { name: ReactNode; value: string; disabled?: boolean };

export type SelectItemType<T = DefaultType> = {
  name: ReactNode;
  index?: number;
  // value: string;
  disabled?: boolean;
  danger?: boolean;
} & T;

type SingleSelectProps<T> = {
  multiple?: false;
  onChange?: (selected: SelectItemType<T>) => void;
};

type MultiSelectProps<T> = {
  multiple?: true;
  onChange?: (selected: SelectItemType<T>[]) => void;
};

type SelectProps<T> = {
  width?: string;
  error?: boolean;
  size?: 'sm' | 'md';
  options: SelectItemType<T>[];
  optionsHeight?: string;
  placeholder?: string;
  name?: string;
  disabled?: boolean;
  isFullWidth?: boolean;
  multiple?: boolean;
  value?: SelectItemType<T>;
  defaultValue?: SelectItemType<T>;
} & (SingleSelectProps<T> | MultiSelectProps<T>);
