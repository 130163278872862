import { forwardRef, TextareaHTMLAttributes } from 'react';
import cns from 'classnames';

import S from './Textarea.module.scss';

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    const { size = 'md', error, isFullWidth, className, ...rest } = props;
    return (
      <textarea
        ref={ref}
        {...rest}
        className={cns(
          S.textarea,
          S[`size_${size}`],
          {
            [S.error]: error,
            [S.fullWidth]: isFullWidth,
            [S.disabled]: rest.disabled,
            [S.read_only]: rest.readOnly,
          },
          className,
        )}
      />
    );
  },
);

type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  size?: 'sm' | 'md';
  isFullWidth?: boolean;
  error?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
};
