import { useMemo } from 'react';
import __isMobile from 'ismobilejs';
import { memoize } from 'lodash-es';

import { isApp } from './isApp';
import { isServer } from './isServer';

function _isMobile() {
  if (isServer()) {
    return false;
  }
  const userAgent = __isMobile(window.navigator);

  if (
    userAgent.phone ||
    userAgent.tablet ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document) ||
    isApp()
  ) {
    return true;
  }
  return false;
}

export const isMobileDevice = memoize(_isMobile);
export const isMobile = isMobileDevice;

export function useIsMobile() {
  return useMemo(() => isMobileDevice(), []);
}
