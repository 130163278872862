import { PropsWithChildren, ReactElement } from 'react';
import { useRouter } from 'next/router';

import { isApp } from '@zep/utils';

import { Footer } from './Footer';
import { Header } from './Header';

export const DefaultLayout = (props: PropsWithChildren) => {
  const { children } = props;
  const router = useRouter();

  if (isApp() || router.pathname.split('/')[1] === 'notfound') {
    return <>{children}</>;
  }

  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export const getDefaultLayout = (page: ReactElement) => (
  <DefaultLayout>{page}</DefaultLayout>
);
