import { QueryKey, useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { UseZepApiQueryOptions } from '../zep-api';
import { useZepetofulContext } from '../zepetoful';

export const QUERY_KEY_ZEPETOFUL = 'zepetoful';

export async function fetchZepetofulContent<T>(
  zepetofulClient: AxiosInstance,
  contentId: string,
  locale?: string,
) {
  const { data } = await zepetofulClient.get<T>('', {
    params: {
      'sys.id': contentId,
      ...(locale && { locale }),
    },
  });
  return data;
}

export function useZepetofulContents<
  TQueryFnData extends readonly any[] | Record<string, any> = never,
  TData = TQueryFnData,
>(entryId: string, options?: UseZepApiQueryOptions<TQueryFnData, TData>) {
  const { client, locale } = useZepetofulContext();
  return useQuery(
    [QUERY_KEY_ZEPETOFUL, locale, entryId] as QueryKey,
    () => {
      return fetchZepetofulContent<TQueryFnData>(client, entryId);
    },
    options,
  );
}
