/* eslint-disable @typescript-eslint/no-this-alias,no-prototype-builtins */

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface String {
    /**
     * 문자열의 왼쪽에 원하는 길이가 될 때까지 padString 추가
     * @param padString
     * @param length
     */
    lPad(padString: string, length: number): string;
    /**
     * 첫글자 대문자, 두번쨰 글자부터 소문자로 변경
     */
    capitalize(): string;
    /**
     * 전화번호 포맷으로 변경
     * @param delimiter (default: '-')
     */
    phoneFormat(delimiter: string): string;
    /**
     * 콤마(,)를 포함한 숫자 포맷 문자열로 변경
     */
    numberFormat(): string;
    /**
     * 문자열을 클립보드로 복사
     */
    copyToClipboard(callback: () => void): void;

    /**
     * 문자열의 특정 키 텍스트를 replaceWord 객체의 키 값과 매칭되는 값으로 교체
     * @param replaceWord
     * @param regexp
     */
    bindObject(replaceWord: Record<string, string>, regexp?: RegExp): string;

    /**
     * 한글 문자열의 마지막 글자가 받침을 포함하는지 여부 확인
     */
    hasBatchim(): boolean;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Number {
    /**
     * 숫자 왼쪽에 원하는 길이가 될 때까지 padString 추가한 string
     * @param padString
     * @param length
     */
    lPad(padString: string, length: number): string;
    /**
     * 콤마(,)를 포함한 숫자 포맷 문자열로 변경
     */
    numberFormat(): string;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Array<T> {
    /**
     * 배열의 엘리먼트 위치 변경
     * @param fromIndex
     * @param toIndex
     */
    moveElement(fromIndex: number, toIndex: number): Array<T>;

    /**
     * 배열의 엘리먼트 교환
     * @param firstIndex
     * @param secondIndex
     */
    swapElement(firstIndex: number, secondIndex: number): Array<T>;
  }
}

export const initPrototypes = () => {
  if (!String.prototype.lPad) {
    Object.defineProperties(String.prototype, {
      lPad: {
        enumerable: false,
        value: function (padString: string, length: number): string {
          let self = this || '',
            i = self.length;
          while (i++ < length) {
            self = padString + self;
          }
          return self;
        },
      },
      capitalize: {
        enumerable: false,
        value: function (): string {
          return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
        },
      },
      phoneFormat: {
        enumerable: false,
        value: function (delimiter = '-'): string {
          const reg = this.startsWith('0504')
            ? /(\d{4})(\d{4})(\d)/
            : this.startsWith('02')
            ? /(\d{2})(\d{3,4})(\d{4})/
            : /(\d{3})(\d{3,4})(\d{4})/;

          return this.replace(reg, `$1${delimiter}$2${delimiter}$3`);
        },
      },
      numberFormat: {
        enumerable: false,
        value: function (): string {
          const num = parseFloat(this);
          if (isNaN(num)) {
            return 'isNaN';
          }

          return num.numberFormat();
        },
      },
      copyToClipboard: {
        enumerable: false,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        value: function (callback: () => void = () => {}): void {
          if (window.navigator.clipboard) {
            window.navigator.clipboard.writeText(this).then(callback);
          } else {
            const self = this;
            const element = document.createElement('textarea');
            element.value = self;
            element.setAttribute('readonly', '');
            element.style.position = 'absolute';
            element.style.left = '-9999px';
            document.body.appendChild(element);
            element.select();

            document.execCommand('copy');
            document.body.removeChild(element);
            callback();
          }
        },
      },
      bindObject: {
        enumerable: false,
        value: function (
          replaceWord: Record<string, string> = {},
          regexp = /{{1,2}|}{1,2}/g,
        ): string {
          let self = this || '';
          const matches = self.match(/{{1,2}[a-zA-Z]+?}{1,2}/g);
          if (matches) {
            matches.forEach(function (templateStringToReplace: string) {
              // Strip the special characters to dynamically get the indices of the object
              const templateString: string = templateStringToReplace
                .replace(regexp, '')
                .trim();
              if (replaceWord.hasOwnProperty(templateString)) {
                self = self.replace(
                  templateStringToReplace,
                  replaceWord[templateString],
                );
              }
            });
          }

          return self;
        },
      },
      hasBatchim: {
        enumerable: false,
        value: function (): boolean {
          const finalChrCode = this.charCodeAt(this.length - 1);
          // 0 = 받침 없음, 그 외 = 받침 있음
          const finalConsonantCode = (finalChrCode - 44032) % 28;
          return finalConsonantCode !== 0;
        },
      },
    });

    Object.defineProperties(Number.prototype, {
      numberFormat: {
        enumerable: false,
        value: function (): string {
          if (this === 0) return '0';

          const reg = /(^[+-]?\d+)(\d{3})/;
          let n = this.toString();

          while (reg.test(n)) {
            n = n.replace(reg, '$1' + ',' + '$2');
          }

          return n;
        },
      },
      lPad: {
        enumerable: false,
        value: function (padString: string, length: number): string {
          return String(this).lPad(padString, length);
        },
      },
    });

    Object.defineProperties(Array.prototype, {
      moveElement: {
        enumerable: false,
        value: function (fromIndex: number, toIndex: number) {
          if (fromIndex < 0) {
            fromIndex += this.length;
          }
          if (toIndex < 0) {
            toIndex += this.length;
          }
          if (toIndex >= this.length) {
            let k = toIndex - this.length + 1;
            while (k--) {
              this.push(undefined);
            }
          }
          return this.splice(toIndex, 0, this.splice(fromIndex, 1)[0]);
        },
      },
      swapElement: {
        enumerable: false,
        value: function (firstIndex: number, secondIndex: number) {
          if (this.length <= 1) return this;
          if (firstIndex < 0) firstIndex += this.length;
          if (secondIndex < 0) secondIndex += this.length;
          this.splice(
            secondIndex,
            1,
            this.splice(firstIndex, 1, this[secondIndex])[0],
          );
          return this;
        },
      },
    });
  }
};
