import { NextRouter } from 'next/router';
import qs from 'qs';

export const isLocal =
  !process.env.NEXT_PUBLIC_STAGE || process.env.NEXT_PUBLIC_STAGE === 'LOC';
export const isDevelopment = process.env.NEXT_PUBLIC_STAGE === 'DEV' || isLocal;
export const isStage = process.env.NEXT_PUBLIC_STAGE === 'STG';
export const isProduction = process.env.NEXT_PUBLIC_STAGE === 'PRD';

/**
 * @description 광고 집행 시 utm_* 파라미터를 넣어도 도입까지 진행되었는지 알기 위해 추가된 코드
 * @see https://www.notion.so/teamzep/93b606df80004158bc426370809af459?pvs=4
 */
export const joinUtmQueryParams = (url: string, router: NextRouter) => {
  return url + '?' + qs.stringify({ ...router.query });
};

export const getElementComputedWidth = (element: Element) => {
  const elementStyle = window.getComputedStyle(element, null);
  const width = Number(elementStyle.width.replace('px', ''));
  if (isNaN(width)) {
    return 0;
  }

  return width;
};
