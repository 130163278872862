import * as React from 'react';
import { SVGProps } from 'react';
export const CheckSmallCircleFillIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm14.747-2.336a1 1 0 1 0-1.494-1.328l-4.586 5.159-1.92-2.16a1 1 0 0 0-1.494 1.33l2.666 3a1 1 0 0 0 1.495 0l5.333-6Z"
      fill={props.fill || `currentColor`}
    />
  </svg>
);
