import { useRouter } from 'next/router';

const FontLink = () => {
  const { locale } = useRouter();

  return (
    <>
      {locale !== 'ja' && (
        <link
          rel="stylesheet"
          href="https://fastly.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard.css"
        />
      )}
      {locale === 'ja' && (
        <link
          rel="stylesheet"
          href="https://fastly.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard-jp.css"
        />
      )}
    </>
  );
};

export default FontLink;
