import React, { MouseEvent, ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';

import { Pagination, PaginationProps } from '../Pagination';

import S from './Table.module.scss';

export function Table<T extends { [key: string]: any }>(props: TableProps<T>) {
  const handleRowClick = (e: MouseEvent<HTMLElement>, data: T) => {
    if (props.onRowClick) {
      e.preventDefault();
      props.onRowClick(data);
    }
  };

  return (
    <div className={S.table_wrapper}>
      <div className={S.table_scroll}>
        <table>
          <colgroup>
            {props.columns.map(({ dataIndex, width, hide }, index) =>
              hide ? null : (
                <col
                  key={dataIndex ? String(dataIndex) : index}
                  width={width}
                />
              ),
            )}
          </colgroup>
          <thead>
            <tr>
              {props.columns.map(({ dataIndex, title, hide }, index) =>
                hide ? null : (
                  <th key={dataIndex ? String(dataIndex) : index}>{title}</th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {!props.data.length && props.emptyString ? (
              <tr>
                <td colSpan={props.columns.length}>{props.emptyString}</td>
              </tr>
            ) : (
              props.data.map((entry, index) => (
                <tr key={index}>
                  {props.columns.map(({ dataIndex, render, hide }, cIndex) =>
                    hide ? null : (
                      <td
                        style={{
                          cursor: props.onRowClick ? 'pointer' : 'auto',
                        }}
                        key={
                          `${index}-${cIndex}` +
                          (dataIndex ? `-${String(dataIndex)}` : '')
                        }
                        onClick={e => handleRowClick(e, props.data[index])}>
                        {render
                          ? render(entry)
                          : dataIndex
                          ? String(entry[dataIndex])
                          : ''}
                      </td>
                    ),
                  )}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {props.pagination && <Pagination {...props.pagination} />}
    </div>
  );
}

export const TableSkeleton = ({
  rows = 6,
  cols = 4,
  padding = 50,
}: {
  rows?: number;
  cols?: number;
  padding?: number;
}) => {
  return (
    <div className={S.table_wrapper} style={{ padding }}>
      <div className={S.table_scroll}>
        <table>
          <thead>
            <tr>
              {Array.from({ length: cols }).map((value, index) => (
                <th key={String(index)}>
                  <Skeleton />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: rows }).map((_value, rowIndex) => (
              <tr key={String(rowIndex)}>
                {Array.from({ length: cols }).map((__value, colIndex) => (
                  <td key={colIndex}>
                    <Skeleton />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export type TableColumn<T extends { [key: string]: any }> = {
  title: string | ReactNode;
  dataIndex?: keyof T;
  hide?: boolean;
  width?: number | string;
  render?: (entry: T) => React.ReactNode;
};

type TableProps<T extends { [key: string]: any }> = {
  columns: TableColumn<T>[];
  data: T[];
  pagination?: PaginationProps;
  emptyString?: string | ReactNode;
  onRowClick?: (item: T) => void;
};
