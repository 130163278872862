import {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode,
} from 'react';
import * as Slider from '@radix-ui/react-slider';
import cns from 'classnames';

import { Marker, MarkerProps } from './Marker';
import { RangeSliderProps } from './RangeSlider';
import S from './RangeSlider.module.scss';

const SliderTooltip = ({
  className,
  children,
  vertical = false,
}: {
  className?: string;
  children: ReactNode;
  vertical?: boolean;
}) => {
  return (
    <span
      className={cns(
        S.sliderTooltip,
        className,
        vertical ? S.sliderTooltipVertical : S.sliderTooltipHorizontal,
      )}>
      {children}
    </span>
  );
};

export function Controlled(
  props: Omit<RangeSliderProps, 'value'> & { value: number },
) {
  const {
    width = '45px',
    height = '45px',
    vertical = false,
    onChange,
    max = 100,
    min = 0,
    step = 1,
    hideTooltip = false,
    className,
    value,
    children,
  } = props;

  return (
    <Slider.Root
      className={cns(S.sliderRoot, className)}
      value={value != null ? [value] : undefined}
      max={max}
      min={min}
      step={step}
      onValueChange={values => {
        onChange?.(values[0]);
      }}
      style={{
        width,
        height,
      }}
      orientation={vertical ? 'vertical' : 'horizontal'}>
      <Slider.Track className={S.sliderTrack}>
        <Slider.Range className={S.sliderRange} />
        {/* Marker가 필요로 하는 값을 Context 대신 cloneElement를 이용해서 전달합니다. */}
        {Children.map(children, child => {
          if (isValidElement(child) && child.type === Marker) {
            const isLessThanCurrentValue = child.props.value <= value;
            return cloneElement<MarkerProps>(
              child as ReactElement<MarkerProps>,
              {
                _max: max,
                _vertical: vertical,
                _active: isLessThanCurrentValue,
              },
            );
          }
          return null;
        })}
      </Slider.Track>
      <Slider.Thumb className={S.sliderThumb} aria-label="Volume">
        {hideTooltip ? null : (
          <SliderTooltip vertical={vertical}>{value}</SliderTooltip>
        )}
      </Slider.Thumb>
    </Slider.Root>
  );
}
