import { forwardRef, HTMLProps, ReactNode, SVGProps, useId } from 'react';
import cns from 'classnames';

import S from './Checkbox.module.scss';

// MEMO: 두께가 달라 별도로 체크 아이콘을 사용
const CheckIcon = (props: SVGProps<SVGSVGElement> & { size: number }) => (
  <svg
    width={10 * (props.size / 24)}
    height={8 * (props.size / 24)}
    viewBox={props.viewBox || '0 0 10 8'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M9.664.253a1 1 0 0 1 .083 1.411l-5.333 6a1 1 0 0 1-1.495 0l-2.666-3a1 1 0 1 1 1.494-1.328l1.92 2.159L8.253.335A1 1 0 0 1 9.664.254Z"
      fill="currentColor"
    />
  </svg>
);

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  function Checkbox(props, ref) {
    const checkboxId = useId();
    const {
      label,
      text,
      circle,
      size = 24,
      labelPlacement = 'left',
      isFullWidth,
      ...rest
    } = props;

    const CheckboxComponent = (
      <span
        className={cns(S.checkbox, circle && S.circle)}
        style={{
          width: size,
          height: size,
        }}>
        <input
          ref={ref}
          id={checkboxId}
          type="checkbox"
          data-checkbox-control
          {...rest}
        />
        <span aria-hidden="true" data-checkbox-icon>
          <CheckIcon size={size} />
        </span>
      </span>
    );

    if (label || text) {
      return (
        <div
          className={cns(S.row, {
            [S.checked]: rest.checked,
            [S.disabled]: rest.disabled,
            [S.fullWidth]: isFullWidth,
          })}>
          {labelPlacement === 'left' && CheckboxComponent}
          <div className={S.col}>
            {label && (
              <label htmlFor={checkboxId} className={S.label}>
                {label}
              </label>
            )}
            {text && <p className={S.text}>{text}</p>}
          </div>
          {labelPlacement === 'right' && CheckboxComponent}
        </div>
      );
    }

    return CheckboxComponent;
  },
);

type CheckboxProps = {
  label?: ReactNode;
  labelPlacement?: 'left' | 'right';
  text?: ReactNode;
  circle?: boolean;
  isFullWidth?: boolean;
} & Omit<HTMLProps<HTMLInputElement>, 'label'>;
