import { useTranslation } from 'next-i18next';

import { useZepetofulContents } from '@zep/apis';

import { ZepetofulEntries } from '../../../consts';
import { FooterSNSItemTypes } from '../../../types/GlobalTypes';

import S from './Footer.module.scss';

export const FooterInformation = () => {
  const { t } = useTranslation(['layout'], { useSuspense: true });
  const { data: snsList } = useZepetofulContents<FooterSNSItemTypes[]>(
    ZepetofulEntries.LAYOUT_FOOTER_SNS_LIST,
    { suspense: true },
  );

  return (
    <div className={S.footer_address_wrapper}>
      <div className={S.footer_address}>
        <div>
          <span>{t('layout:footer_companyname')}</span>
          <span className={S.wall}>|</span>
          <span>{t('layout:footer_ceo')}</span>
          <span className={S.wall}>|</span>
          <span>{t('layout:footer_biz_num')}</span>
          <span className={S.wall}>|</span>
          <span>{t('layout:footer_commerce_num')}</span>
        </div>
        <div>
          <span>{t('layout:footer_address')}</span>
          <span className={S.wall}>|</span>
          <span>{t('layout:footer_tel')}</span>
          <span className={S.wall}>|</span>
          <span>{t('layout:footer_fax')}</span>
          <span className={S.wall}>|</span>
          <span>{t('layout:footer_email')}</span>
          <p>{t('layout:footer_copyright')}</p>
        </div>
      </div>

      <div className={S.bottom_wrapper}>
        <div className={S.links_wrapper}>
          <ul className={S.menu_links}>
            {snsList
              ?.filter(link => !link.hidden)
              .map((link, index) => (
                <li key={index}>
                  <a href={link.linkUrl} target="_blank" rel="noreferrer">
                    <img
                      width={22}
                      height={22}
                      src={link.imageUrl}
                      alt={link.imageAlt}
                    />
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
