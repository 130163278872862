import { useEffect } from 'react';
import { useRouter } from 'next/router';

export function useRouterEvents(
  eventType: 'routeChangeComplete',
  callback: (url: string) => void,
) {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url?: string) => {
      if (eventType === 'routeChangeComplete') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        callback(url);
      }
    };

    router.events.on(eventType, handleRouteChange);

    return () => {
      router.events.off(eventType, handleRouteChange);
    };
  }, [eventType, callback, router]);
}
