import { CSSProperties, Fragment, ReactNode } from 'react';
import {
  Dialog as HeadlessDialog,
  DialogProps as HeadlessDialogProps,
  Transition,
} from '@headlessui/react';
import cns from 'classnames';

import { useEscapeOverride } from '../../hooks/useEscapeOverride';

import S from './Dialog.module.scss';

/**
 * @description
 * 이 다이얼로그는 재사용 비중이 제일 큽니다. 나은님과 협의하여 너비, 높이를 엄격하게 다룹니다.
 * 요구 사항이 변경 되거나 추가될 때, 모든 다이얼로그의 영향도를 최대한 고려하여 수정해야하며, 테스트 커버리지가 상당히 넓어집니다.
 *
 * @param {boolean} drawer drawer 모드는 모바일 해상도만 사용됩니다.
 * @param {number} maxWidth 최대 너비 지정: %, px 단위 사용 자유
 * @param {number} maxHeight 최대 높이 지정: %, px 단위 사용 자유
 */
export const Dialog = (props: DialogProps) => {
  const {
    open,
    className,
    maxWidth = '400px',
    maxHeight,
    height = 'auto',
    children,
    drawer,
    afterClose,
    ...rest
  } = props;

  useEscapeOverride(open, () => {
    props?.onClose(false);
  });

  return (
    <Transition show={open} as={Fragment} afterLeave={afterClose}>
      <HeadlessDialog
        {...rest}
        data-no-hotkeys
        className={cns(S.dialog, className as string)}
        data-dialog-drawer={drawer ? 'true' : 'false'}
        style={Object.assign(
          {
            '--dialog-max-width': maxWidth,
            '--dialog-max-height': maxHeight,
            '--dialog-height': height,
          } as CSSProperties,
          rest.style,
        )}>
        {children}
      </HeadlessDialog>
    </Transition>
  );
};

type DialogProps = HeadlessDialogProps<'div'> & {
  children: ReactNode;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  drawer?: boolean;
  afterClose?: () => void;
};
