import { CSSProperties, PropsWithChildren, useMemo } from 'react';
import cns from 'classnames';

import S from './CircularProgress.module.scss';

export const CircularProgress = (
  props: PropsWithChildren<CircularProgressType>,
) => {
  const { color, size = 24, value } = props;
  const MIN = 0;
  const MAX = 100;
  const RADIUS = 5;
  const CIRCUMFERENCE = 2 * Math.PI * RADIUS;
  const strokeDashoffset = useMemo(() => {
    if (value === undefined) return;
    const progress = value / 100;
    return CIRCUMFERENCE * (1 - progress);
  }, [CIRCUMFERENCE, value]);

  return (
    <svg
      role="progressbar"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 12 12`}
      width={size}
      height={size}
      aria-valuenow={value}
      aria-valuemin={MIN}
      aria-valuemax={MAX}
      className={cns(S.progress, value === undefined && S.loading)}
      style={{ '--progress-color': color } as CSSProperties}
      {...props}>
      <circle
        cx="50%"
        cy="50%"
        r={RADIUS}
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray={CIRCUMFERENCE}
        strokeDashoffset={strokeDashoffset}
        className={S.circle}
      />
    </svg>
  );
};

type CircularProgressType = {
  color?: string;
  size?: string | number;
  value?: number;
};
