import {
  ChangeEvent,
  forwardRef,
  HTMLProps,
  InputHTMLAttributes,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { When } from 'react-if';

import S from './ToggleGroup.module.scss';
import { ToggleGroupContext } from './ToggleGroupContext';

const ToggleGroupRadio = forwardRef<HTMLInputElement, ToggleGroupOptionProps>(
  (props, ref) => {
    const { name, defaultChecked, value, children, ...rest } = props;
    const { value: selectedValue } = useContext(ToggleGroupContext) ?? {};
    const checked = selectedValue ? selectedValue === value : defaultChecked;

    return (
      <>
        <input
          ref={ref}
          name={name}
          type="radio"
          className={S.control}
          value={value}
          defaultChecked={checked}
          {...rest}
        />
        {children({ checked })}
      </>
    );
  },
);

const ToggleGroupCheckbox = forwardRef<
  HTMLInputElement,
  ToggleGroupOptionProps
>((props, ref) => {
  const { name, defaultChecked, value, children, ...rest } = props;
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    defaultChecked && setChecked(defaultChecked);
  }, [defaultChecked]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setChecked(e.target.checked);

  return (
    <>
      <input
        ref={ref}
        name={name}
        type="checkbox"
        className={S.control}
        value={value}
        defaultChecked={defaultChecked}
        onChange={handleChange}
        {...rest}
      />
      {children({ checked })}
    </>
  );
});

export const ToggleGroupOption = forwardRef<
  HTMLInputElement,
  ToggleGroupOptionProps
>((props, ref) => {
  const { type } = useContext(ToggleGroupContext) ?? {};

  return (
    <label className={S.option}>
      <When condition={type === 'single'}>
        <ToggleGroupRadio ref={ref} {...props} />
      </When>
      <When condition={type === 'multiple'}>
        <ToggleGroupCheckbox ref={ref} {...props} />
      </When>
    </label>
  );
});

type ToggleGroupOptionProps = Omit<
  HTMLProps<HTMLInputElement>,
  'ref' | 'children'
> & {
  name?: string;
  defaultChecked?: boolean;
  value: InputHTMLAttributes<HTMLInputElement>['value'];
  children: ({ checked }: { checked?: boolean }) => ReactNode;
};
