import { useEffect } from 'react';
import * as amplitude from '@amplitude/analytics-browser';

import { zepAnalytics } from '../lib';

export const useAmplitude = (isAllowedWeb: boolean) => {
  useEffect(() => {
    if (!process.env['NEXT_PUBLIC_AMPLITUDE_API_KEY']) return;

    if (isAllowedWeb) {
      amplitude.init(process.env['NEXT_PUBLIC_AMPLITUDE_API_KEY'], undefined, {
        defaultTracking: true,
      });
      zepAnalytics.setDefaultUserProperties();
    }
  }, [isAllowedWeb]);

  return;
};
