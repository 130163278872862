import { useEffect, useId } from 'react';

import { useLatestValue } from './useLatestValue';

let listeners: {
  id: string;
  callback: (evt: KeyboardEvent) => void;
}[] = [];

export const useEscapeOverride = (
  open: boolean | undefined,
  _listener: (evt: KeyboardEvent) => void,
  _id?: string,
) => {
  const id = useId();
  const { current } = useLatestValue(_listener);

  useEffect(() => {
    if (!open) return;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code !== 'Escape') return;

      event.preventDefault();

      listeners[listeners.length - 1].callback(event);
      listeners.pop();
    };

    if (typeof window !== 'undefined') {
      if (!listeners.some(listener => listener.id === id)) {
        listeners.push({ id, callback: current });
      }
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (typeof window !== 'undefined') {
        document.removeEventListener('keydown', handleKeyDown);
        listeners = listeners.filter(listener => listener.id !== id);
      }
    };
  }, [current, id, open]);
};
