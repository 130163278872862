import { ReactNode } from 'react';
import cns from 'classnames';

import S from './RangeSlider.module.scss';

const HANDLE_SIZE = 16;

export type MarkerProps = {
  value: number;
  children?: ReactNode;

  /** RangeSlider에서 주입해주는 props이므로 직접 사용하지 않는 걸 권장합니다. */
  _max?: number;
  _vertical?: boolean;
  _active?: boolean;
};

export const Marker = ({
  value,
  children,
  _max = 100,
  _vertical = false,
  _active = false,
}: MarkerProps) => {
  const handleSize = (HANDLE_SIZE * value) / _max;
  const position = `calc(${(value / _max) * 100}% + ${
    HANDLE_SIZE / 2
  }px - ${handleSize}px)`;

  return (
    <div
      className={S.sliderMarker}
      style={
        _vertical
          ? {
              bottom: position,
              transform: `translateY(-50%)`,
            }
          : {
              left: position,
              transform: `translateX(-50%)`,
            }
      }>
      <div
        className={cns(
          S.sliderMarkerCircle,
          _active && S.sliderMarkerCircleActive,
        )}
      />
      {children}
    </div>
  );
};
