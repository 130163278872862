import {
  ChangeEvent,
  ComponentProps,
  CSSProperties,
  forwardRef,
  SVGProps,
  useState,
} from 'react';
import cns from 'classnames';

import S from './NativeSelect.module.scss';

const CarotDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="m14.167 11.667-4.167-5-4.167 5h8.334Z" fill="currentColor" />
  </svg>
);

export const NativeSelect = forwardRef<HTMLSelectElement, SelectProps>(
  (props, ref) => {
    const {
      variant,
      size = 'md',
      options,
      width,
      disabled,
      isFullWidth,
      onChange,
      ...rest
    } = props;

    const [selected, setSelected] = useState<SelectItem>(options[0]);

    const styles = {
      '--select-width': width,
    } as CSSProperties;

    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
      setSelected(options[e.currentTarget.selectedIndex]);
      onChange && onChange(e);
    };

    return (
      <div
        className={cns(S.select, {
          [S.fullWidth]: isFullWidth,
        })}
        style={styles}>
        <select
          ref={ref}
          {...rest}
          className={S.control}
          onChange={handleChange}>
          {options.map((option, index) => (
            <option
              value={option.value}
              key={index}
              onSelect={() => console.log(option)}
              disabled={option.disabled}>
              {option.name}
            </option>
          ))}
        </select>
        <span
          className={cns(S.button, S[`size_${size}`], {
            [S.danger]: variant === 'danger',
            [S.success]: variant === 'success',
            [S.disabled]: disabled,
          })}>
          <span className={S.text}>{selected?.name}</span>
          <CarotDownIcon className={S.icon} />
        </span>
      </div>
    );
  },
);

type SelectItem = {
  value: string;
  name: string;
  disabled?: boolean;
};

type SelectProps = Omit<ComponentProps<'select'>, 'size'> & {
  options: SelectItem[];
  width?: string;
  variant?: 'danger' | 'success';
  size?: 'xs' | 'sm' | 'md';
  danger?: boolean;
  success?: boolean;
  isFullWidth?: boolean;
  onChange?: () => void;
};
