import { useContext } from 'react';

import { DialogContext } from './DialogContext';

/**
 *
 * ❌ 감싸면 무한 실행 버그 걸립니다.
 * useEffect(() => {}, [dialog])
 *
 * ⭕ 이런식으로 부탁드립니다.
 * const alert = usePreservedCallback(() => {
 *     dialog.alert({
 *       title: '1',
 *     });
 * });
 *
 * useEffect() => { alert(), [alert]}
 * */
export const useDialog = () => {
  const dialogContext = useContext(DialogContext);
  if (!dialogContext) {
    throw new Error('Could not find DialogProvider.');
  }
  return dialogContext;
};
