import PQueue from 'p-queue';

export class StatefulPQueue extends PQueue {
  private ready = false;

  get isReady() {
    return this.ready;
  }

  setReady(ready: boolean): StatefulPQueue {
    this.ready = ready;
    return this;
  }
}
