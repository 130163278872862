'use client';

import { ComponentPropsWithoutRef, useCallback } from 'react';
import * as React from 'react';
import * as ContextMenuPrimitive from '@radix-ui/react-context-menu';
import { Slot } from '@radix-ui/react-slot';
import { VariantProps } from 'class-variance-authority';
import { ChevronRight } from 'lucide-react';

import { CheckSmallIcon } from '@zep/icons';
import { cn } from '@zep/utils';

import {
  MENU_CHECKBOX_ITEM,
  MENU_CONTENT,
  MENU_ICON_WRAPPER,
  MENU_ITEM_LABEL,
  MENU_ITEMINDICATOR_WRAPPER,
  MENU_LABEL,
  MENU_RADIO_ITEM,
  MENU_SEPARATOR,
  MENU_SHORTCUT,
  MENU_SUBCONTENT,
  MENU_SUBTRIGGER,
  menuItemVariants,
} from '../DropdownMenu/constants';

const ContextMenu = ContextMenuPrimitive.Root;

const ContextMenuTrigger = ContextMenuPrimitive.Trigger;

/**
 * @description ContextMenu(우클릭, 모바일 롱프레스)지만 클릭으로 트리거 되어야 하는경우 사용합니다. ContextMenuTrigger 안 에서만 사용가능합니다.
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<React.ComponentPropsWithoutRef<"div"> & {asChild?: boolean}> & React.RefAttributes<HTMLDivElement>>}
 */
const ContextMenuClickTrigger = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'> & { asChild?: boolean }
>(({ className: _, asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : 'div';

  const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    const rightClickEvent = new MouseEvent('contextmenu', {
      bubbles: true,
      cancelable: true,
      view: window,
      button: 2,
      screenX: event.screenX,
      screenY: event.screenY,
      clientX: event.clientX,
      clientY: event.clientY,
    });
    event.target.dispatchEvent(rightClickEvent);
  }, []);

  return <Comp className={cn('')} ref={ref} onClick={handleClick} {...props} />;
});
ContextMenuClickTrigger.displayName = 'ContextMenuClickTrigger';

const ContextMenuGroup = ContextMenuPrimitive.Group;

const ContextMenuPortal = ContextMenuPrimitive.Portal;

const ContextMenuSub = ContextMenuPrimitive.Sub;

const ContextMenuRadioGroup = ContextMenuPrimitive.RadioGroup;

const ContextMenuSubTrigger = React.forwardRef<
  React.ElementRef<typeof ContextMenuPrimitive.SubTrigger>,
  React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.SubTrigger> & {
    inset?: boolean;
  }
>(({ className, inset, children, ...props }, ref) => (
  <ContextMenuPrimitive.SubTrigger
    ref={ref}
    className={cn(MENU_SUBTRIGGER, inset && 'pl-8', className)}
    {...props}>
    {children}
    <ChevronRight className="ml-auto size-4" />
  </ContextMenuPrimitive.SubTrigger>
));
ContextMenuSubTrigger.displayName = ContextMenuPrimitive.SubTrigger.displayName;

const ContextMenuSubContent = React.forwardRef<
  React.ElementRef<typeof ContextMenuPrimitive.SubContent>,
  React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.SubContent>
>(({ className, ...props }, ref) => (
  <ContextMenuPrimitive.SubContent
    ref={ref}
    className={cn(MENU_SUBCONTENT, className)}
    {...props}
  />
));
ContextMenuSubContent.displayName = ContextMenuPrimitive.SubContent.displayName;

const ContextMenuContent = React.forwardRef<
  React.ElementRef<typeof ContextMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.Content>
>(({ className, ...props }, ref) => (
  <ContextMenuPrimitive.Portal>
    <ContextMenuPrimitive.Content
      ref={ref}
      className={cn(MENU_CONTENT, className)}
      {...props}
    />
  </ContextMenuPrimitive.Portal>
));
ContextMenuContent.displayName = ContextMenuPrimitive.Content.displayName;

const ContextMenuItem = React.forwardRef<
  React.ElementRef<typeof ContextMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.Item> &
    VariantProps<typeof menuItemVariants> & {
      inset?: boolean;
    }
>(({ className, color = 'normal', inset, ...props }, ref) => (
  <ContextMenuPrimitive.Item
    ref={ref}
    className={cn(menuItemVariants({ color }), inset && 'pl-8', className)}
    {...props}
  />
));
ContextMenuItem.displayName = ContextMenuPrimitive.Item.displayName;

type ContextMenuItemLabelProps = ComponentPropsWithoutRef<'span'>;
const ContextMenuItemLabel = (props: ContextMenuItemLabelProps) => {
  const { className, children, ...rest } = props;
  return (
    <span className={cn(MENU_ITEM_LABEL, className)} {...rest}>
      {children}
    </span>
  );
};

const ContextMenuCheckboxItem = React.forwardRef<
  React.ElementRef<typeof ContextMenuPrimitive.CheckboxItem>,
  React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.CheckboxItem>
>(({ className, children, checked, ...props }, ref) => {
  return (
    <ContextMenuPrimitive.CheckboxItem
      ref={ref}
      className={cn(MENU_CHECKBOX_ITEM, className)}
      checked={checked}
      {...props}>
      <div className="flex flex-1 truncate">{children}</div>
      <ContextMenuPrimitive.ItemIndicator>
        <span className={cn(MENU_ITEMINDICATOR_WRAPPER)}>
          <CheckSmallIcon className="size-[18px] fill-current" />
        </span>
      </ContextMenuPrimitive.ItemIndicator>
    </ContextMenuPrimitive.CheckboxItem>
  );
});
ContextMenuCheckboxItem.displayName =
  ContextMenuPrimitive.CheckboxItem.displayName;

const ContextMenuRadioItem = React.forwardRef<
  React.ElementRef<typeof ContextMenuPrimitive.RadioItem>,
  React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.RadioItem>
>(({ className, children, ...props }, ref) => (
  <ContextMenuPrimitive.RadioItem
    ref={ref}
    className={cn(MENU_RADIO_ITEM, className)}
    {...props}>
    <div className="flex flex-1 truncate">{children}</div>
    <ContextMenuPrimitive.ItemIndicator>
      <span className={cn(MENU_ITEMINDICATOR_WRAPPER)}>
        <CheckSmallIcon className="size-[18px] fill-current" />
      </span>
    </ContextMenuPrimitive.ItemIndicator>
  </ContextMenuPrimitive.RadioItem>
));
ContextMenuRadioItem.displayName = ContextMenuPrimitive.RadioItem.displayName;

const ContextMenuLabel = React.forwardRef<
  React.ElementRef<typeof ContextMenuPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.Label> & {
    inset?: boolean;
  }
>(({ className, inset, ...props }, ref) => (
  <ContextMenuPrimitive.Label
    ref={ref}
    className={cn(MENU_LABEL, inset && 'pl-8', className)}
    {...props}
  />
));
ContextMenuLabel.displayName = ContextMenuPrimitive.Label.displayName;

const ContextMenuSeparator = React.forwardRef<
  React.ElementRef<typeof ContextMenuPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <ContextMenuPrimitive.Separator
    ref={ref}
    className={cn(MENU_SEPARATOR, className)}
    {...props}
  />
));
ContextMenuSeparator.displayName = ContextMenuPrimitive.Separator.displayName;

const ContextMenuShortcut = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
  return <span className={cn(MENU_SHORTCUT, className)} {...props} />;
};
ContextMenuShortcut.displayName = 'ContextMenuShortcut';

const ContextMenuIconWrapper = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'> & { asChild?: boolean }
>(({ className, asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : 'div';

  return (
    <Comp className={cn(MENU_ICON_WRAPPER, className)} ref={ref} {...props} />
  );
});
ContextMenuIconWrapper.displayName = 'ContextMenuIconWrapper';

export {
  ContextMenu,
  ContextMenuTrigger,
  ContextMenuClickTrigger,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuItemLabel,
  ContextMenuCheckboxItem,
  ContextMenuRadioItem,
  ContextMenuLabel,
  ContextMenuSeparator,
  ContextMenuShortcut,
  ContextMenuGroup,
  ContextMenuPortal,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubTrigger,
  ContextMenuRadioGroup,
  ContextMenuIconWrapper,
};
