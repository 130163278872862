import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ZepApiError } from '../zep-api';

export interface BaseSuspendedUseQueryResult<TData>
  extends Omit<
    UseQueryResult<TData, never>,
    'data' | 'status' | 'error' | 'isLoading' | 'isError' | 'isFetching'
  > {
  data: TData;
  status: 'success' | 'idle';
}

export type SuspendedUseQueryResultOnSuccess<TData> =
  BaseSuspendedUseQueryResult<TData> & {
    status: 'success';
    isSuccess: true;
    isIdle: false;
  };
export type SuspendedUseQueryResultOnIdle<TData> =
  BaseSuspendedUseQueryResult<TData> & {
    status: 'idle';
    isSuccess: false;
    isIdle: true;
  };

export type SuspendedUseQueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'suspense'>;
export function useSuspendedQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<
    SuspendedUseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'enabled'
  >,
): SuspendedUseQueryResultOnSuccess<TData>;
export function useSuspendedQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options: Omit<
    SuspendedUseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'enabled'
  > & {
    enabled?: true;
  },
): SuspendedUseQueryResultOnSuccess<TData>;
export function useSuspendedQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options: Omit<
    SuspendedUseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'enabled'
  > & {
    enabled: false;
  },
): SuspendedUseQueryResultOnIdle<undefined>;
export function useSuspendedQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options: SuspendedUseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
):
  | SuspendedUseQueryResultOnSuccess<TData>
  | SuspendedUseQueryResultOnIdle<undefined>;
export function useSuspendedQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: SuspendedUseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
) {
  return useQuery(queryKey, queryFn, {
    ...options,
    suspense: true,
  }) as unknown as BaseSuspendedUseQueryResult<TData>;
}

export type SuspenseQueryOption<T> = Partial<
  Parameters<typeof useSuspendedQuery<T, AxiosError | ZepApiError>>[2]
>;
