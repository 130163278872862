import isMobile from 'ismobilejs';

import { isServer } from './isServer';

export const isTablet = () => {
  if (isServer()) {
    return false;
  }
  return (
    isMobile(navigator.userAgent).tablet ||
    (navigator.userAgent.includes('Macintosh') && 'ontouchend' in document)
  );
};
