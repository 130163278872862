import { ComponentType, Suspense, SuspenseProps, useEffect } from 'react';
import { useIsMounted } from '@toss/react';

let isMountedBefore = false;

/**
 * Page router 에서 Suspense 사용 시 발생하는 Hydration 관련 문제를 방지할 수 있는 Suspense Wrapper 컴포넌트
 *
 * SSR 또는 SSG 에서는 무조건 fallback 이 렌더링되고, 클라이언트 사이드에서는 Suspense 와 동일하게 작동한다.
 */
export function SSRSafeSuspense(props: SuspenseProps) {
  const isMounted = useIsMounted();

  useEffect(() => {
    if (isMounted) {
      isMountedBefore = true;
    }
  }, [isMounted]);

  if (isMounted || isMountedBefore) {
    return <Suspense {...props} />;
  }
  return <>{props.fallback}</>;
}

/**
 * SSRSafeSuspense 컴포넌트의 HOC 형태
 */
export function withSSRSafeSuspense<P extends JSX.IntrinsicAttributes>(
  Component: ComponentType<P>,
  suspenseProps?: SuspenseProps,
) {
  return function WithSSRSafeSuspense(props: P) {
    return (
      <SSRSafeSuspense {...suspenseProps}>
        <Component {...props} />
      </SSRSafeSuspense>
    );
  };
}
