// Do not edit directly
// Generated on Wed, 12 Jul 2023 08:46:20 GMT

export const Black = '#000000';
export const White = '#FFFFFF';
export const Gray50 = '#F8F9FC';
export const Gray100 = '#F3F5F9';
export const Gray200 = '#E9EAF0';
export const Gray300 = '#D5D9E0';
export const Gray400 = '#BEC3CC';
export const Gray500 = '#8C9097';
export const Gray600 = '#5E6066';
export const Gray700 = '#27262E';
export const GrayDefault = '#5E6066';
export const GrayDefaultHover = '#4B4D52';
export const PrimaryBackground = '#F3F2FF';
export const PrimaryBackgroundHover = '#E9E8FF';
export const PrimaryDisabled = '#E9E8FF';
export const PrimaryDefault = '#6758FF';
export const PrimaryDefaultHover = '#5246CC';
export const PrimaryGradient = '#994AFF';
export const MintLight = '#74FADA';
export const MintDefault = '#15E4BF';
export const MintDark = '#05B8A2';
export const MintDarkHover = '#049382';
export const MintBackground = '#E5FFF9';
export const RedBackground = '#FFF2F7';
export const RedBackgroundHover = '#FFE8F1';
export const RedDefault = '#FF5353';
export const RedDefaultHover = '#EF4141';
export const YellowLight = '#FFEB3A';
export const YellowDefault = '#FFD600';
export const GreenLight = '#60FF70';
export const GreenDefault = '#15E42A';
export const BlueLight = '#61BDFF';
export const BlueDefault = '#0D94FF';
export const PurpleDefault = '#924AFF';
