import { useTranslation } from 'react-i18next';
import { withAsyncBoundary } from '@toss/async-boundary';

import { useViewportDevice } from '@zep/react';
import { Button } from '@zep/ui';

import S from './AceeptCookiePannel.module.scss';

const _AcceptCookiePanel = ({ onClickButton }: AcceptCookiePanelProps) => {
  const { t } = useTranslation(['common'], {
    useSuspense: true, // 깜박임 제거하기 위해
  });

  const { isTablet } = useViewportDevice();
  const size = isTablet ? 'sm' : 'md';

  const handleAccept = () => {
    onClickButton(true);
  };

  return (
    <div className={S.wrapper}>
      <div className={S.container}>
        <div
          className={S.text}
          dangerouslySetInnerHTML={{
            __html: t('common:acceptCookie.panel.text'),
          }}
        />

        <div className={S.buttons}>
          <Button size={size} isFullWidth onClick={handleAccept}>
            {t('common:acceptCookie.panel.accept')}
          </Button>
        </div>
      </div>
    </div>
  );
};
export const AcceptCookiePanel = withAsyncBoundary(_AcceptCookiePanel, {
  pendingFallback: '',
  rejectedFallback: () => <></>,
});

type AcceptCookiePanelProps = {
  onClickButton: (isAccepted: boolean) => void;
};
