import { PropsWithChildren, useEffect } from 'react';
import { useBooleanState } from '@toss/react';

import { isApp, isEuropeByTimeZone } from '@zep/utils';

import { AcceptCookiePanel } from './AcceptCookiePanel';
import {
  getAcceptCookie,
  removeUnnecessaryCookie,
  setAcceptCookie,
} from './aceeptCookieUtil';

export const AcceptCookieWrapper = (props: AcceptCookieWrapperProps) => {
  const {
    updateVisibilityCookiePanel,
    onAcceptedCookie,
    enabledCookiePanel = true,
  } = props;

  const isInEuropeanUnion = isEuropeByTimeZone();
  const acceptCookieValue = getAcceptCookie();

  const [isShowFixedBar, showFixedBar, hideFixedBar] = useBooleanState(false);
  const handleAction = (isAccepted: boolean) => {
    hideFixedBar();
    // 현재 !isAccepted 버튼은 없다. 취소버튼은 다음에 넣을계획입니다.

    if (isAccepted) {
      setAcceptCookie('all');
      onAcceptedCookie();
      return;
    } else {
      removeUnnecessaryCookie();
      setAcceptCookie('necessary');
    }
  };

  useEffect(() => {
    const _isApp = isApp();
    if (acceptCookieValue === 'all' || _isApp) {
      hideFixedBar();
      onAcceptedCookie();
      return;
    }

    if (acceptCookieValue === 'necessary') {
      hideFixedBar();
      removeUnnecessaryCookie();
      return;
    }

    if (acceptCookieValue === 'unknown' && isInEuropeanUnion) {
      showFixedBar();
      return;
    }

    hideFixedBar();
    onAcceptedCookie();
  }, [
    onAcceptedCookie,
    hideFixedBar,
    showFixedBar,
    acceptCookieValue,
    isInEuropeanUnion,
  ]);

  useEffect(() => {
    updateVisibilityCookiePanel && updateVisibilityCookiePanel(isShowFixedBar);
  }, [isShowFixedBar, updateVisibilityCookiePanel]);

  return (
    <>
      {props.children}
      {isShowFixedBar && enabledCookiePanel && (
        <AcceptCookiePanel onClickButton={handleAction} />
      )}
    </>
  );
};

type AcceptCookieWrapperProps = PropsWithChildren<{
  /** 쿠키 패널 */
  enabledCookiePanel?: boolean;
  /** 모든 쿠키 허용 */
  onAcceptedCookie: () => void;
  /**필수 쿠키만 허용 */
  rejectCookieServices?: () => void;
  /** 쿠키패널이 변경되는 callback 함수  */
  updateVisibilityCookiePanel?: (isShow: boolean) => void;
}>;
