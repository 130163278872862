import { ComponentProps, CSSProperties } from 'react';
import { SVGProps } from 'react';
import cns from 'classnames';

import { Colors } from '../../../dist/index';
import { Avatar } from '../Avatar';

import S from './Profile.module.scss';

const Persen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={28}
    fill="none"
    viewBox="0 0 26 28"
    {...props}>
    <path
      fill="#D5D9E0"
      d="M0 26c0-5.523 4.85-10 10.833-10h4.334C21.15 16 26 20.477 26 26s-4.85 10-10.833 10h-4.334C4.85 36 0 31.523 0 26Z"
    />
    <path fill="#D5D9E0" d="M21 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z" />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.5 10a9.5 9.5 0 1 1-19 0 9.5 9.5 0 0 1 19 0ZM13 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z"
      clipRule="evenodd"
    />
  </svg>
);

/**
 * @param backgroundColor 색상 hex 값
 */
export const Profile = (props: ProfileProps) => {
  const {
    src,
    size = 'xl',
    skin = 1,
    clothes = 1,
    face = 1,
    hair = 1,
    status,
    backgroundColor = Colors.Gray100,
    empty = false,
    className,
  } = props;
  const avatarProps = {
    skin,
    clothes,
    face,
    hair,
  };

  if (empty) {
    return (
      <div className={cns(S.profile, S[`size_${size}`], className)}>
        <div
          className={cns(S.avatar, S.empty)}
          style={
            { '--profile-background-color': backgroundColor } as CSSProperties
          }>
          <div className={S.person}>
            <Persen color={backgroundColor} />
          </div>
        </div>
      </div>
    );
  }

  if (src) {
    return (
      <div className={cns(S.profile, S[`size_${size}`], className)}>
        <div className={S.avatar}>
          <img src={src} alt="User Profile" loading="lazy" />
        </div>
      </div>
    );
  }

  return (
    <div className={cns(S.profile, S[`size_${size}`], className)}>
      <div
        className={S.avatar}
        style={
          { '--profile-background-color': backgroundColor } as CSSProperties
        }>
        <Avatar {...avatarProps} className={S.component} />
      </div>
      {status && <span className={S.badge}></span>}
    </div>
  );
};

export type ProfileProps = ComponentProps<typeof Avatar> & {
  src?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  status?: 'online' | 'offline' | 'away' | 'busy';
  empty?: boolean;
  backgroundColor?: string;
};
