import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { pageview } from '../lib';

export const NaverAnalytics = () => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Script src="//wcs.naver.net/wcslog.js" strategy="afterInteractive" />
      <Script id="naver-analytics" strategy="afterInteractive">
        {`
           if(!wcs_add) var wcs_add = {};
           wcs_add["wa"] = "s_2488ee246056";
           if (!_nasa) var _nasa={};
           if (window.wcs) {
           wcs.inflow("zep.us");
           wcs_do(_nasa);
           }
        `}
      </Script>
    </>
  );
};
