import { ZepApiStatusType } from './types/ZepApiStatusType';

/**
 * ZEP API 서버에 요청 후 반환된 Error response 에 대한 Error Class
 */
export class ZepApiError extends Error {
  constructor(
    readonly status: ZepApiStatusType | number,
    readonly title: string,
    readonly traceId: string,
    readonly message: string,
    readonly statusKey?: string,
    /** redirectUri 등, 다양한 케이스에 존재함 */
    readonly bindingData?: Record<string, any>,
  ) {
    const customMessage = `
    API Error Response from server
    status: ${status}
    title: ${title}
    message: ${message}
    traceId: ${traceId}
    reason: ${bindingData?.reason}
    `;
    super(customMessage);
  }

  static createErrorFromResponse(response: any = {}) {
    const { status = -9999, title = 'Unknown Error', traceId = '' } = response;
    return new ZepApiError(
      status,
      title,
      traceId,
      response?.data?.message ?? '',
      response.statusKey ?? '',
      matchErrorData(response),
    );
  }
}
const matchErrorData = (response: Record<string, object>): object => {
  if (response.bindingData) {
    return response.bindingData;
  }
  if (response.data) {
    return response.data;
  }
  if (response.errorData) {
    return response.errorData;
  }
  return {};
};

export const isZepApiError = (error: unknown): error is ZepApiError => {
  console.log('error instanceof ZepApiError:', error instanceof ZepApiError);
  return error instanceof ZepApiError;
};
