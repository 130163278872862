import { ReactNode, useEffect } from 'react';
import {
  autoUpdate,
  flip,
  FloatingOverlay,
  Placement,
  useFloating,
} from '@floating-ui/react';
import { Menu as HeadlessMenu, Portal } from '@headlessui/react';
import cns from 'classnames';

import S from './Menu.module.scss';

export const Menu = (props: MenuProps) => {
  const {
    open,
    placement = 'left',
    menuListPlacement = 'bottom-end',
    useFloatingOverlay = true,
    button,
    className,
    children,
  } = props;

  const { x, y, strategy, refs, elements } = useFloating({
    placement: menuListPlacement,
    middleware: [flip({ altBoundary: true })],
    whileElementsMounted: autoUpdate,
  });

  useEffect(() => {
    if (!open) return;
    // (elements.domReference as HTMLButtonElement).click();
  }, [elements, open]);

  return (
    <HeadlessMenu
      as="div"
      className={cns(S.menu, className)}
      data-headlessui-menu
      data-headlessui-placement={placement}>
      {({ open: isOpen, close }) => (
        <>
          <HeadlessMenu.Button as="div" ref={refs.setReference}>
            {button}
          </HeadlessMenu.Button>
          {isOpen && (
            <Portal>
              {useFloatingOverlay && (
                <FloatingOverlay data-floating-overlay style={{ zIndex: 2 }} />
              )}
              <div
                ref={refs.setFloating}
                style={{
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                  zIndex: 2,
                }}
                onClick={close}>
                {children}
              </div>
            </Portal>
          )}
        </>
      )}
    </HeadlessMenu>
  );
};

type MenuProps = {
  placement?: 'left' | 'right';
  open?: boolean;
  useFloatingOverlay?: boolean;
  menuListPlacement?: Placement;
  button: ReactNode;
  children: ReactNode;
  className?: string;
};
