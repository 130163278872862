import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { pageview } from '../lib';

export const GoogleAnalytics = (props: GoogleAnalyticsProps) => {
  const router = useRouter();
  const { id } = props;

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
        strategy="afterInteractive"
      />
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ADWORDS}`}
        strategy="afterInteractive"
      />
      {id && (
        <Script
          src={`https://www.googletagmanager.com/gtag/js?id=${id}`}
          strategy="afterInteractive"
        />
      )}
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
            page_path: window.location.pathname,
            debug_mode: true
          });
        `}
        {process.env.NEXT_PUBLIC_STAGE === 'PRD' &&
          `
          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ADWORDS}');
          `}
        {id &&
          `
          gtag('config', '${id}', {
            page_path: window.location.pathname,
            debug_mode: true
          });
        `}
      </Script>
    </>
  );
};

type GoogleAnalyticsProps = {
  id?: string;
};
