import { isServer } from './isServer';

export const isWhaleBrowser = (userAgent?: string) => {
  if (isServer()) {
    return false;
  }
  return (
    (userAgent || window.navigator.userAgent).toLowerCase().indexOf('whale') >
    -1
  );
};
