import { CSSProperties, forwardRef, ReactNode, useRef, useState } from 'react';
import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  FloatingPortal,
  offset,
  Placement,
  shift,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';

import { cn } from '@zep/utils';

import { Colors } from '../../../dist/index';

import S from './Tooltip.module.scss';

export const TooltipContent = forwardRef<HTMLDivElement, TooltipContentProps>(
  (props, ref) => (
    <div ref={ref} {...props} className={cn(S.tooltip, props.className)} />
  ),
);

export const Tooltip = (props: TooltipProps) => {
  const {
    placement = 'top-start',
    open,
    title,
    className,
    tooltipClassName,
    arrow: showArrow = false,
    flip: flipEnabled = true,
    children,
  } = props;
  const [isOpenDefault, setIsOpenDefault] = useState(open);
  const isOpen = open ?? isOpenDefault;
  const arrowRef = useRef(null);
  const { refs, context, x, y, strategy } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpenDefault,
    placement,
    middleware: [
      flipEnabled && flip({ altBoundary: true }),
      shift(),
      offset(2),
      showArrow &&
        arrow({
          element: arrowRef,
        }),
      showArrow && offset(6),
    ],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, { move: false, mouseOnly: true });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    dismiss,
    role,
  ]);

  return (
    <>
      <div
        ref={refs.setReference}
        {...getReferenceProps()}
        className={cn(S.trigger, className)}>
        {children}
      </div>
      {isOpen && (
        <FloatingPortal>
          <TooltipContent
            ref={refs.setFloating}
            {...getFloatingProps()}
            className={cn('break-all', tooltipClassName)}
            style={{
              position: strategy,
              left: x ?? 0,
              top: y ?? 0,
            }}>
            {title}
            {showArrow && (
              <FloatingArrow
                ref={arrowRef}
                context={context}
                width={10}
                height={6}
                fill={Colors.Gray700}
                fillOpacity={0.8}
              />
            )}
          </TooltipContent>
        </FloatingPortal>
      )}
    </>
  );
};

type TooltipContentProps = {
  style?: CSSProperties;
  className?: string;
  children?: ReactNode;
};

export type TooltipProps = {
  placement?: Placement;
  title?: ReactNode;
  open?: boolean;
  className?: string;
  tooltipClassName?: string;
  maxWidth?: number;
  arrow?: boolean;
  flip?: boolean;
  children?: ReactNode;
};
