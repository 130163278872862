import { z } from 'zod';

import { SpaceUsageType } from '@zep/types';
import { zFile } from '@zep/utils';

export const SpaceSchemaSpec = {
  name: { maxLength: 30 },
  desc: { maxLength: 80 },
  password: { maxLength: 32, minLength: 1 },
  customUrl: { maxLength: 30, regex: /^[A-Za-z0-9-.]*[A-Za-z0-9-]?$/ },
} as const;

export const thumbnailSchema = zFile({
  allowedImageExt: {
    value: ['png', 'jpg', 'jpeg'],
    message: 'notAccepted',
  },
  fileSize: {
    value: 1024 * 1024, // 1mb
    message: 'limitedSize',
  },
});

export const coverImageSchema = zFile({
  allowedImageExt: {
    value: ['png', 'jpg', 'jpeg'],
    message: 'notAccepted',
  },
  fileSize: {
    value: 1024 * 1024, // 1mb
    message: 'limitedSize',
  },
});
export const PremiumLogoImageSchema = zFile({
  allowedImageExt: {
    value: ['png'],
    message: 'notAccepted',
  },
  fileSize: {
    value: 1024 * 1024 * 1, // 1mb
    message: 'limitedSize',
  },
});

export const PremiumLoadingSpinnerSchema = zFile({
  allowedImageExt: {
    value: ['png'],
    message: 'notAccepted',
  },
  fileSize: {
    value: 1024 * 1024 * 5, // 5mb
    message: 'limitedSize',
  },
});

export const SpaceSchema = z.object({
  name: z
    .string()
    .min(1, {
      message: 'play:settingsModal.settingSpaceOptions.minimumTextLength',
    })
    .max(SpaceSchemaSpec.name.maxLength)
    .trim(),
  description: z.string().optional(),
  thumbnailPath: z.string().optional(),
  thumbnailUrl: z.string().optional(),
  coverPath: z.string().optional(),
  coverUrl: z.string().optional(),
  enableFriendsInvitation: z.boolean(),
  enableFixedSidebar: z.boolean(),
  enableStartAtEntryMap: z.boolean(),
  entryMapHashId: z.string(),
  enableFilterBadWords: z.boolean(),
  blockedKeywords: z
    .string()
    .trim()
    .refine(
      validateBlockedKeywords,
      'play:settingsModal.settingSpaceOptions.blockKeywordsErrorText',
    )
    .transform(value => {
      const keywords = value.split(',');
      return keywords
        .filter(keyword => keyword.trim() !== '' && !/^\d+$/.test(keyword))
        .join(',');
    }),
  enablePassword: z.boolean(),
  password: z.string().trim().max(SpaceSchemaSpec.password.maxLength),
  enableWhitelisting: z.boolean(),
  enableDomainRestriction: z.boolean(),
  allowedDomains: z.string().min(1),
  enableGuestRestriction: z.boolean(),
  spaceUsageType: z.nativeEnum(SpaceUsageType),
  allowSearch: z.boolean(),
  enableDonation: z.boolean(),
  donationFee: z.number().min(0).max(100),
  enableCryptoWallet: z.boolean(),
  logoPath: z.string().optional(),
  logoUri: z.string().optional(),
  loadingColor: z.string().optional(),
  loadingSpinnerPath: z.string().optional(),
  loadingSpinnerUri: z.string().optional(),
  customUrl: z
    .string()
    .max(SpaceSchemaSpec.customUrl.maxLength)
    .regex(SpaceSchemaSpec.customUrl.regex)
    .trim()
    .optional(),
  gaId: z.string().trim().optional(),
  enableTutorial: z.boolean(),
  enableAdditionalInformationRequired: z.boolean(), // 부가 정보 입력 받기
  additionalInformationTitle: z.string().optional(), // 부가 정보
  enableGetAllUsersNickname: z.boolean(), // 모든 사용자 닉네임 입력 받기
  rtcHostUrl: z.string().optional(),
  disableFlyMode: z.boolean(), // flyMode 제한 여부
  enableLogflare: z.boolean(), // 로깅 시스템 사용 여부
  enableBlockedKeywords: z.boolean(),
  enableGa: z.boolean(),
});

export const SpaceSettingSchema = SpaceSchema.omit({
  spaceUsageType: true,
});
export const CreateSpaceSchema = SpaceSchema.pick({
  name: true,
  enablePassword: true,
  password: true,
  spaceUsageType: true,
});

export type SpaceSettingSchemaType = z.infer<typeof SpaceSettingSchema>;

export type CreateSpaceSchemaType = z.infer<typeof CreateSpaceSchema>;

const digitRegex = /^\d+$/;
const spaceRegex = /\s/;
const onlySpaceRegex = /^[\s]*$/;

export function validateBlockedKeywords(value: string) {
  const keywords = value.split(',');
  for (let keyword of keywords) {
    keyword = keyword.trim();
    if (
      keyword === ' ' ||
      digitRegex.test(keyword) ||
      spaceRegex.test(keyword) ||
      onlySpaceRegex.test(keyword)
    ) {
      return false;
    }
  }
  return true;
}
