import { cva } from 'class-variance-authority';

import { cn } from '@zep/utils';

export const MENU_CONTENT = cn(
  'z-menu min-w-[8rem] overflow-hidden rounded-[8px] bg-white py-[8px] text-popover-foreground animate-in fade-in-80',
  'shadow-[0px_4px_8px_3px_rgba(42,39,65,0.10)]',
  'data-[state=open]:animate-in data-[state=open]:fade-in-0 data-[state=open]:zoom-in-95',
  'data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95',
  'data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
);

export const menuItemVariants = cva(
  [
    'relative flex h-[40px] cursor-default select-none items-center gap-[6px] rounded-sm px-[20px] py-[4px]',
    'text-body-1 font-medium text-gray-700 outline-none',
    'data-[disabled]:pointer-events-none data-[disabled]:text-gray-400',
    'group truncate',
  ],
  {
    variants: {
      color: {
        normal: ['hover:bg-gray-50 focus:bg-gray-50', 'active:bg-gray-50'],
        danger: [
          'text-red',
          'hover:bg-gray-50 focus:bg-gray-50',
          'active:bg-redBg',
        ],
      },
    },
    defaultVariants: {
      color: 'normal',
    },
  },
);

export const MENU_CHECKBOX_ITEM = cn(
  'relative flex cursor-default select-none items-center rounded-sm px-[20px] py-[4px] gap-[6px] h-[40px]',
  'text-sm outline-none transition-colors',
  'focus:bg-accent focus:text-accent-foreground',
  'data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
  'data-[state=checked]:bg-primaryBg data-[state=checked]:text-primary data-[state=checked]:font-semibold',
  'active:bg-primaryBg active:text-primary active:font-semibold',
);

export const MENU_RADIO_ITEM = cn(
  'relative flex cursor-default select-none items-center rounded-sm px-[20px] py-[4px] gap-[6px] h-[40px]',
  'text-sm outline-none transition-colors',
  'focus:bg-accent focus:text-accent-foreground',
  'data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
  'data-[state=checked]:bg-primaryBg data-[state=checked]:text-primary data-[state=checked]:font-semibold',
  'active:bg-primaryBg active:text-primary active:font-semibold',
);

export const MENU_ITEM_LABEL = cn('truncate flex-1');

export const MENU_LABEL = cn(
  'px-[16px] pt-[10px] pb-[8px] text-gray-500 text-caption-1 font-semibold truncate',
);

export const MENU_SEPARATOR = cn('-mx-1 my-1 h-px bg-gray-200');

export const MENU_SHORTCUT = cn('ml-auto text-xs tracking-widest opacity-60');

export const MENU_SUBTRIGGER = cn(
  'flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-accent data-[state=open]:bg-accent',
);
export const MENU_SUBCONTENT = cn(
  'z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover p-[8px] text-popover-foreground shadow-md',
  'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
);

export const MENU_ITEMINDICATOR_WRAPPER = cn(
  'ml-auto flex size-[18px] items-center justify-center',
);

export const MENU_ICON_WRAPPER = cn('size-[18px] opacity-70 shrink-0');
