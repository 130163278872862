import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { addYears } from 'date-fns';

const AcceptCookieKey = 'AcceptCookie';

const AcceptCookies = [
  '__cuid',
  'AcceptCookie',
  '.AspNetCore.Session',
  'iron-session-zep',
  'banSpaceList',
  'blockAccount',
  'DV',
  'HSID',
  'SID',
  'G_ENABLED_IDPS',
];
const getAllCookieNames = () => {
  try {
    const cookies = document.cookie.split(';');
    return cookies.map(cookie => cookie.split('=')[0]);
  } catch (e) {
    console.log(e);
    return [];
  }
};

type AcceptCookieValue = 'all' | 'necessary' | 'unknown';
export const getAcceptCookie = (): AcceptCookieValue => {
  try {
    return (['all', 'necessary'].find(
      key => key === String(getCookie(AcceptCookieKey)),
    ) || 'unknown') as AcceptCookieValue;
  } catch (e) {
    return 'unknown';
  }
};

export const setAcceptCookie = (_data: AcceptCookieValue) => {
  setCookie(AcceptCookieKey, _data, {
    expires: addYears(new Date(), 1),
  });
};

export const removeUnnecessaryCookie = () => {
  getAllCookieNames()
    .filter(name => !AcceptCookies.includes(name))
    .forEach(removeCookie => deleteCookie(removeCookie));
};
