import React from 'react';
import { useTranslation } from 'next-i18next';

import { Button } from '@zep/ui';

import { ErrorWidget } from '../ErrorWidget';

export const Error500Widget = () => {
  const { t, ready } = useTranslation(['common']);

  const imageUrl = '/images/error_500.png';
  const title = t('common:unknownError.title');
  const description = t('common:unknownError.content');

  if (!ready) return null;

  return (
    <ErrorWidget.Root>
      <ErrorWidget.Header>
        <ErrorWidget.Image src={imageUrl} />
      </ErrorWidget.Header>
      <ErrorWidget.Content>
        <ErrorWidget.Title>{title}</ErrorWidget.Title>
        <ErrorWidget.Description>{description}</ErrorWidget.Description>
      </ErrorWidget.Content>
      <ErrorWidget.Footer>
        <Button
          size="lg"
          as="a"
          href={t('common:unknownError.buttonHelp.link') ?? ''}
          variant="secondary">
          {t('common:unknownError.buttonHelp.title')}
        </Button>
        <Button size="lg" as="a" href="/">
          {t('common:unknownError.buttonHome')}
        </Button>
      </ErrorWidget.Footer>
    </ErrorWidget.Root>
  );
};
