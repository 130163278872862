'use client';

import { ComponentPropsWithoutRef } from 'react';
import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { Separated } from '@toss/react';
import { VariantProps } from 'class-variance-authority';
import { Drawer as DrawerPrimitive } from 'vaul';

import { cn } from '@zep/utils';

import {
  MENU_ICON_WRAPPER,
  MENU_ITEM_LABEL,
  MENU_LABEL,
  menuItemVariants,
} from '../DropdownMenu/constants';

const ActionSheet = ({
  shouldScaleBackground = true,
  ...props
}: React.ComponentProps<typeof DrawerPrimitive.Root>) => (
  <DrawerPrimitive.Root
    shouldScaleBackground={shouldScaleBackground}
    {...props}
  />
);
ActionSheet.displayName = 'ActionSheet';

const ActionSheetTrigger = DrawerPrimitive.Trigger;

const ActionSheetPortal = DrawerPrimitive.Portal;

const ActionSheetClose = DrawerPrimitive.Close;

const ActionSheetOverlay = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(({ className, ...props }, ref) => {
  return (
    <DrawerPrimitive.Overlay
      ref={ref}
      className={cn('fixed inset-0 z-40 bg-[#0000003D]', className)}
      {...props}
    />
  );
});
ActionSheetOverlay.displayName = DrawerPrimitive.Overlay.displayName;

const ActionSheetContent = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <ActionSheetPortal>
    <div className={cn('fixed bottom-0 w-screen z-50 flex justify-center')}>
      <ActionSheetOverlay />
      <DrawerPrimitive.Content
        ref={ref}
        className={cn(
          'z-action-sheet',
          'inset-x-0 outline-none',
          'flex justify-center mx-[16px] w-full',
          'max-w-[508px] flex h-auto flex-col bg-transparent mb-[16px] overflow-hidden',
          className,
        )}
        {...props}>
        <div
          className={cn(
            'flex flex-col overflow-hidden rounded-[16px] border bg-white outline-none',
            'pb-[8px]',
          )}>
          <div className="mb-[3px] mt-[5px] h-[4px] w-[84px] self-center rounded-full bg-gray-400 outline-none" />
          <Separated with={<Separator />}>{children}</Separated>
        </div>
      </DrawerPrimitive.Content>
    </div>
  </ActionSheetPortal>
));
ActionSheetContent.displayName = 'ActionSheetContent';

const ActionSheetHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('grid gap-1.5 p-4 ', className)} {...props} />
);
ActionSheetHeader.displayName = 'ActionSheetHeader';

const ActionSheetFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn('mt-auto flex flex-col gap-2 p-4', className)}
    {...props}
  />
);
ActionSheetFooter.displayName = 'ActionSheetFooter';

const ActionSheetTitle = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Title
    ref={ref}
    className={cn(
      'text-lg font-semibold leading-none tracking-tight',
      className,
    )}
    {...props}
  />
));
ActionSheetTitle.displayName = DrawerPrimitive.Title.displayName;

const ActionSheetDescription = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Description
    ref={ref}
    className={cn('text-sm text-muted-foreground', className)}
    {...props}
  />
));
ActionSheetDescription.displayName = DrawerPrimitive.Description.displayName;

const ActionSheetLabel = React.forwardRef<
  React.ElementRef<'span'>,
  React.ComponentPropsWithoutRef<'span'> & { asChild?: boolean }
>(({ className, asChild, ...props }, ref) => {
  const Comp = asChild ? Slot : 'span';
  return (
    <Comp
      ref={ref}
      className={cn(MENU_LABEL, 'px-[28px]', className)}
      {...props}
    />
  );
});
ActionSheetLabel.displayName = 'ActionSheetLabel';

const ActionSheetItem = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'> &
    VariantProps<typeof menuItemVariants> & {
      asChild?: boolean;
      disabled?: boolean;
    }
>(({ className, disabled, style: _, asChild, color, ...props }, ref) => {
  const Comp = asChild ? Slot : 'div';
  return (
    <Comp
      ref={ref}
      className={cn(menuItemVariants({ color }), 'px-[32px]', className)}
      aria-disabled={disabled || undefined}
      data-disabled={disabled ? '' : undefined}
      {...props}
    />
  );
});
ActionSheetItem.displayName = 'ActionSheetItem';

type ActionSheetItemLabelProps = ComponentPropsWithoutRef<'span'>;
const ActionSheetItemLabel = (props: ActionSheetItemLabelProps) => {
  const { className, children, ...rest } = props;
  return (
    <span className={cn(MENU_ITEM_LABEL, className)} {...rest}>
      {children}
    </span>
  );
};

const ActionSheetIconWrapper = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'> & { asChild?: boolean }
>(({ className, asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : 'div';

  return (
    <Comp className={cn(MENU_ICON_WRAPPER, className)} ref={ref} {...props} />
  );
});
ActionSheetIconWrapper.displayName = 'ActionSheetIconWrapper';

type SeparatorProps = ComponentPropsWithoutRef<'span'>;
const Separator = (props: SeparatorProps) => {
  const { className, ...rest } = props;
  return (
    <span
      className={cn(
        'shrink-0 bg-gray-200',
        'h-[1px] w-full my-[4px]',
        className,
      )}
      {...rest}
    />
  );
};

export {
  ActionSheet,
  ActionSheetLabel,
  ActionSheetItem,
  ActionSheetItemLabel,
  ActionSheetIconWrapper,
  ActionSheetPortal,
  ActionSheetOverlay,
  ActionSheetTrigger,
  ActionSheetClose,
  ActionSheetContent,
  ActionSheetHeader,
  ActionSheetFooter,
  ActionSheetTitle,
  ActionSheetDescription,
};
