import { ReactNode, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import cns from 'classnames';

import { Button } from '../Button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPanel,
  DialogTitle,
} from '../Dialog';

import S from './ConfirmDialog.module.scss';

export const ConfirmDialog = (props: ConfirmDialogProps) => {
  const {
    maxWidth = '320px',
    topSlot,
    title,
    content,
    keyboard = true,
    dimmed = true,
    useOutSideClick = false,
    confirmVariant = 'primary',
    cancelText,
    confirmText,
    focus,
    onClose,
    onConfirm,
    ...rest
  } = props;
  const { t } = useTranslation(['common']);
  const confirmButtonRef = useRef<HTMLButtonElement>(null);

  const handleClose = () => {
    onClose && onClose();
  };

  const handleCloseDialog = () => {
    if (!useOutSideClick) {
      return;
    }
    handleClose();
  };

  useEffect(() => {
    if (focus && confirmButtonRef.current) {
      confirmButtonRef.current.focus();
    }
  }, [focus, confirmButtonRef]);

  return (
    <Dialog
      maxWidth={maxWidth}
      onClose={handleCloseDialog}
      onKeyDown={e => !keyboard && e.stopPropagation()}
      initialFocus={confirmButtonRef}
      open
      {...rest}>
      {dimmed && <DialogOverlay />}
      <DialogPanel>
        <DialogHeader
          topSlot={topSlot}
          className={cns(content === undefined && S.header)}>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        {content && <DialogContent preLine>{content}</DialogContent>}
        <DialogFooter>
          <Button variant="sub" size="md" onClick={handleClose}>
            {cancelText ?? t('common:cancel')}
          </Button>
          <Button
            ref={confirmButtonRef}
            variant={confirmVariant}
            size="md"
            onClick={onConfirm}>
            {confirmText ?? t('common:confirm')}
          </Button>
        </DialogFooter>
      </DialogPanel>
    </Dialog>
  );
};

export type ConfirmDialogProps = {
  maxWidth?: string;
  topSlot?: ReactNode;
  title: ReactNode;
  content?: ReactNode;
  dimmed?: boolean;
  keyboard?: boolean;
  useOutSideClick?: boolean;

  cancelText?: ReactNode;
  confirmText?: ReactNode;
  confirmVariant?: 'primary' | 'alert';
  onClose?: () => void;
  onConfirm?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  focus?: boolean;
};
