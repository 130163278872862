import {
  GetNextPageParamFunction,
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ZepApiCursorPaginationResponse, ZepApiError } from '../zep-api';

export function useCursorInfiniteQuery<
  TQueryFnData extends ZepApiCursorPaginationResponse<any, any>,
  TError = AxiosError | ZepApiError,
  TData = TQueryFnData,
>(
  queryKey: QueryKey,
  queryFn: QueryFunction<TQueryFnData, QueryKey>,
  options?: UseCursorInfiniteQueryOptions<TQueryFnData, TError, TData>,
): UseInfiniteQueryResult<TData, TError> {
  const getNextPageParam: GetNextPageParamFunction<TQueryFnData> = (
    lastPage: TQueryFnData,
    allPages: TQueryFnData[],
  ) => {
    if (options?.getNextPageParam) {
      return options.getNextPageParam(lastPage, allPages);
    }
    const hasNext = !!lastPage.cursorPaginator?.next;
    return hasNext
      ? {
          next: lastPage.cursorPaginator.next,
          limit: lastPage.cursorPaginator.limit,
          hasNext: lastPage.cursorPaginator.hasNext,
        }
      : undefined;
  };

  return useInfiniteQuery<TQueryFnData, TError, TData>(queryKey, queryFn, {
    ...options,
    getNextPageParam,
  });
}

export type UseCursorInfiniteQueryOptions<
  TQueryFnData extends ZepApiCursorPaginationResponse<any, any>,
  TError = AxiosError | ZepApiError,
  TData = TQueryFnData,
> = Omit<
  UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData>,
  'queryKey' | 'queryFn'
>;
