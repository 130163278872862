import { ReactNode, useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import { ErrorBoundary, FallbackRender } from '@sentry/nextjs';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { redirectSigninPage } from '@zep/common/routes';
import { Error500PageView } from '@zep/web-components';

const GlobalErrorBoundary = ({ children }: Props) => {
  useUnhandledRejectionErrors(event => {
    console.log(
      '%c [JL] event - event',
      'font-size: 16px; color:  red;',
      event,
    );
    Sentry.captureException(event.reason.error);
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const fallback: FallbackRender = ({ error, resetError }) => {
    if (isServerError(error)) {
      switch (error.response.status) {
        case 401:
        case 403:
          resetError();
          redirectSigninPage();
          return null;
      }
    }
    return <Error500PageView />;
  };

  return (
    <ErrorBoundary fallback={fallback} onError={console.error}>
      {children}
    </ErrorBoundary>
  );
};

export default GlobalErrorBoundary;

type Props = {
  children: ReactNode;
};

export interface ServerError<T = any, D = any> extends AxiosError<T, D> {
  response: AxiosResponse<T, D>;
}

export function isServerError<T>(error: unknown): error is ServerError<T> {
  return (
    error != null &&
    axios.isAxiosError(error) &&
    typeof (error as ServerError<T>).response?.status !== 'undefined'
  );
}

export const useUnhandledRejectionErrors = (handler: Handler) => {
  useEffect(() => {
    window.addEventListener('unhandledrejection', handler);

    return () => {
      window.removeEventListener('unhandledrejection', handler);
    };
  }, [handler]);
};

type Handler = (event: PromiseRejectionEvent) => void;
