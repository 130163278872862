import { User } from '@zep/types';

import fetchJson from '../utils/fetchJson';

const prefix = process.env['NEXT_PUBLIC_NEXTAPI_PREFIX'] || '';

export const setUserSession = async (user: User): Promise<User> => {
  return fetchJson<User>(`${prefix}/nextapi/set-user-session`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ user: user }),
  });
};

export const removeUserSession = async (): Promise<{
  user: User | null;
}> => {
  return fetchJson(`${prefix}/nextapi/remove-user-session`);
};

export const getServerSession = async (): Promise<{
  serverSession: string;
}> => {
  return fetchJson(`${prefix}/nextapi/server-session`);
};

export const getUserSession = async (): Promise<{
  user: User | null;
}> => {
  return fetchJson(`${prefix}/nextapi/user-session`);
};
