import * as React from 'react';
import { SVGProps } from 'react';
export const QuestionmarkSmallCircleColorIcon = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10ZM10.486 9.164c.144-.864.947-1.398 1.904-1.353.458.022.861.184 1.138.443.26.243.472.628.472 1.246 0 .709-.242 1.001-.492 1.168-.322.215-.838.332-1.508.332a1 1 0 0 0-1 1v1a1 1 0 1 0 2 0v-.073c.555-.087 1.12-.264 1.617-.595C15.492 11.75 16 10.792 16 9.5c0-1.132-.413-2.06-1.106-2.707-.677-.632-1.555-.939-2.409-.98-1.668-.079-3.615.887-3.971 3.023a1 1 0 1 0 1.972.328ZM10.5 16.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
      fill="#F3F5F9"
    />
    <path
      d="M12 14a1 1 0 0 1-1-1v-1a1 1 0 1 1 2 0v1a1 1 0 0 1-1 1ZM10.5 16.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
      fill="#8C9097"
    />
    <path
      d="M12.39 7.811c-.957-.045-1.76.49-1.904 1.353a1 1 0 0 1-1.972-.328c.356-2.136 2.303-3.102 3.971-3.022.854.04 1.733.347 2.409.979C15.587 7.44 16 8.368 16 9.5c0 1.291-.508 2.249-1.383 2.832-.803.535-1.788.668-2.617.668a1 1 0 1 1 0-2c.67 0 1.186-.117 1.508-.332.25-.167.492-.46.492-1.168 0-.618-.212-1.003-.472-1.246-.277-.259-.68-.42-1.138-.443Z"
      fill="#8C9097"
    />
  </svg>
);
