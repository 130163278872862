import { CSSProperties, PropsWithChildren } from 'react';
import cns from 'classnames';

import { Colors } from '../../../dist/index';

import S from './LinearProgress.module.scss';

export const LinearProgress = (
  props: PropsWithChildren<LinearProgressType>,
) => {
  const { color = Colors.PrimaryDefault, width = '100%', value } = props;
  const MIN = 0;
  const MAX = 100;
  const styles = {
    maxWidth: width,
    '--progress-value': value,
    '--progress-color': color,
  } as CSSProperties;
  return (
    <div
      role="progressbar"
      className={cns(S.progress, value === undefined && S.loading)}
      aria-valuenow={value}
      aria-valuemin={MIN}
      aria-valuemax={MAX}
      style={styles}>
      <div className={S.bar}></div>
    </div>
  );
};

type LinearProgressType = {
  color?: string;
  width?: string;
  value?: number;
};
