import React, { useCallback, useState } from 'react';
import { nanoid } from 'nanoid';

import { AlertDialog, ConfirmDialog, PromptDialog } from '@zep/ui';

import { DialogContext } from './DialogContext';
import {
  ConfirmDialogType,
  DialogContextAlertProps,
  DialogContextConfirmProps,
  DialogPromptConfirmProps,
  DialogType,
  PromptDialogType,
} from './types';

export const DialogProvider = ({ children }: { children: React.ReactNode }) => {
  const [dialogs, setDialogs] = useState<DialogType[]>([]);

  /**
   *  if(await dialog.confirm({ title: '확인', cancelText: '취소', confirmText: '확인' })){
   *    !!TrueCase
   *  }
   *  dialog.confirm({ title: '확인', cancelText: '취소', confirmText: '확인' }).then((e) => if(e) { // !!TrueCase} })
   * */
  const confirm = useCallback((props: DialogContextConfirmProps) => {
    return new Promise<boolean>((resolve, reject) => {
      setDialogs(prevDialogs => [
        ...prevDialogs,
        {
          ...props,
          resolve,
          reject,
          focus: false,
          id: nanoid(),
          type: 'confirm',
        },
      ]);
    });
  }, []);

  /**
   * const dialog = useDialog();
   *
   * Await
   * if(await dialog.alert({ confirmText: '확인', title: 'header', })){
   *  TrueCase
   * }
   * dialog.alert({ confirmText: '확인', title: 'header', }).then(d => if(d){ !!TrueCase })
   * */
  const alert = useCallback((props: DialogContextAlertProps) => {
    return new Promise<boolean>((resolve, reject) => {
      setDialogs(prevDialogs => [
        ...prevDialogs,
        {
          ...props,
          resolve,
          reject,
          focus: false,
          id: nanoid(),
          type: 'alert',
        },
      ]);
    });
  }, []);

  /**
   *  사용 예시
   *  dialog.prompt({
   *    cancelText: true, inputType: 'text', confirmText: '1', title: '1',
   *  })
   *  .then(([result, value]) => {
   *    console.log(result, value);
   *  });
   * */
  const prompt = useCallback((props: DialogPromptConfirmProps) => {
    return new Promise<[boolean, string]>((resolve, reject) => {
      setDialogs(prevDialogs => [
        ...prevDialogs,
        {
          ...props,
          resolve,
          reject,
          focus: false,
          id: nanoid(),
          type: 'prompt',
        },
      ]);
    });
  }, []);

  const closeDialog = useCallback(() => {
    const currentDialog = dialogs[dialogs.length - 1];

    setDialogs(prevDialogs => {
      const newDialogs = prevDialogs.slice(0, -1);
      // focus event props 를 주입해서 포커가 될수 있게 설정함.
      if (newDialogs.length > 0) {
        newDialogs[newDialogs.length - 1].focus = true;
      }
      return newDialogs;
    });
    return currentDialog;
  }, [dialogs]);

  const handleConfirm = useCallback(() => {
    const currentDialog = closeDialog();

    if (!currentDialog) return Promise.reject('Not Found Dialog');

    if (currentDialog.confirmAction) {
      currentDialog
        .confirmAction()
        .then(() => {
          return currentDialog.resolve(true);
        })
        .catch(error => {
          return currentDialog.reject(error);
        });
      return;
    }
    currentDialog.resolve(true);
  }, [closeDialog]);

  const handlePrompt = useCallback(
    (e: React.FormEvent<HTMLFormElement>, value: string) => {
      const currentDialog = closeDialog();

      if (!currentDialog) return Promise.reject('Not Found Dialog');

      if (currentDialog.confirmAction) {
        currentDialog
          .confirmAction()
          .then(() => {
            return currentDialog.resolve([true, value]);
          })
          .catch(error => {
            return currentDialog.reject(error);
          });
        return;
      }
      currentDialog.resolve([true, value]);
    },
    [closeDialog],
  );

  const handleCancel = useCallback(() => {
    const currentDialog = closeDialog();
    if (!currentDialog) return Promise.reject('Not Found Dialog');

    if (currentDialog.type === 'prompt') {
      /** prompt 는 return array 로   */
      currentDialog.resolve([false, '']);
      return;
    }
    currentDialog.resolve(false);
  }, [closeDialog]);

  const renderDialog = ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    reject,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resolve,
    type,
    id,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    confirmAction,
    ..._rest
  }: DialogType) => {
    if (type === 'confirm') {
      const rest = _rest as ConfirmDialogType;

      return (
        <ConfirmDialog
          {...rest}
          key={id}
          onConfirm={handleConfirm}
          onClose={handleCancel}
        />
      );
    }

    if (type === 'prompt') {
      const rest = _rest as PromptDialogType;
      return (
        <PromptDialog
          {...rest}
          key={id}
          onConfirm={handlePrompt}
          onClose={handleCancel}
        />
      );
    }

    return (
      <AlertDialog
        {..._rest}
        key={id}
        onClose={handleConfirm}
        onConfirm={handleConfirm}
      />
    );
  };

  return (
    <DialogContext.Provider value={{ confirm, alert, prompt }}>
      {children}
      {dialogs.map(renderDialog)}
    </DialogContext.Provider>
  );
};
