import { ReactNode } from 'react';
import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { type VariantProps, cva } from 'class-variance-authority';

import { cn } from '../../utils/tailwind';

const buttonVariants = cva(
  [
    'focus-visible:ring-primary-bg-hover',
    'inline-flex',
    'items-center',
    'justify-center',
    '!leading-none',
    'shadow-[inset_0_0_0_1px_rgba(0,0,0,0.03)]',
    'outline-none',
    'focus-visible:ring-2',
  ],
  {
    variants: {
      intent: {
        primary:
          'bg-primary hover:bg-primary-hover disabled:bg-primary-disabled text-white shadow-none disabled:text-white/70',
        secondary:
          'bg-primary bg-primaryBg hover:bg-primaryBg-hover text-primary disabled:text-primary/40 disabled:bg-gray-50',
        sub: 'bg-gray-100 text-gray-600 hover:bg-gray-200 disabled:bg-gray-50 disabled:text-gray-600/40 hover:disabled:bg-gray-50',
        alert:
          'bg-redBg text-red hover:bg-redBg-hover disabled:bg-redBg/[0.4] disabled:text-red/40',
      },
      size: {
        md: 'text-subtitle size-[40px] rounded-[8px] p-[8px]',
        sm: 'text-body-1 size-[36px] rounded-[6px] p-[6px]',
        xs: 'text-body-1 size-[30px] rounded-[4px] p-[5px]',
        xxs: 'text-caption-1 size-[26px] rounded-[4px] p-[4px]',
        xxxs: 'text-caption-1 size-[18px] rounded-[4px] p-[4px]',
      },
      variant: {
        contained: '',
        transparent:
          'bg-transparent shadow-none disabled:bg-transparent hover:disabled:bg-transparent',
        rounded: 'rounded-full',
      },
    },
    compoundVariants: [
      {
        intent: 'primary',
        variant: 'transparent',
        className:
          'text-primary disabled:text-primary-disabled hover:bg-gray-700/[0.08] active:bg-gray-700/[0.08]',
      },
      {
        intent: 'secondary',
        variant: 'transparent',
        className:
          'text-primary disabled:text-primary-disabled hover:bg-gray-700/[0.08] active:bg-gray-700/[0.08]',
      },
      {
        intent: 'sub',
        variant: 'transparent',
        className: 'hover:bg-gray-700/[0.08] active:bg-gray-700/[0.08]',
      },
      {
        intent: 'alert',
        variant: 'transparent',
        className: 'hover:bg-gray-700/[0.08] active:bg-gray-700/[0.08]',
      },
    ],
    defaultVariants: {
      intent: 'primary',
      size: 'md',
      variant: 'contained',
    },
  },
);

export interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  children: ReactNode;
  asChild?: boolean;
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ className, intent, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ intent, variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
IconButton.displayName = 'IconButton';

export { IconButton, buttonVariants };
