import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { When } from 'react-if';
import { withAsyncBoundary } from '@toss/async-boundary';
import { useThrottle } from '@toss/react';
import cns from 'classnames';

import { useSpaceCategories } from '@zep/apis';
import { useAuth } from '@zep/auth';
import { PagePath } from '@zep/common/routes';
import { HammerIcon } from '@zep/icons';
import { useViewportDevice } from '@zep/react';
import { SpaceCategory } from '@zep/types';
import { Button, Stack } from '@zep/ui';
import { isTablet } from '@zep/utils';

import S from './MapTemplateModal.module.scss';

export const MapTemplateModalHeader = (props: MapTemplatesHeaderProps) => {
  const { tab, onTabChange, categoryId, onCategoryChange, onClickNoTemplate } =
    props;
  const { t } = useTranslation(['common']);
  const { isMobile } = useViewportDevice();
  const [isScrolling, setIsScrolling] = useState(false);

  const onScroll = useThrottle((e: Event) => {
    if (e.target instanceof HTMLDivElement) {
      setIsScrolling(e.target.scrollTop > 0);
    }
  }, 100);

  useEffect(() => {
    const templateList = document.getElementById('template_modal_content');
    templateList?.addEventListener('scroll', onScroll);
    return () => templateList?.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  return (
    <div
      className={cns(S.header_wrapper, {
        [S.header_wrapper_shadow]: isScrolling,
      })}>
      <Stack direction="row" className={S.tabs_and_buttons}>
        <TabMenus tab={tab} onTabChange={onTabChange} />
        <When condition={!isMobile}>
          <Stack direction={'row'} gap={5}>
            <Button
              as="a"
              variant="sub"
              size="sm"
              children={t('common:mapTemplateModal.assetStore')}
              href={PagePath.AssetStore}
              target="_blank"
            />
            <When condition={!isTablet()}>
              <Button
                as="button"
                variant="secondary"
                size="sm"
                leftSlot={<HammerIcon width={16} height={16} />}
                children={t('common:mapTemplateModal.emptyTemplate')}
                onClick={onClickNoTemplate}
              />
            </When>
          </Stack>
        </When>
      </Stack>
      <When condition={tab === 'official'}>
        <CategorySelector
          categoryId={categoryId}
          onCategoryChange={onCategoryChange}
        />
      </When>
    </div>
  );
};

const TabMenus = (
  props: Pick<MapTemplatesHeaderProps, 'tab' | 'onTabChange'>,
) => {
  const { tab, onTabChange } = props;
  const { user } = useAuth();
  const { t } = useTranslation(['common']);
  const { isMobile } = useViewportDevice();

  const isEdu = user?.isEdu ?? false;

  return (
    <Stack
      direction="row"
      className={S.tab_container}
      style={{ width: isMobile ? '100%' : 'auto' }}>
      <span
        className={cns(S.tab, {
          [S.tab_active]: tab === 'official',
        })}
        onClick={() => onTabChange('official')}>
        {t('common:mapTemplateModal.publicMap')}
      </span>
      <div className="h-[12px] w-px bg-gray-300" />
      <span
        className={cns(S.tab, {
          [S.tab_active]: tab === 'purchased',
        })}
        onClick={() => onTabChange('purchased')}>
        {isEdu
          ? t('common:mapTemplateModal.eduMap')
          : t('common:mapTemplateModal.purchaseMap')}
      </span>
    </Stack>
  );
};

const CategorySelector = withAsyncBoundary(
  (props: Pick<MapTemplatesHeaderProps, 'categoryId' | 'onCategoryChange'>) => {
    const { categoryId, onCategoryChange } = props;
    const { i18n, t } = useTranslation(['common']);
    const {
      data: { categories },
    } = useSpaceCategories({ locale: i18n.resolvedLanguage ?? 'en' });

    const categoriesWithAll = useMemo<SpaceCategory[]>(
      () => [
        {
          id: -1,
          category: t('common:space.category.all'),
          visible: true,
          priority: 0,
          prefixIcon: null,
        },
        ...categories.filter(category => category.visible),
      ],
      [categories, t],
    );

    return (
      <div className={S.category}>
        {categoriesWithAll.map(category => (
          <div
            key={category.id}
            className={cns(S.category_item, {
              [S.category_item_active]: categoryId === category.id,
            })}
            onClick={() => onCategoryChange(category.id)}>
            {category.prefixIcon ?? ''} {category.category}
          </div>
        ))}
      </div>
    );
  },
  {
    pendingFallback: null,
    rejectedFallback: () => null,
  },
);

interface MapTemplatesHeaderProps {
  tab: string;
  onTabChange: (tab: 'official' | 'purchased') => void;
  categoryId: number;
  onCategoryChange: (categoryId: number) => void;
  onClickNoTemplate: () => void;
}
