import {
  ComponentProps,
  FormEvent,
  forwardRef,
  HTMLInputTypeAttribute,
  KeyboardEvent,
  ReactNode,
  useRef,
} from 'react';

import { cn } from '@zep/utils';

import S from './Input.module.scss';

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    type = 'text',
    size = 'md',
    leftSlot,
    rightSlot,
    error,
    isFullWidth,
    isReadonly,
    isDisabled,
    className,
    maxLength,
    onKeyDown,
    onInput,
    ...rest
  } = props;

  const inputValue = useRef<string>('');
  const inputCaretPosition = useRef<number>(0);

  /**
   * 안드로이드 또는 composition 언어인 경우에 maxLength를 초과하여 입력되는 이슈에 대비하여 값 저장
   * @param event
   */
  const onKeyDownForMaxLength = (event: KeyboardEvent<HTMLInputElement>) => {
    const targetInput = event.target as HTMLInputElement;
    inputValue.current = targetInput.value;
    inputCaretPosition.current = targetInput.selectionStart ?? 0;
  };

  /**
   * 안드로이드 또는 composition 언어인 경우에 maxLength를 초과하여 입력되는 이슈 방지를 위해 저장된 값으로 덮어쓰기 후 키보드 커서 위치 복구
   * @param event
   */
  const onInputForMaxLength = (event: FormEvent<HTMLInputElement>) => {
    const targetInput = event.target as HTMLInputElement;
    if (
      maxLength &&
      targetInput.value.length > maxLength &&
      targetInput.value.length > inputValue.current.length
    ) {
      targetInput.value = inputValue.current;

      if (targetInput.type !== 'number') {
        targetInput.setSelectionRange(
          inputCaretPosition.current,
          inputCaretPosition.current,
        );
      }
    }
  };

  return (
    <span
      className={cn(
        S.input,
        S[`size_${size}`],
        {
          [S.error]: error,
          [S.fullWidth]: isFullWidth,
          [S.disabled]: isDisabled,
          [S.readOnly]: isReadonly,
        },
        className,
      )}>
      {leftSlot && <span className={S.slot}>{leftSlot}</span>}
      <input
        {...rest}
        ref={ref}
        type={type}
        className={S.control}
        readOnly={isReadonly}
        disabled={isDisabled}
        maxLength={maxLength}
        onKeyDown={event => {
          onKeyDownForMaxLength(event);
          onKeyDown && onKeyDown(event);
        }}
        onInput={event => {
          onInputForMaxLength(event);
          onInput && onInput(event);
        }}
      />
      {rightSlot && <span className={S.slot}>{rightSlot}</span>}
    </span>
  );
});

export type InputProps = Omit<ComponentProps<'input'>, 'size' | 'ref'> & {
  type?: HTMLInputTypeAttribute;
  size?: 'md' | 'sm';
  leftSlot?: ReactNode;
  rightSlot?: ReactNode;
  isFullWidth?: boolean;
  isDisabled?: boolean;
  isReadonly?: boolean;
  error?: boolean;
};
