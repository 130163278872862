import { ReactNode } from 'react';

import { Controlled } from './Controlled';
import { Marker } from './Marker';
import { Uncontrolled } from './Uncontrolled';

export type RangeSliderProps = {
  width?: string;
  height?: string;
  vertical?: boolean;
  className?: string;
  hideTooltip?: boolean;
  children?: ReactNode;
  min?: number;
  max?: number;
  step?: number;
  onChange?(value: number): void;
  name?: string;
  value?: number;
  defaultValue?: number;
};

export function _RangeSlider({ value, ...props }: RangeSliderProps) {
  return value != null ? (
    <Controlled {...props} value={value} />
  ) : (
    <Uncontrolled {...props} />
  );
}

export const RangeSlider = Object.assign(_RangeSlider, {
  Marker,
});
