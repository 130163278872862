import cns from 'classnames';
import Link from 'next/link';

import { XIcon } from '@zep/icons';

import { GNBMenu, GNBSubMenu } from '../../../types';

import S from './Header.module.scss';
import { HeaderCTAButton, Logos } from './HeaderCommons';

/**
 * @param MobileGNBMenuProps
 * @returns 모바일 헤더 메뉴 컴포넌트
 */
const MobileGlobalNavigationBarMenu = (props: MobileGNBMenuProps) => {
  const { headerContent, setIsShow, isShow } = props;

  return (
    <nav className={cns(S.mobile_nav, isShow && S.show_mobile_nav)}>
      <div className={S.mobile_header}>
        {<Logos />}
        <button className={S.close_button} onClick={() => setIsShow(!isShow)}>
          <XIcon aria-label="Close" />
        </button>
      </div>
      <div className={S.mobile_contents}>
        {headerContent.map((menu: GNBMenu) => (
          <div
            className={cns(S.mobile_menu, menu.menus && S.mobile_menu_sub)}
            key={menu.title}>
            {menu.menus ? (
              <div className={S.mobile_menu_mini}>{menu.title}</div>
            ) : (
              <Link
                href={
                  ((menu.useBaseURL && process.env.NEXT_PUBLIC_BASE_URL) ||
                    '') + menu.path
                }
                target={menu.target}
                onClick={() => setIsShow(!isShow)}
                prefetch={false}>
                {menu.title}
              </Link>
            )}

            {menu.menus && (
              <>
                <div className={S.mobile_subMenu}>
                  {menu.menus
                    .filter((menus: GNBSubMenu) => !menus.hidden)
                    .map((menus: GNBSubMenu) => (
                      <Link
                        key={menus.name}
                        href={
                          ((menus.useBaseURL &&
                            process.env.NEXT_PUBLIC_BASE_URL) ||
                            '') + menus.url
                        }
                        target={menus.target}
                        onClick={() => setIsShow(!isShow)}
                        prefetch={false}>
                        <img
                          width={18}
                          height={18}
                          src={menus.icon}
                          alt="sub menu icon"
                        />
                        <span>{menus.name}</span>
                      </Link>
                    ))}
                </div>
                <span className={S.contour_line}></span>
              </>
            )}
          </div>
        ))}
      </div>
      <div className={S.mobile_footer}>
        <HeaderCTAButton />
      </div>
    </nav>
  );
};

export default MobileGlobalNavigationBarMenu;

type MobileGNBMenuProps = {
  headerContent: GNBMenu[];
  isShow: boolean;
  setIsShow: (isShow: boolean) => void;
};
