import type { BrowserClient } from '@sentry/nextjs';

// eslint-disable-next-line @nx/enforce-module-boundaries
import baseOptions from '../../../../sentry.base.config';

let isInitialized = false;
export const initSentry = async () => {
  const { getCurrentHub, init, Replay, BrowserTracing } = await import(
    '@sentry/nextjs'
  );

  init({
    ...baseOptions,
    integrations: [],
  });

  if (!isInitialized) {
    const client = getCurrentHub().getClient<BrowserClient>();
    client?.addIntegration(new Replay());
    client?.addIntegration(new BrowserTracing());
    isInitialized = true;
  }
};
