import { Fragment } from 'react';
import { Dialog, DialogOverlayProps, Transition } from '@headlessui/react';

import { cn } from '../../utils/tailwind';

import S from './DialogOverlay.module.scss';

export const DialogOverlay = ({
  className,
  ...rest
}: DialogOverlayProps<'div'>) => {
  return (
    <Transition.Child
      as={Fragment}
      enter={S.enter}
      enterFrom={S.enter_from}
      enterTo={S.enter_to}
      leave={S.leave}
      leaveFrom={S.leave_from}
      leaveTo={S.leave_to}>
      <Dialog.Overlay {...rest} className={cn(S.overlay, className)} />
    </Transition.Child>
  );
};
