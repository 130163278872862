import { useModal } from '@ebay/nice-modal-react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { zepAnalytics } from '@zep/analytics';
import { useAuth } from '@zep/auth';
import { useIsomorphicViewportDevice } from '@zep/react';
import { Button } from '@zep/ui';
import { CreateSpaceFunnel } from '@zep/web-components';

import S from './Header.module.scss';
import UserMenu from './UserMenu';

/**
 * @returns 젭 로고 컴포넌트
 */
const Logos = () => {
  const { user } = useAuth();
  const link = user ? '/home/spaces' : '/home/landing';
  const logoStyle = {
    width: user?.isEdu ? 132 : 84,
    url: user?.isEdu
      ? '/images/light/layout/logo_zep_edu.svg'
      : '/images/light/layout/logo_zep.svg',
  };
  return (
    <Link href={link} className={S.logo} prefetch={false}>
      <img width={logoStyle.width} height={32} src={logoStyle.url} alt="zep" />
    </Link>
  );
};

/**
 * 헤더 Call To Action 버튼 컴포넌트
 */
const HeaderCTAButton = (props: HeaderCTAButtonProps) => {
  const { isHeaderCTA } = props;
  const { isPortableDevice } = useIsomorphicViewportDevice();
  const { user, isInitialized } = useAuth();
  const router = useRouter();
  const createSpaceFunnel = useModal(CreateSpaceFunnel);
  const { t } = useTranslation(['layout'], { useSuspense: true });

  // 로그인
  const goSignIn = (property?: string) => {
    const isLoginClick = property === 'login';

    if (isLoginClick) {
      zepAnalytics.track('home_login');
    } else {
      zepAnalytics.track('home_cta', { action: 'login' });
    }
    router.push('/accounts/signin');
  };

  // 스페이스 생성
  const handleClickCreateSpace = async () => {
    await createSpaceFunnel.show({ from: 'home' });

    zepAnalytics.track('home_cta', { action: 'create' });
  };

  if (!isInitialized) {
    return null;
  }

  return (
    <>
      {user ? (
        <>
          {isHeaderCTA && <UserMenu />}
          {router.pathname !== '/home/spaces' && (
            <Button
              className={S.create_button}
              type="button"
              variant="primary"
              onClick={handleClickCreateSpace}>
              {t('layout:header_create_space_button')}
            </Button>
          )}
        </>
      ) : (
        <>
          <Button
            className={S.signIn_button}
            type="button"
            variant="secondary"
            size={isHeaderCTA && isPortableDevice ? 'xs' : 'md'}
            onClick={() => goSignIn('login')}>
            {t('layout:header_signin')}
          </Button>

          <Button
            className={S.start_button}
            type="button"
            variant="primary"
            size={isHeaderCTA && isPortableDevice ? 'xs' : 'md'}
            onClick={() => goSignIn()}>
            {t('layout:header_free_start_button')}
          </Button>
        </>
      )}
    </>
  );
};

export { Logos, HeaderCTAButton };

type HeaderCTAButtonProps = {
  isHeaderCTA?: true;
};
