import { ZNetLoginRequest } from '@zep/proto';

import { MapAccessRequest } from '../api/map';

export enum SpaceRole {
  GUEST = -1,
  MEMBER = 0,
  EDITOR = 1000,
  STAFF = 2000,
  ADMIN = 3000,
  OWNER = 3001,
}
export type SPACE_ROLE_TEXT =
  | 'GUEST'
  | 'MEMBER'
  | 'EDITOR'
  | 'STAFF'
  | 'ADMIN'
  | 'OWNER';

export enum LOCAL_STORAGE_KEY {
  MEDIA = 'media',
  INPUT_DEVICE = 'inputDevice',
  PLAYER_SETTINGS = 'playerSettings',
  CHANNEL_TALK_MEMBER_ID = 'channelTalkMemberId',
}

export enum CookieKey {
  REMOVE_ADVERTISEMENT = 'removeAdvertisement',
  VIEWED_ADVERTISEMENT = 'viewedAdvertisement_',
  TUTORIAL_COMPLETED = 'tutorialCompleted',
}

export enum SESSION_STORAGE_KEY {
  CHANNEL_TOOLTIP_SHOWN = 'channelTooltipShown_',
  PLAY_SESSION = 'playSession',
  PLAYERS_VOLUME = 'playersVolume',
}

export type ParsedPlayQueryParams = {
  spaceMapQueryParam: SpaceMapQueryParam;
  loginQueryParam: LoginQueryParam;
  customDomain: string | undefined;
  forceKey: number;
  mobileTouchMove?: boolean;
};
export type SpaceMapQueryParam = Partial<MapAccessRequest> & {
  mapHashId: string;
};
export type LoginQueryParam = Partial<ZNetLoginRequest> & {
  mapHashId: string;
};

export type TemplateType = 'templates' | 'purchasedTemplates';

export type MapTemplateCard = {
  hashId?: string;
  name?: string;
  categories?: number[];
  imageUrl?: string;
  isNew?: boolean;
};
