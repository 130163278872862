import { createContext } from 'react';

import {
  DialogContextAlertProps,
  DialogContextConfirmProps,
  DialogPromptConfirmProps,
} from './types';

const emptyFn = async () => {
  throw new Error('Could not find DialogProvider.');
};

/**
 *
 * ❌ 감싸면 무한 실행 버그 걸립니다.
 * useEffect(() => {}, [dialog])
 *
 * ⭕ 이런식으로 부탁드립니다.
 * const alert = usePreservedCallback(() => {
 *     dialog.alert({
 *       title: '1',
 *     });
 * });
 *
 * useEffect() => { alert(), [alert]}
 * */
export const DialogContext = createContext<DialogContextType>({
  alert: emptyFn,
  confirm: emptyFn,
  prompt: emptyFn,
});

/** 확인창, 결과값 Promise Boolean 반환 */
export type DialogContextType = {
  /**
   *  필수 paramas
   *  confirmText: '확인',
   *  title: 'header',
   *
   *
   * */
  alert: (props: DialogContextAlertProps) => Promise<boolean>;
  /**
   * title: '확인',
   * cancelText: '취소',
   * confirmText: '확인',
   * */
  confirm: (props: DialogContextConfirmProps) => Promise<boolean>;
  /**
   *  필수 params
   *  title: '1'
   *  confirmText: '1',
   *  cancelText: true,
   *  inputType: 'text',
   * */
  prompt: (props: DialogPromptConfirmProps) => Promise<[boolean, string]>;
};
