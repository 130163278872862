import { Dialog, DialogTitleProps } from '@headlessui/react';

import { cn } from '../../utils/tailwind';

import S from './Dialog.module.scss';

export const DialogTitle = (props: DialogTitleProps<'div'>) => {
  const { className, children } = props;
  return (
    <Dialog.Title {...props} className={cn(S.title, className as string)}>
      {children}
    </Dialog.Title>
  );
};
