import { AllLanguageType } from '../types/StoreTypes';

export const ZepStringKeys = {
  GOOGLE_USER_EMAIL: 'zep-string-manager@metatown.iam.gserviceaccount.com',
  CROWDIN_TOKEN:
    '8e9cef3470d99f78ac41026a10decb3df56f791c25637b13de8c0372bb613585728d02104a7605da',
  CROWDIN_PROJECT_ID: 569143,
  CLIENT: {
    GOOGLE_SHEET_ID: '1kucrm_EoWYdzJYk8bP3btQ9aN3Fr7odwvtvavn_5exg',
    DISTRIBUTION_HASH: '52742882e7f78f8dd5bbe79c1wt',
    LIVE_DISTRIBUTION_HASH: '8ca17f90fedefdd1d7e63c9c1wt',
  },
  SCRIPT: {
    GOOGLE_SHEET_ID: '16G_XRfBuJtckNZaBZ0huO0zsI7viaIUXR4M8Numi0FQ',
    DISTRIBUTION_HASH: '0e41ab85dff1f0ad86a0a89c1wt',
    LIVE_DISTRIBUTION_HASH: '45f32e3117235f4718b65f9c1wt',
  },
  APP: {
    GOOGLE_SHEET_ID: '1IbdZrm4Bq6MmA72ZAyVizpPLfsBPyFztewoLkOaPmTs',
    DISTRIBUTION_HASH: '145def5b544fadbb3e598d9c1wt',
  },
};

export const ZepetofulEntries = {
  // 롯데 NFT 행사
  LOTTE_NFT: 'VNPfR3wK873t0T4FS8EtST3',
  // 개인정보처리방침
  PRIVACY: 'EPZoA3wK876tTRxl3QFt7dp',
  // 이용 약관
  TERMS: '5TZNv3wK876tTRC9vPaoq43',
  // 둘러보기 컨텐츠
  EXPLORE: 'tD1rE3wK87HtXIIm1QD8I7U',
  // 에셋 스토어 추천 에셋
  STORE_RECOMMEND_ASSETS: 'sXe463wK87pt8I6AOMe6vTa',
  // 앱스토어 추천 앱
  STORE_RECOMMEND_APPS: 'W2Pqp3wK88wtdU41i4CfTbu',
  // 에셋 스토어 배너 리스트
  STORE_BANNERS: 'KRxRy3wK88BtbitfTbng5bI',
  // 에셋 스토어 구축 문의 배너 리스트
  STORE_INQUIRY_BANNERS: 'dvyn23wK87HtWYj6Gjfe0M2',
  // 메인 피쳐드 배너 리스트
  MAIN_FEATURED_BANNERS: 'crvjW3wK88xteJeOrHW0wmQ',
  // 교육용 둘러보기 컨텐츠
  EXPLORE_EDU: 'jKMIv3wK87KtYjtpjJCX40h',
  // 교육용 스페이스 배너 리스트
  MAIN_BANNERS_EDU: 'EqVE63wK87LtgxQdsABivSm',
  // 교육용 에셋 스토어 추천 에셋
  STORE_RECOMMEND_ASSETS_EDU: '7pYAc3wK87LthyQMvWxnGFy',
  // 교육용 앱스토어 추천 앱
  STORE_RECOMMEND_APPS_EDU: 'a7yYT3wK87MthyQPtYxXjlr',
  // 배너 리스트
  BANNERS: 'nKdC93wK876tT06OqyQf9BM',
  // 글로벌 네비게션바 메뉴 리스트(GNB)
  GNB_MENU_LIST: 'QjhcD3wK87Vtp6KobEMpIo2',
  // 프리미엄 스페이스 약관
  PREMIUM_SPACE_TERMS: 'ehbIF3wK89dttWsH2LC54PX',
  LAYOUT_FOOTER_SNS_LIST: 'dFgZb3wK879twJZlgJyLIKc', // footer SNS 리스트
  BAD_WORDS: 'T1asH3wK88dtAwKwhjO1en1',

  // 랜딩 페이지 데이터
  LANDING_PAGE: 'eoKZJ3wK87ftxNWNeeFTSah',
  // 오피스 랜딩 페이지 데이터
  OFFICE_PAGE: 'ojbzv3wK879txOlt5dLYVO7',
  // 에듀 랜딩 페이지 데이터
  ONLINE_CLASS_PAGE: 'saakf3wK87Ru3zahSh4Lq5B',

  // 요금제 페이지 데이터
  PRICING_PAGE: 'gYBVP3wK879tGVO2AmneXHL',

  // 프라이싱 기업 로고 데이터
  PRICING_CLIENTS: 'SC26K3wK87dujZhqqGeXDkc',

  // 행사 페이지 데이터
  EVENT_PAGE: 'F7dLb3wK87due9sphAZ09TP',
  // 학교 페이지 데이터
  SCHOOL_PAGE: '1A1TU3wK87due9rWQQIqIPc',
  // HRD 페이지 데이터
  HRD_PAGE: 'saDJ83wK87due9symFT7tb1',
  // 브랜딩 페이지 데이터
  BRANDING_PAGE: 'lBtjd3wK87dueGX4uLePwEb',
};

export const StoreLocales: AllLanguageType[] = [
  'en',
  'ko',
  'ja',
  'id',
  'th',
  'vi',
  'es',
  'pt',
  'ge',
  'fr',
  'ru',
];

// 서버에서 사용하는 에러코드 모음, 찾기 쉽게 하기 위해 변수명은 v1과 통일
export enum ErrorStatus {
  FAILED = -1,
  IS_NOT_IMAGE_FILE = -84,
  TOO_MANY_SIGNIN_TRIAL = -94,
  CACHE_INVALIDATION_FAILED = -103,
  NOT_UPLOAD_PREMIUM_SPACE_MAP_ASSET = -116,
  NOT_UPLOAD_INVISIBLE_MAP_ASSET = -117,
  NOT_UPLOAD_REVIEW_COMPLETED_APP_ASSET = -120,
  NOT_UPLOAD_REVIEW_COMPLETED_MAP_ASSET = -121,
  FINISHED_PAYMENT = -124,
  DISABLED_PAYMENT = -125,
  NOT_AGREED_TERMS_OR_PRIVACY_POLICY = -192,
  TOO_MANY_AUTH_CODE_FAILURES = -194, // 인증번호 5회 이상 입력 실패
}

export const CHANNEL_TALK_UNUSED_PATHS = [
  '/store',
  '/accounts/agree-terms',
  '/shared',
];
