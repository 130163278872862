import { CSSProperties, PropsWithChildren } from 'react';
import { When } from 'react-if';

import { cn } from '@zep/utils';

import { Profile } from '../Profile';

import S from './Avatar.module.scss';

/**
 *
 * @param name skin | clothes | face | hair
 * @param id 1 ~ 999
 * @returns http|s://baseUrl/path/sprites/{name}/-${id}.png
 */
function getSpriteImageUrl(name: string, id: number) {
  return new URL(
    `/static/assets/sprites/${name}/-${id}.png`,
    process.env.NEXT_PUBLIC_ASSETS_URL || 'https://zep.us',
  ).href;
}

export const Avatar = (props: PropsWithChildren<AvatarProps>) => {
  const {
    skin = 2,
    clothes = 8,
    face = 1,
    hair = 73,
    posture = 'front',
    behavior = 'stand',
    className,
  } = props;

  const playerInfo = {
    skin,
    clothes,
    face,
    hair,
  };

  return (
    <div
      className={cn(
        S.character,
        {
          [S.left]: posture === 'left',
          [S.right]: posture === 'right',
          [S.back]: posture === 'back',
          [S.front]: posture === 'front',
        },
        {
          [S.jump]: behavior === 'jump',
          [S.sit]: behavior === 'sit',
          [S.stand]: behavior === 'stand',
        },
        className,
      )}>
      {Object.entries(playerInfo).map(([name, id], index) => (
        <span
          key={index}
          className={S.object}
          style={
            {
              '--image-url': `url(${getSpriteImageUrl(name, id)})`,
            } as CSSProperties
          }
          aria-hidden="true"></span>
      ))}
      <When condition={playerInfo.skin <= 0}>
        <div className={S.empty_profile}>
          <Profile empty />
        </div>
      </When>
    </div>
  );
};

export type AvatarProps = {
  skin?: number;
  clothes?: number;
  face?: number;
  hair?: number;
  posture?: 'left' | 'right' | 'back' | 'front';
  behavior?: 'stand' | 'sit' | 'jump';
  className?: string;
};
