import { ReactNode } from 'react';
import { Menu } from '@headlessui/react';
import cns from 'classnames';

import S from './MenuList.module.scss';

export const MenuList = (props: MenuListProps) => {
  const { className, children } = props;
  return <Menu.Items className={cns(S.list, className)}>{children}</Menu.Items>;
};

type MenuListProps = {
  className?: string;
  children: ReactNode;
};
