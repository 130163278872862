import { forwardRef } from 'react';
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';

import { cn } from '../../utils/tailwind';

import S from './ScrollArea.module.scss';

export const ScrollArea = forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root>
>(({ className, children, type = 'always', ...props }, ref) => (
  <ScrollAreaPrimitive.Root
    ref={ref}
    className={cn(S.scroll, className)}
    type={type}
    {...props}>
    <ScrollAreaPrimitive.Viewport className={S.viewport}>
      {children}
    </ScrollAreaPrimitive.Viewport>
    <ScrollBar />
    <ScrollAreaPrimitive.Corner />
  </ScrollAreaPrimitive.Root>
));
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

const ScrollBar = forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>
>(({ className, orientation = 'vertical', ...props }, ref) => (
  <ScrollAreaPrimitive.ScrollAreaScrollbar
    ref={ref}
    orientation={orientation}
    className={cn(
      S.scrollbar,
      orientation === 'vertical' && S.vertical,
      orientation === 'horizontal' && S.horizontal,
      className,
    )}
    {...props}>
    <ScrollAreaPrimitive.ScrollAreaThumb
      className={cn(S.thumb, orientation === 'vertical' && S.vertical)}
    />
  </ScrollAreaPrimitive.ScrollAreaScrollbar>
));
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;
