import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Error500Widget } from './error-widgets';
import { ErrorPageWrapper } from './ErrorPageWrapper';

type Error500Props = {
  error?: Error | unknown;
  onResetError?(): void;
};

export const Error500PageView = (props: Error500Props) => {
  const { error, onResetError } = props;

  const { ready } = useTranslation(['common']);

  useEffect(() => {
    console.log('Error500', error);

    const notifyErrorParentWindow = () => {
      window.parent.postMessage({ type: 'ZEP_500_ERROR' }, '*');
    };

    notifyErrorParentWindow();

    return () => {
      onResetError && onResetError();
    };
  }, [error, onResetError]);

  if (!ready) return null;

  return (
    <ErrorPageWrapper>
      <Error500Widget />
    </ErrorPageWrapper>
  );
};
