import { ComponentProps, forwardRef, ReactNode } from 'react';

import {
  CheckSmallCircleFillIcon,
  ExclamationmarkSmallCircleIcon,
  QuestionmarkSmallCircleIcon,
} from '@zep/icons';

import S from './Toast.module.scss';

export const Toast = forwardRef<HTMLDivElement, ToastProps>((props, ref) => {
  const { type, leftSlot, rightSlot, children } = props;
  const icons = {
    success: () => <CheckSmallCircleFillIcon color="#15E4BF" />,
    error: () => <ExclamationmarkSmallCircleIcon color="#FF5353" />,
    help: () => <QuestionmarkSmallCircleIcon />,
  };
  const IconComponent = icons[type ?? 'success'];
  return (
    <div ref={ref} role="alert" className={S.toast}>
      {(leftSlot || type) && (
        <span className={S.slot}>
          {typeof type === 'string' ? <IconComponent /> : leftSlot}
        </span>
      )}
      <span>{children}</span>
      {rightSlot && <span className={S.slot}>{rightSlot}</span>}
    </div>
  );
});

export type ToastProps = ComponentProps<'div'> & {
  type?: 'success' | 'error' | 'help';
  leftSlot?: ReactNode;
  rightSlot?: ReactNode;
  children: ReactNode;
};
