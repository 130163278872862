import { getGeoInfoFromTimeZone } from './geoTimeZoneUtils';

export const isEuropeByTimeZone = () => {
  return getGeoInfoFromTimeZone().continent === Continent.Europe;
};

export enum Continent {
  Africa = 'Africa',
  Antarctica = 'Antarctica',
  Asia = 'Asia',
  Europe = 'Europe',
  NorthAmerica = 'North America',
  Oceania = 'Oceania',
  SouthAmerica = 'South America',
}
