import { usePreservedCallback } from '@toss/react';
import { useContextSelector } from 'use-context-selector';

import { useLogout } from '@zep/apis';
import { User } from '@zep/types';

import { AuthContext } from '../contexts';

interface Auth {
  /**
   * The user object.
   * 유저 정보
   * 커스텀한 조건의 유저정보 조회가 필요하면 useUser를 사용하세요.
   */
  user: User | null;

  /*
   Flag indicating if a user is authenticated or not.
   로그인 되어있는지 여부
   */
  isAuthenticated: boolean;

  /*
   Flag indicating if the authentication process has been initialized or not.
   초기싸이클이 완료 되었는지 여부 -> 로그인 되어있는지 확인 및 유저 정보 패칭완료 여부
   */
  isInitialized: boolean;

  /*
   Function to update the user object and perform a login.
   user 정보를 업데이트한다.
   */
  login: (user: User) => Promise<void>;

  /*
   Function to clear the user object and perform a logout.
   로그아웃 요청을 보내고 user 정보를 초기화한다.
   */
  logout: () => void;

  refetchUser: () => Promise<void>;

  clearUser: () => void;
}

export const useAuth = (): Auth => {
  const { mutate: postLogout } = useLogout();

  // should be replaced with useUser
  const user = useContextSelector(AuthContext, state => state?.user);

  const isInitialized = useContextSelector(
    AuthContext,
    state => state?.isInitialized,
  );

  const updateUser = useContextSelector(
    AuthContext,
    state => state?.updateUser,
  );

  const refetchUser = useContextSelector(AuthContext, state => async () => {
    if (user) {
      state?.refetch();
    }

    return;
  });

  const clearUser = useContextSelector(AuthContext, state => state?.clearUser);

  if (
    user === undefined ||
    isInitialized === undefined ||
    updateUser === undefined ||
    clearUser === undefined
  ) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  const login = updateUser;

  const logout = usePreservedCallback(async () => {
    clearUser();
    postLogout();
  });

  return {
    user,
    refetchUser,
    clearUser,
    isAuthenticated: !!user,
    isInitialized,
    login,
    logout,
  };
};
