import cn from 'classnames';

import S from './InputTextLengthLabel.module.scss';

/**
 *  textLabel 에 들어가는 input 입력 길이 및 최대 길이를 나타내주는 컴포넌트
 * */
export const InputTextLengthLabel = (props: InputTextLengthLabelProps) => {
  const { maxInputLength, inputLength, className } = props;

  return (
    <span className={cn(S.text, className)}>
      {inputLength}/{maxInputLength}
    </span>
  );
};

type InputTextLengthLabelProps = {
  inputLength: number;
  maxInputLength: number;
  className?: string;
};
