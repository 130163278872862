import * as React from 'react';
import { SVGProps } from 'react';
export const ChevronDoubleLeftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M11.707 5.293a1 1 0 0 0-1.414 0l-6 6a1 1 0 0 0 0 1.414l6 6a1 1 0 0 0 1.414-1.414L6.414 12l5.293-5.293a1 1 0 0 0 0-1.414Zm6 0a1 1 0 0 0-1.414 0l-6 6a1 1 0 0 0 0 1.414l6 6a1 1 0 0 0 1.414-1.414L12.414 12l5.293-5.293a1 1 0 0 0 0-1.414Z"
      fill={props.fill || `currentColor`}
    />
  </svg>
);
