import { useTranslation } from 'next-i18next';

import { LoginUserMenu, SubLayoutButton } from '../types';

/**
 * @desc 사용자 메뉴 리스트 데이터
 */

export const LoginUserMenuList: LoginUserMenu[] = [
  {
    name: 'layout:header_layout_my_spaces',
    path: '/home/spaces',
    target: '_self',
    hidden: false,
    beta: false,
    useBaseURL: false,
  },
  {
    name: 'layout:header_layout_help',
    path: 'https://docs-kr.zep.us',
    pathEn: 'https://docs.zep.us/',
    eventLog: 'home_premium_space',
    target: '_blank',
    hidden: true,
    beta: false,
    useBaseURL: false,
  },
  {
    name: 'layout:header_layout_profile',
    path: '/accounts/profile',
    target: '_self',
    hidden: false,
    beta: false,
    useBaseURL: false,
  },
  {
    name: 'layout:header_layout_my_apps',
    path: '/me/apps',
    target: '_self',
    hidden: false,
    beta: false,
    useBaseURL: false,
  },
  {
    name: 'layout:header_subscription_manage',
    path: '/me/spaces/plans',
    target: '_self',
    hidden: false,
    beta: false,
    useBaseURL: false,
    imageResource: 'light/layout/Bolt',
  },
  {
    name: 'layout:header_asset_manager',
    path: '/manage/assets',
    target: '_self',
    hidden: false,
    beta: false,
    useBaseURL: false,
    imageResource: 'light/layout/person_outline-black',
  },
  {
    name: 'layout:header_favorite_asset',
    path: '/store/save?type=like',
    target: '_self',
    hidden: true,
    beta: false,
    useBaseURL: false,
    imageResource: 'light/common/favorite_border-black',
  },
  {
    name: 'layout:header_purchased_asset',
    path: '/store/save?type=purchased',
    target: '_self',
    hidden: true,
    beta: false,
    useBaseURL: false,
    imageResource: 'light/layout/fileDownload_outline-black',
  },
  {
    name: 'layout:header_donate_my_history',
    path: '/manage/donations',
    target: '_self',
    hidden: false,
    beta: false,
    useBaseURL: false,
    imageResource: 'light/common/zem_border-black',
  },
];

export const useAssetManagerLeftButtons = (): SubLayoutButton[] => {
  const { t } = useTranslation(['manage']);

  return [
    {
      name: 'assets',
      pageName: t('manage:manage_my_assets'),
      link: '/manage/assets',
    },
    {
      name: 'sales',
      pageName: t('manage:manage_sales'),
      link: '/manage/sales',
    },
    {
      name: 'settlement',
      pageName: t('manage:manage_settlement'),
      link: '/manage/settlement',
    },
  ];
};
