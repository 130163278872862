import * as React from 'react';
import { SVGProps } from 'react';
export const ZemColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M6.857 4.719 3 10.39h4.018l-.16-5.672Z" fill="#EA00FF" />
    <path
      d="M6.857 4.719 3 10.39h4.018l-.16-5.672Z"
      fill="#fff"
      fillOpacity={0.65}
    />
    <path d="M12 4.719h5.143l-.16 5.672L12 4.719Z" fill="#EA00FF" />
    <path
      d="M12 4.719h5.143l-.16 5.672L12 4.719Z"
      fill="#fff"
      fillOpacity={0.2}
    />
    <path d="m6.857 4.719.16 5.672L12 4.719H6.857Z" fill="#EA00FF" />
    <path
      d="m6.857 4.719.16 5.672L12 4.719H6.857Z"
      fill="#fff"
      fillOpacity={0.54}
    />
    <path d="M12 4.719 7.018 10.39h9.964L12 4.719Z" fill="#EA00FF" />
    <path
      d="M12 4.719 7.018 10.39h9.964L12 4.719Z"
      fill="#fff"
      fillOpacity={0.4}
    />
    <path d="M16.982 10.39H7.018L12 19.786l4.982-9.394Z" fill="#EA00FF" />
    <path
      d="M16.982 10.39H7.018L12 19.786l4.982-9.394Z"
      fill={props.fill || `currentColor`}
      fillOpacity={0.07}
    />
    <path d="m17.143 4.719-.16 5.672H21l-3.857-5.672Z" fill="#EA00FF" />
    <path
      d="m17.143 4.719-.16 5.672H21l-3.857-5.672Z"
      fill={props.fill || `currentColor`}
      fillOpacity={0.1}
    />
    <path d="M7.018 10.39H3l9 9.396-4.982-9.395Z" fill="#EA00FF" />
    <path
      d="M7.018 10.39H3l9 9.396-4.982-9.395Z"
      fill="#fff"
      fillOpacity={0.38}
    />
    <path d="M16.982 10.39 12 19.787l9-9.395h-4.018Z" fill="#EA00FF" />
    <path
      d="M16.982 10.39 12 19.787l9-9.395h-4.018Z"
      fill={props.fill || `currentColor`}
      fillOpacity={0.33}
    />
  </svg>
);
