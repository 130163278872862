import { useModal } from '@ebay/nice-modal-react';
import { Menu } from '@headlessui/react';
import axios from 'axios';
import cns from 'classnames';
import { ParseKeys } from 'i18next';
import { useTranslation } from 'next-i18next';

import { useDialog } from '@zep/dialog';
import { Button } from '@zep/ui';

import { Loading } from '../../service/common/Loading';

import S from './Footer.module.scss';

export const ZepStringMenus = () => {
  const { t, i18n } = useTranslation(['common', 'layout'], {
    useSuspense: true,
  });
  const loadingSpinner = useModal(Loading);
  const dialog = useDialog();

  /**
   * 구글 스프레드 시트에서 ZEPString 을 받아서 Crowdin 소스파일 업데이트
   * @param type
   * @param locale 지정 시 해당 언어 번역본만 업데이트 수행
   */
  const updateZepStringSource = (type: ZepStringType, locale = 'ko') => {
    dialog.confirm({
      title: t('layout:zepString.update.confirm', {
        type,
        language: locale,
      }),
      confirmText: t('common:update'),
      confirmAction: async () => {
        loadingSpinner.show({ text: 'Updating' });
        axios
          .post('/nextapi/release-zep-strings', {
            type,
            update: true,
            locale,
          })
          .then(() => {
            dialog.alert({
              title: t('layout:zepString.update.success', { type }),
            });
            i18n.reloadResources();
          })
          .catch(reason => {
            dialog.alert({
              title: t('layout:zepString.update.sourceFail'),
            });
            console.error(reason);
          })
          .finally(() => {
            loadingSpinner.remove();
          });
      },
    });
  };

  /**
   * 크라우드인에 번역된 언어에 대한 배포 기능
   * @param type
   * @param successMessageKey
   * @param live
   */
  const releaseZepString = (
    type: ZepStringType,
    successMessageKey: ParseKeys<
      ['layout']
    > = 'layout:zepString.release.success',
    live = false,
  ) => {
    loadingSpinner.show({ text: 'Releasing' });
    axios
      .post('/nextapi/release-zep-strings', { type, live })
      .then(() => {
        dialog
          .alert({
            title: t(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              successMessageKey,
              { type },
            ),
          })
          .then(() => i18n.reloadResources());
      })
      .catch(reason => {
        dialog.alert({
          title: t('layout:zepString.release.fail'),
        });
        console.error(reason);
      })
      .finally(() => {
        loadingSpinner.remove();
      });
  };

  /**
   * 젭 스트링 live 배포
   * @param type
   */
  const releaseLiveZepString = (type: 'CLIENT' | 'SCRIPT') => {
    dialog.confirm({
      title: t('layout:zepString.deploy.confirm', { type }),
      confirmAction: async () => {
        releaseZepString(type, 'layout:zepString.deploy.success', true);
      },
    });
  };

  return (
    <>
      <span className={S.vertical_bar}>|</span>
      <Menu as="div" className={cns(S.login_user_menu, S.header_menu)}>
        {({ close: closeUserMenu }) => (
          <>
            <Menu.Button>{t('layout:zepString.update.title')}</Menu.Button>
            <Menu.Items as="div" className={S.footer_dropdown}>
              <Menu.Item as="div">
                <Button
                  transparent={true}
                  onClick={() => {
                    closeUserMenu();
                    updateZepStringSource('CLIENT');
                  }}>
                  Client
                </Button>
              </Menu.Item>
              <Menu.Item as="div">
                <Button
                  transparent={true}
                  onClick={() => {
                    closeUserMenu();
                    updateZepStringSource('SCRIPT');
                  }}>
                  Script
                </Button>
              </Menu.Item>
              <Menu.Item as="div">
                <Button
                  transparent={true}
                  onClick={() => {
                    closeUserMenu();
                    updateZepStringSource('APP');
                  }}>
                  App
                </Button>
              </Menu.Item>
            </Menu.Items>
          </>
        )}
      </Menu>
      <span className={S.vertical_bar}>|</span>
      <Menu as="div" className={cns(S.login_user_menu, S.header_menu)}>
        {({ close: closeUserMenu }) => (
          <>
            <Menu.Button>{t('layout:zepString.release.title')}</Menu.Button>
            <Menu.Items as="div" className={S.footer_dropdown}>
              <Menu.Item as="div">
                <Button
                  transparent={true}
                  onClick={() => {
                    closeUserMenu();
                    releaseZepString('CLIENT');
                  }}>
                  Client
                </Button>
              </Menu.Item>
              <Menu.Item as="div">
                <Button
                  transparent={true}
                  onClick={() => {
                    closeUserMenu();
                    releaseZepString('SCRIPT');
                  }}>
                  Script
                </Button>
              </Menu.Item>
              <Menu.Item as="div">
                <Button
                  transparent={true}
                  onClick={() => {
                    closeUserMenu();
                    releaseZepString('APP');
                  }}>
                  App
                </Button>
              </Menu.Item>
              <Menu.Item as="div">
                <Button
                  transparent={true}
                  onClick={() => {
                    closeUserMenu();
                    releaseLiveZepString('CLIENT');
                  }}>
                  Client(Live)
                </Button>
              </Menu.Item>
              <Menu.Item as="div">
                <Button
                  transparent={true}
                  onClick={() => {
                    closeUserMenu();
                    releaseLiveZepString('SCRIPT');
                  }}>
                  Script(Live)
                </Button>
              </Menu.Item>
            </Menu.Items>
          </>
        )}
      </Menu>
    </>
  );
};

type ZepStringType = 'WEB' | 'CLIENT' | 'SCRIPT' | 'APP';
