import { FormEvent, ReactNode, useEffect, useId, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { When } from 'react-if';

import { Button } from '../Button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPanel,
  DialogTitle,
} from '../Dialog';
import { Input } from '../Input';
import { TextField } from '../TextField';

export const PromptDialog = (props: PromptDialogProps) => {
  const {
    maxWidth = '320px',
    topSlot,
    title,
    content,
    afterContent,
    dimmed = true,
    input,
    confirmVariant = 'primary',
    cancelText,
    confirmText,
    useOutSideClick = false,
    keyboard = false,
    focus,
    onClose,
    onConfirm,
    ...rest
  } = props;

  const formId = useId();
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation(['common']);

  const handleCloseDialog = () => {
    if (!useOutSideClick) {
      return;
    }
    onClose && onClose();
  };

  const handleCancel = () => {
    onClose && onClose();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onConfirm && onConfirm(e, e.currentTarget[`prompt-dialog-${formId}`].value);
  };

  useEffect(() => {
    const formEl = document.getElementById(
      `prompt-dialog-${formId}`,
    ) as HTMLFormElement;
    const inputEl = formEl?.[`prompt-dialog-${formId}`];
    if (inputEl && focus) {
      inputEl.focus();
    }
  }, [focus, formId]);

  return (
    <Dialog
      open
      maxWidth={maxWidth}
      initialFocus={inputRef}
      onClose={handleCloseDialog}
      onKeyDown={e => !keyboard && e.stopPropagation()}
      {...rest}>
      {dimmed && <DialogOverlay />}
      <DialogPanel>
        <DialogHeader topSlot={topSlot}>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <DialogContent preLine>
          <When condition={!afterContent && !!content}>
            <div>{content}</div>
          </When>
          <form id={`prompt-dialog-${formId}`} onSubmit={handleSubmit}>
            <TextField
              className="mt-2"
              helperText={input?.helperText}
              error={input?.error}>
              <Input
                ref={inputRef}
                name={`prompt-dialog-${formId}`}
                type={input?.type || 'text'}
                rightSlot={input?.rightSlot}
                placeholder={input?.placeholder}
                error={input?.error}
                defaultValue={input?.defaultValue}
                autoComplete="off"
                isFullWidth
              />
            </TextField>
          </form>
          <When condition={afterContent && !!content}>
            <div>{content}</div>
          </When>
        </DialogContent>
        <DialogFooter>
          <When condition={cancelText !== false}>
            <Button variant="sub" size="md" onClick={handleCancel}>
              {cancelText ?? t('common:cancel')}
            </Button>
          </When>
          <Button
            type="submit"
            form={`prompt-dialog-${formId}`}
            variant={confirmVariant}
            size="md">
            {confirmText ?? t('common:confirm')}
          </Button>
        </DialogFooter>
      </DialogPanel>
    </Dialog>
  );
};

export type PromptDialogProps = {
  maxWidth?: string;
  topSlot?: ReactNode;
  title: ReactNode;
  content?: ReactNode;

  useOutSideClick?: boolean;

  // Prompt Dialog 특성상 Input 컴포넌트가 기본적으로 포함되게 됩니다.
  // Input 컴포넌트 다음으로 콘텐츠를 노출 하고 싶은 경우 afterContent 를 사용하면 됩니다.
  afterContent?: boolean;

  dimmed?: boolean;

  input?: {
    type?: 'text' | 'password';
    helperText?: string;
    error?: boolean;
    placeholder?: string;
    rightSlot?: ReactNode;
    defaultValue?: string;
  };

  keyboard?: boolean;

  // 버튼 필드
  cancelText?: string | boolean;
  confirmText?: string;
  confirmVariant?: 'primary' | 'alert';
  onClose?: () => void;
  onConfirm?: (event: FormEvent<HTMLFormElement>, value: string) => void;
  focus?: boolean;
};
