import { ComponentProps, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { When } from 'react-if';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { withAsyncBoundary } from '@toss/async-boundary';
import AsyncBoundary from '@toss/async-boundary/dist/AsyncBoundary';
import { isBefore, subDays } from 'date-fns';

import {
  useMapTemplates,
  usePurchasedEduMaps,
  usePurchasedMaps,
  useSpaceCategories,
} from '@zep/apis';
import { useAuth } from '@zep/auth';
import { MapTemplate } from '@zep/types';

import {
  MapTemplatesEmpty,
  MapTemplatesSkeleton,
} from './MapTemplateFallbacks';
import S from './MapTemplateModal.module.scss';

const asyncBoundaryProps: ComponentProps<typeof AsyncBoundary> = {
  pendingFallback: <MapTemplatesSkeleton />,
  rejectedFallback: () => <div>Error</div>,
  onError: error => {
    window.alert(error.message);
  },
};

export const OfficialMapTemplates = withAsyncBoundary(
  (props: MapTemplatesProps) => {
    const { i18n } = useTranslation();
    const { data: mapTemplates } = useMapTemplates(
      {
        locale: i18n.resolvedLanguage ?? 'en',
        category: props.categoryId,
      },
      {
        page: 1,
        limit: 100,
      },
      {
        suspense: true,
      },
    );

    const templates = useMemo<MapTemplate[] | undefined>(
      () => mapTemplates?.pages.flatMap(d => d.results),
      [mapTemplates],
    );

    return <MapTemplates {...props} templates={templates} />;
  },
  asyncBoundaryProps,
);

export const PurchasedMapTemplates = withAsyncBoundary(
  (props: MapTemplatesProps) => {
    const { user } = useAuth();
    const { i18n } = useTranslation();
    const queryOptions = {
      locale: i18n.resolvedLanguage ?? 'en',
    };
    const paginationOptions = {
      page: 1,
      limit: 100,
    };
    const isEdu = user?.isEdu ?? false;
    const purchasedMapsQuery = usePurchasedMaps(
      queryOptions,
      paginationOptions,
      {
        enabled: !isEdu,
      },
    );
    const purchasedEduMapsQuery = usePurchasedEduMaps(
      queryOptions,
      paginationOptions,
      {
        enabled: isEdu,
      },
    );
    const { data } = isEdu ? purchasedEduMapsQuery : purchasedMapsQuery;

    const templates = useMemo<MapTemplate[] | undefined>(
      () =>
        data?.pages
          .map(d =>
            d.items.map(asset => ({
              hashId: asset.hashId,
              name: asset.name,
              thumbnailUrl: asset.finalImageUrl,
              createdAt: asset.createdAt,
            })),
          )
          .flat(),
      [data],
    );

    return <MapTemplates {...props} templates={templates} isPurchased={true} />;
  },
  asyncBoundaryProps,
);

const MapTemplates = (
  props: MapTemplatesProps & {
    templates?: MapTemplate[];
    isPurchased?: boolean;
  },
) => {
  const { templates, isPurchased = false, onSelectTemplate } = props;
  return templates?.length ? (
    <div className={S.content}>
      {templates.map(template => (
        <MapTemplateItem
          key={template.hashId}
          mapTemplate={template}
          onClick={() =>
            onSelectTemplate({
              ...template,
              isPurchased,
            })
          }
        />
      ))}
    </div>
  ) : (
    <When condition={isPurchased}>
      <MapTemplatesEmpty />
    </When>
  );
};

const MapTemplateItem = (props: {
  mapTemplate: MapTemplate;
  onClick: () => void;
}) => {
  const { mapTemplate, onClick } = props;
  const { i18n } = useTranslation();
  const {
    data: { categories },
  } = useSpaceCategories(
    { locale: i18n.resolvedLanguage ?? 'en' },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );

  const isNew = isBefore(
    subDays(new Date(), 14),
    new Date(mapTemplate.createdAt),
  );

  const categoryString = useMemo(
    () =>
      mapTemplate.categories
        ?.map(
          categoryId =>
            categories?.find(category => category.id === categoryId)
              ?.category ?? '',
        )
        ?.join(' | '),
    [categories, mapTemplate.categories],
  );

  return (
    <div className={S.item} onClick={onClick}>
      <div className={S.item_image_wrapper}>
        <LazyLoadImage
          className={S.item_image}
          src={mapTemplate.thumbnailUrl}
          alt={mapTemplate.name + '_thumbnail'}
        />
      </div>
      {isNew && <div className={S.item_new}>{'NEW'}</div>}
      <span className={S.item_category}>{categoryString}</span>
      <span className={S.item_name}>{mapTemplate.name}</span>
    </div>
  );
};

export type MapTemplatesProps = {
  categoryId?: number;
  onSelectTemplate: (
    mapTemplate: (MapTemplate & { isPurchased: boolean }) | null,
  ) => void;
};
