import { useEffect, useMemo, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { usePreservedCallback } from '@toss/react';
import { useRouter } from 'next/router';

import { useAuth } from '@zep/auth';

import { CHANNEL_TALK_UNUSED_PATHS, PremiumSpaceKeys } from '../consts';
import { useChannelTalk } from '../lib/ChannelTalk';

import { useRouterEvents } from './useRouterEvents';

export const useChannelTalkBoot = (isAllowBoot: boolean) => {
  const router = useRouter();
  const { user } = useAuth();
  const channelTalk = useChannelTalk();
  const queryClient = useQueryClient();
  const isBooted = useRef(false);
  const isScriptLoaded = useRef(false);

  const isUnusedPage = useMemo(() => {
    return CHANNEL_TALK_UNUSED_PATHS.some(hidePath =>
      router.pathname.startsWith(hidePath),
    );
  }, [router.pathname]);

  const setPage = usePreservedCallback(() => {
    channelTalk?.setPage(router.asPath);
  });

  const boot = usePreservedCallback(() => {
    const isFirstBoot = !isScriptLoaded.current;
    if (isFirstBoot) {
      channelTalk?.loadScript();
      isScriptLoaded.current = true;
    }
    channelTalk?.boot({
      pluginKey: '26103066-96bf-40fa-8e6c-fc7afba2378f',
    });

    if (isFirstBoot) {
      setPage();
    }
    isBooted.current = true;
  });

  const shutdown = usePreservedCallback(() => {
    channelTalk?.shutdown();
    isBooted.current = false;
  });

  useEffect(() => {
    if (isAllowBoot && !isUnusedPage) {
      if (isBooted.current) {
        //
      } else if (!isBooted.current && window.top === window.self) {
        boot();
      }
    } else {
      shutdown();
    }
  }, [boot, isAllowBoot, isUnusedPage, shutdown]);

  useEffect(() => {
    const userHashId = user?.hashId ?? '';

    return () => {
      queryClient.resetQueries({
        queryKey: [PremiumSpaceKeys.subscribed, userHashId],
        exact: true,
      });
    };
  }, [queryClient, user?.hashId]);

  useRouterEvents('routeChangeComplete', () => {
    if (isBooted.current) {
      setPage();
    }
  });
};
