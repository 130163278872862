import { useTranslation } from 'react-i18next';

import { PagePath } from '@zep/common/routes';
import { Button, Stack } from '@zep/ui';
import { cn } from '@zep/utils';

import S from './MapTemplateModal.module.scss';

export const MapTemplatesEmpty = () => {
  const { t } = useTranslation(['common']);

  return (
    <Stack gap={24} className={S.empty_content}>
      <img
        src="/images/empty-purchased-asset.png"
        alt="empty purchased asset"
        width="88"
        height="60"
      />
      <span className={S.empty_content_text}>
        <span>{t('common:mapTemplateModal.emptyContent.noItems')}</span>
        <span className={S.subtitle}>
          {t('common:mapTemplateModal.emptyContent.goAssetStore')}
        </span>
      </span>
      <Button
        as="a"
        size="sm"
        children={t('common:mapTemplateModal.emptyButtonText')}
        href={PagePath.AssetStore}
        target="_blank"
      />
    </Stack>
  );
};

export const MapTemplatesSkeleton = () => {
  return (
    <div className={cn('pt-[20px]', S.content)}>
      {Array.from({ length: 12 }).map((d, index) => (
        <div key={index} className={S.skeleton_box}>
          <div className={S.skeleton_box_1} />
          <div className={S.skeleton_box_2} />
        </div>
      ))}
    </div>
  );
};
