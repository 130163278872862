import {
  GetNextPageParamFunction,
  QueryFunctionContext,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  OffsetPaginationOptions,
  ZepApiError,
  ZepApiOffsetPaginationResponse,
} from '../zep-api';

export function useOffsetInfiniteQuery<
  TQueryFnData extends ZepApiOffsetPaginationResponse<any, any>,
  TError = AxiosError | ZepApiError,
  TData = TQueryFnData,
>(
  queryKey: QueryKey,
  queryFn: (
    context: QueryFunctionContext<QueryKey, OffsetPaginationOptions>,
  ) => TQueryFnData | Promise<TQueryFnData>,
  options?: UseOffsetInfiniteQueryOptions<TQueryFnData, TError, TData>,
): UseInfiniteQueryResult<TData, TError> {
  const getNextPageParam: GetNextPageParamFunction<TQueryFnData> = (
    lastPage: TQueryFnData,
    allPages: TQueryFnData[],
  ) => {
    if (options?.getNextPageParam) {
      return options.getNextPageParam(lastPage, allPages);
    }
    const { pager } = lastPage;
    if (!pager) {
      return undefined;
    }
    const { currentPage, totalPages, pageSize } = pager;
    const hasNext = currentPage < totalPages;
    return hasNext
      ? {
          page: currentPage + 1,
          limit: pageSize,
        }
      : undefined;
  };

  return useInfiniteQuery<TQueryFnData, TError, TData>(queryKey, queryFn, {
    ...options,
    getNextPageParam,
  });
}

export type UseOffsetInfiniteQueryOptions<
  TQueryFnData extends ZepApiOffsetPaginationResponse<any, any>,
  TError = AxiosError | ZepApiError,
  TData = TQueryFnData,
> = Omit<
  UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData>,
  'queryKey' | 'queryFn'
>;
