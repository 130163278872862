import { InitOptions } from 'i18next';
import { ChainedBackendOptions } from 'i18next-chained-backend';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import { match } from 'ts-pattern';

type AppName = 'play' | 'edit' | 'web' | 'dashboard' | 'web3';

const getLoadPathOursByAppName = (appName: AppName) => {
  return match(appName)
    .with('play', () => `/play/locales/{{lng}}/{{ns}}.json`)
    .with('edit', () => `/edit/locales/{{lng}}/{{ns}}.json`)
    .with('web', () => `/locales/{{lng}}/{{ns}}.json`)
    .with('dashboard', () => `/dashboard/locales/{{lng}}/{{ns}}.json`)
    .with('web3', () => `/web3/locales/{{lng}}/{{ns}}.json`)
    .otherwise(() => `/locales/{{lng}}/{{ns}}.json`);
};

const getZepBackendOptions = (appName: AppName) => ({
  loadPath: getLoadPathOursByAppName(appName),
  queryStringParams: {
    version: process.env.NEXT_PUBLIC_COMMIT_SHA,
  },
});

export const chainedBackendOptions = (appName: AppName) => {
  return {
    backends: [HttpBackend],
    backendOptions: [getZepBackendOptions(appName)],
  };
};

export const getDefaultI18nOptions = (
  appName: AppName,
): InitOptions<ChainedBackendOptions & HttpBackendOptions> => ({
  detection: {
    order: [
      'querystring',
      'navigator',
      'cookie',
      'htmlTag',
      'path',
      'subdomain',
    ],
  },
  backend: {
    ...chainedBackendOptions(appName),
  },
  supportedLngs: ['en', 'ko', 'ja'],
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
    bindI18n: 'languageChanged loaded',
  },
  nonExplicitSupportedLngs: true,
  load: 'languageOnly',
});
