import { memoize } from 'lodash-es';

import { isServer } from './isServer';

function _isApp() {
  if (isServer()) {
    return false;
  }

  return /ZepApp/i.test(window.navigator.userAgent);
}

export const isApp = memoize(_isApp);
