import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

export * from './lineBreakPlugin';

/**
 * @see https://github.com/dcastil/tailwind-merge/blob/v1.14.0/docs/api-reference.md#extendtailwindmerge
 */
export const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': [
        'text-h1',
        'text-h2',
        'text-h3',
        'text-h4',
        'text-h5',
        'text-subtitle',
        {
          'text-body': ['1', '2'],
          'text-caption': ['1', '2', '3'],
        },
      ],
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
