import {
  Children,
  cloneElement,
  CSSProperties,
  forwardRef,
  HTMLAttributes,
  ReactElement,
  ReactNode,
} from 'react';

export type StackProps = HTMLAttributes<HTMLElement> & {
  className?: string;
  divider?: ReactElement;
  direction?: CSSProperties['flexDirection'];
  alignItems?: CSSProperties['alignItems'];
  gap?: CSSProperties['gap'];
  children: ReactNode;
};

export const Stack = forwardRef<HTMLDivElement, StackProps>((props, ref) => {
  const {
    direction = 'column',
    className,
    divider,
    style,
    alignItems,
    gap,
    children,
    ...rest
  } = props;
  return (
    <div
      ref={ref}
      style={{
        display: 'flex',
        flexDirection: direction,
        alignItems,
        gap,
        ...style,
      }}
      className={className}
      {...rest}>
      {divider ? joinChildren(children, divider) : children}
    </div>
  );
});

/**
 * Return an array with the separator React element interspersed between
 * each React node of the input children.
 *
 * > joinChildren([1,2,3], 0)
 * [1,0,2,0,3]
 */
function joinChildren(children: ReactNode, separator: ReactElement) {
  const childrenArray = Children.toArray(children).filter(Boolean);

  return childrenArray.reduce<ReactNode[]>((output, child, index) => {
    output.push(child);

    if (index < childrenArray.length - 1) {
      output.push(cloneElement(separator, { key: `separator-${index}` }));
    }

    return output;
  }, []);
}
