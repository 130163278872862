// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import UAParser from '@amplitude/ua-parser-js';
import { UAParserInstance } from 'ua-parser-js';

import { getNativeAppVersion } from './getNativeAppVersion';
import { isApp } from './isApp';
import { isMobile } from './isMobile';
import { isWhaleBrowser } from './isWhaleBrowser';

const parser = new UAParser() as UAParserInstance;

export const getDeviceInformation = () => {
  return {
    browserName: parser.getBrowser().name,
    osName: parser.getOS().name,
    isMobile: isMobile(),
    isWhaleBrowser: isWhaleBrowser(),
    isApp: isApp(),
    appVersion: getNativeAppVersion(),
  };
};
