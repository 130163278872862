import { MouseEvent, ReactNode } from 'react';
import { Menu } from '@headlessui/react';
import cns from 'classnames';

import S from './MenuItem.module.scss';

export const MenuItem = (props: MenuItemProps) => {
  const {
    subtitle,
    className,
    leftSlot,
    rightSlot,
    selected,
    disabled,
    error,
    children,
    ...rest
  } = props;

  const renderText =
    leftSlot || rightSlot ? (
      <>
        {leftSlot && <div>{leftSlot}</div>}
        <div className={S.text}>{children}</div>
        {rightSlot && <div>{rightSlot}</div>}
      </>
    ) : (
      children
    );

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (props.onClick) {
      props.onClick(e);
    }
  };

  return (
    <Menu.Item
      as="button"
      type="button"
      className={cns(S.item, className, {
        [S.error]: error,
        [S.disabled]: disabled,
        [S.selected]: selected,
      })}
      disabled={disabled}
      {...rest}
      onClick={handleClick}>
      {subtitle ? (
        <div className={S.with_subtitle}>
          <small className={S.subtitle}>{subtitle}</small>
          <div>{renderText}</div>
        </div>
      ) : (
        renderText
      )}
    </Menu.Item>
  );
};

type MenuItemProps = {
  subtitle?: string;
  className?: string;
  children: ReactNode;
  error?: boolean;
  leftSlot?: ReactNode;
  rightSlot?: ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  selected?: boolean;
};
