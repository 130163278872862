import { useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import {
  DehydratedState,
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import I18NextChainedBackend from 'i18next-chained-backend';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { appWithTranslation, UserConfig } from 'next-i18next';

import '../init';
import '../styles/globals.scss';
import '../styles/motions.scss';
import 'react-loading-skeleton/dist/skeleton.css';

import { ZepApiProvider, ZepetofulProvider } from '@zep/apis';
import { AuthProvider } from '@zep/auth';
import { chainedBackendOptions } from '@zep/i18n';
import { Language, PageWithLayout, User } from '@zep/types';
import { isClient } from '@zep/utils';

import nextI18nConfig from '../../next-i18next.config';
import { getDefaultLayout } from '../components/layouts/DefaultLayout';
import RootLayout from '../components/layouts/RootLayout';
import FontLink from '../components/service/common/FontLink';
import Meta, { MetaProps } from '../components/service/common/Meta';
import GlobalErrorBoundary from '../guards/GlobalErrorBoundary';
import ChannelService, { ChannelTalkContext } from '../lib/ChannelTalk';
import { isDevelopment, isProduction } from '../utils';

const ZEPETOFUL_TOKEN = process.env.NEXT_PUBLIC_ZEPETOFUL_TOKEN || '';

const ZepApp = ({ Component, pageProps }: ZepAppProps) => {
  const { seoContent, serverUser } = pageProps;
  const router = useRouter();
  const locale = (router.locale as Language) ?? 'ko';
  const [channelTalk] = useState<ChannelService>(() => new ChannelService());

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
            keepPreviousData: true,
            useErrorBoundary: true,
            retry: false,
            networkMode: 'always',
          },
          mutations: {
            networkMode: 'always',
          },
        },
      }),
  );

  const getLayout = Component.getLayout ?? getDefaultLayout;

  return (
    <>
      <Meta partialSeoContent={seoContent} />
      <GlobalErrorBoundary>
        <ChannelTalkContext.Provider value={channelTalk}>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <ZepApiProvider>
                <ZepetofulProvider
                  locale={locale}
                  token={ZEPETOFUL_TOKEN}
                  dev={!isProduction}>
                  <AuthProvider initialUser={serverUser}>
                    <FontLink />
                    <RootLayout>
                      <NiceModal.Provider>
                        {getLayout(<Component {...pageProps} />)}
                      </NiceModal.Provider>
                    </RootLayout>
                    {isDevelopment && (
                      <ReactQueryDevtools initialIsOpen={false} />
                    )}
                  </AuthProvider>
                </ZepetofulProvider>
              </ZepApiProvider>
            </Hydrate>
          </QueryClientProvider>
        </ChannelTalkContext.Provider>
      </GlobalErrorBoundary>
    </>
  );
};

const clientI18nConfig: UserConfig = {
  ...nextI18nConfig,
  use: [I18NextChainedBackend],
  backend: chainedBackendOptions('web'),
};

export default appWithTranslation(
  ZepApp,
  isClient() ? clientI18nConfig : nextI18nConfig,
);

type ZepAppProps = AppProps<{
  seoContent?: MetaProps['partialSeoContent'];
  serverUser?: User;
  dehydratedState?: DehydratedState;
}> & {
  Component: PageWithLayout;
};
