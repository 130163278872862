import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Link from 'next/link';
import { match } from 'ts-pattern';

import { zepAnalytics } from '@zep/analytics';
import { ChevronRightIcon } from '@zep/icons';
import { Language } from '@zep/types';

import S from './MapTemplateFooterBanner.module.scss';

const hrefMap = {
  ko: '/home/contact',
  en: 'https://contact.zep.us/premium.plan.en',
  ja: 'https://contact.zep.us/premium.plan.jp',
};

const imagePaths = {
  image1: '/images/map-templates/banner/avatar_with_laptop.png',
  image2: '/images/map-templates/banner/zep-logo-cursor.png',
};

export const MapTemplateFooterBanner = () => {
  const { t, i18n } = useTranslation(['common']);
  const lang: Language = (i18n.resolvedLanguage as Language) || 'en';

  const href = getHref(lang || 'en');
  const subtitle = t('common:create_space.footer_banner.text');
  const title = t('common:create_space.footer_banner.text_highlight');

  const handleClickBanner = () => {
    zepAnalytics.track('create_sales_banner');
  };

  return (
    <div onClick={handleClickBanner}>
      <Link
        className={S.footer_banner}
        href={href}
        target="_blank"
        prefetch={false}>
        <LazyLoadImage
          width={44}
          height={36}
          src={imagePaths.image1}
          alt="image1"
        />

        <div className={S.center_container}>
          <div className={S.text_wrapper}>
            <span className={S.subtitle}>{subtitle}</span>
            <span className={S.title}>{title}</span>
          </div>
          <ChevronRightIcon fill="#BEC3CC" width={20} height={20} />
        </div>
        <LazyLoadImage
          width={34.13}
          height={29}
          src={imagePaths.image2}
          alt="image2"
        />
      </Link>
    </div>
  );
};

const getHref = (lang: Language) => {
  return match(lang)
    .with('ko', () => hrefMap.ko)
    .with('en', () => hrefMap.en)
    .with('ja', () => hrefMap.ja)
    .otherwise(() => hrefMap.en);
};
