import { useState } from 'react';

import { Controlled } from './Controlled';
import { RangeSliderProps } from './RangeSlider';

export function Uncontrolled({
  onChange,
  defaultValue,
  ...props
}: RangeSliderProps) {
  const [value, setValue] = useState(defaultValue ?? 0);

  return (
    <Controlled
      value={value}
      onChange={_value => {
        setValue(_value);
        onChange?.(_value);
      }}
      {...props}
    />
  );
}
