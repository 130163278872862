import * as React from 'react';
import { SVGProps } from 'react';
export const ExclamationmarkSmallCircleIcon = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Z"
      fill={props.fill || `currentColor`}
    />
    <path
      d="M12 14a1 1 0 0 1-1-1V7a1 1 0 1 1 2 0v6a1 1 0 0 1-1 1ZM10.5 16.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
      fill={props.fill || `currentColor`}
    />
  </svg>
);
