import React, { createContext, useContext, useMemo } from 'react';
import { AxiosInstance } from 'axios';

import { createZepetofulClient } from './client';

const ZepetofulClientContext = createContext<ZepetofulContextType>({
  client: createZepetofulClient(''),
  locale: 'en',
});

export const ZepetofulProvider = (props: ZepetofulProviderProps) => {
  const { locale, token, dev = false, children } = props;
  const client = useMemo(
    () => createZepetofulClient(token, locale, dev),
    [token, locale, dev],
  );

  return (
    <ZepetofulClientContext.Provider value={{ client, locale, dev }}>
      {children}
    </ZepetofulClientContext.Provider>
  );
};

export const useZepetofulContext = () => {
  return useContext(ZepetofulClientContext);
};

export const useZepetofulClient = () => {
  return useZepetofulContext().client;
};

export interface ZepetofulConfig {
  locale: string;
  token: string;
  dev?: boolean;
}

export interface ZepetofulProviderProps extends ZepetofulConfig {
  children: React.ReactNode;
}

export interface ZepetofulContextType
  extends Pick<ZepetofulConfig, 'locale' | 'dev'> {
  client: AxiosInstance;
}
