import { useMemo } from 'react';
import cns from 'classnames';

import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@zep/icons';

import { cn } from '../../utils/tailwind';
import { IconContainer } from '../IconContainer';

import S from './Pagination.module.scss';

/**
 * 페이지 컨트롤 UI
 * @param props
 * @constructor
 */
export const Pagination = (props: PaginationProps) => {
  const printableCount = props.printableCount || 5;
  const lastPageNo = (props.lastPageNo > 0 && props.lastPageNo) || 1;
  const nowPageNo =
    (props.nowPageNo &&
      props.nowPageNo <= lastPageNo &&
      props.nowPageNo > 0 &&
      props.nowPageNo) ||
    1;

  /**
   * 출력할 페이지 번호 세팅
   */
  const printPages = useMemo(() => {
    const newPrintPages: Array<number> = [];
    const halfPrintableCount = Math.floor(printableCount / 2);
    for (
      let pageNo =
        (nowPageNo - halfPrintableCount > 0 &&
          nowPageNo - halfPrintableCount) ||
        1;
      newPrintPages.length < printableCount && pageNo <= lastPageNo;
      pageNo++
    ) {
      newPrintPages.push(pageNo);
    }

    if (
      newPrintPages.length < printableCount &&
      nowPageNo - (halfPrintableCount + 1) > 0
    ) {
      for (
        let pageNo = nowPageNo - (halfPrintableCount + 1);
        pageNo > 0 && newPrintPages.length < printableCount;
        pageNo--
      ) {
        newPrintPages.unshift(pageNo);
      }
    }

    return newPrintPages;
  }, [nowPageNo, lastPageNo, printableCount]);

  const onPageChange = (pageNo: number) => {
    if (pageNo <= lastPageNo) {
      props.onPageChange && props.onPageChange(pageNo);
    }
  };

  return (
    <div className={S.pagination_ui}>
      <IconContainer
        className={cn('w-6 h-6', S.button, S.control, {
          hidden: printPages[0] < 2,
        })}
        onClick={() => onPageChange(1)}>
        <ChevronDoubleLeftIcon width={24} height={24} />
      </IconContainer>
      <IconContainer
        className={cn('w-6 h-6', S.button, S.control, {
          hidden: nowPageNo <= 1,
        })}
        onClick={() => onPageChange(nowPageNo - 1)}>
        <ChevronLeftIcon width={24} height={24} />
      </IconContainer>
      {printPages.map((value: number) => (
        <div
          className={cns(S.button, { [S.active]: value === nowPageNo })}
          onClick={() => onPageChange(value)}
          key={value}>
          {value}
        </div>
      ))}
      <IconContainer
        className={cn('w-6 h-6', S.button, S.control, {
          hidden: nowPageNo >= lastPageNo,
        })}
        onClick={() => onPageChange(nowPageNo + 1)}>
        <ChevronRightIcon width={24} height={24} />
      </IconContainer>
      <IconContainer
        className={cn('w-6 h-6', S.button, S.control, {
          hidden: printPages[printPages.length - 1] > lastPageNo - 1,
        })}
        onClick={() => onPageChange(lastPageNo)}>
        <ChevronDoubleRightIcon width={24} height={24} />
      </IconContainer>
    </div>
  );
};

export type PaginationProps = {
  lastPageNo: number;
  nowPageNo: number;
  printableCount?: number;
  onPageChange?: (pageNo: number) => void;
};
