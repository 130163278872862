const path = require('path');

/** @type {import('next-i18next').UserConfig} */
module.exports = {
  i18n: {
    locales: ['en', 'ko', 'ja'],
    defaultLocale: 'ko',
    localeDetection: false,
  },
  ns: [
    'accounts',
    'business',
    'common',
    'home',
    'layout',
    'manage',
    'store',
    'subscription',
    'wallet',
  ],
  partialBundledLanguages: true,
  localePath: path.resolve('./public/locales'),
  reloadOnPrerender: process.env.NEXT_PUBLIC_STAGE === 'LOC',
};
